import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import FurtherAssessmentMonthly from "./FurtherAssessmentMonthly";
import FurtherAssessmentWeekly from "./FurtherAssessmentWeekly";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

class FurtherAssessment extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var monthly = !this.props.assessment || this.props.assessment.policy === 'MONTHLY_PARCELS'
        return monthly && (<FurtherAssessmentMonthly {...this.props}/>) || (<FurtherAssessmentWeekly {...this.props}/>)
    }
}

export default withStyles(styles)(FurtherAssessment);
