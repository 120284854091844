import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import UsersTable from "components/Table/UsersTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "services/ApiService.js";

const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Users extends Component {

    constructor(props) {
      super(props);
      this.fetchUsers = this.fetchUsers.bind(this);
      this.approveUser = this.approveUser.bind(this)
      this.deleteUser = this.deleteUser.bind(this)
      this.setUserRoles = this.setUserRoles.bind(this)
      this.state = {
          users: {},
          usersOrder: []
      }
    }

    componentDidMount() {
        this.fetchUsers()
    }

    fetchUsers() {
        ApiService.request('/users')
            .then(data => {
                this.setState({users: data.reduce(function(map, obj) {
                                                        map[obj.id] = obj;
                                                        return map;
                                                    }, {}),
                               usersOrder: data.map(user => user.id)});
            })
            .catch(err => {
                console.log(err);
            });
    }

    approveUser(id) {
        ApiService.request(`/users/${id}/approve`, 'POST')
            .then(response => {
                var users = this.state.users;
                users[response.id] = response;
                this.setState({ users: users })
            })
    }

    deleteUser(userId) {
        ApiService.request(`/users/${userId}/delete`, 'POST')
            .then(() => {
                var users = this.state.users;
                var usersOrder = this.state.usersOrder;
                delete users[userId];
                usersOrder = usersOrder.filter(function(value, index, arr){ return value != userId;});
                this.setState({ users: users, usersOrder: usersOrder })
            })
    }

    setUserRoles(userId, roles) {
        ApiService.request(`/users/${userId}/setRoles`, 'POST', roles)
            .then(response => {
                var users = this.state.users;
                users[response.id] = response;
                this.setState({ users: users })
            })
    }

    render() {
        const { classes } = this.props;
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <GridContainer alignItems='center'>
                      <GridItem md={12}>
                          <h4 className={classes.cardTitleWhite}>Users</h4>
                      </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <UsersTable
                    tableHeaderColor="primary"
                    tableHead={{"Name": user => user.name,
                                "Email": user => user.email,
                                "Status": user => user.status}}
                    users={this.state.users}
                    usersOrder={this.state.usersOrder}
                    approveUser={this.approveUser}
                    deleteUser={this.deleteUser}
                    setUserRoles={this.setUserRoles}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );

    }


}
export default withStyles(styles)(Users);
