import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import MenuItem from "@material-ui/core/MenuItem";
import ReferralAssessmentsTable from "components/Table/ReferralAssessmentsTable.js";
import TextField from "@material-ui/core/TextField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import ReferralTimelineTable from "components/Table/ReferralTimelineTable.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import Checkbox from "@material-ui/core/Checkbox";
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {infoColor} from "assets/jss/material-dashboard-react.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

const validation = {'fullName': value => (value.length === 0 && 'Name must be set') || '',
                    'contactNumber': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
                    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
                    'address':  value => (value.length === 0 && 'Address must be set') || ''}

class GuestReferralsTable extends Component {
    constructor(props) {
        super(props);
        this.setOpen = this.setOpen.bind(this)
        this.setEdit = this.setEdit.bind(this)
        this.editReferral = this.editReferral.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.addValue = this.addValue.bind(this)
        this.deleteValue = this.deleteValue.bind(this)
        this.confirmArchive = this.confirmArchive.bind(this);

        this.state = {
            open: {},
            edit: {},
            errors: {},
            confirmArchiveReferral: null
        };
    }

    setOpen(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;
        if (!open[key]) {
            open[key] = true;
        }
        else {
            delete edit[key];
            delete errors[key];
            open[key] = false;
        }
        this.setState({open: open, edit: edit, errors: errors});
    }

    changeValue(id, field, value) {
        var edit = this.state.edit;
        var errors = this.state.errors;
        edit[id][field] = value;
        errors[id][field] = (validation[field] && validation[field](value)) || ''
        this.setState({edit: edit, errors: errors});
    }

    deleteValue(id, field, value) {
        var edit = this.state.edit;
        var valueList = edit[id][field];
        var idxToRemove = valueList.indexOf(value);
        valueList.splice(idxToRemove, 1)
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    addValue(id, field, value) {
        var edit = this.state.edit;
        var valueList = edit[id][field].slice();
        valueList.push(value);
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    editReferral(key) {
        this.props.editReferral(this.state.edit[key]);
        delete this.state.edit[key];
        delete this.state.errors[key];
    }

    setEdit(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;

        if (!open[key]) {
            open[key] = true;
        }

        if (!edit[key]) {
            this.props.referrals.forEach((referral, idx) => {
                if (referral.id === key) {
                    edit[key] = Object.assign({}, referral);
                    errors[key] = {};
                }
            });
        }
        else {
            delete edit[key];
            delete errors[key];
        }
        this.setState({open: open, edit: edit, errors: errors});
    }

    confirmArchive() {
        this.props.archiveReferral(this.state.confirmArchiveReferral.id, this.state.confirmArchiveReferral.archiveReason, this.state.confirmArchiveReferral.archiveComment, new Date())
            .then(() => this.setState({confirmArchiveReferral: null}));
    }

    confirmSwitchReferralType() {
        this.props.switchReferralType(this.state.confirmSwitchReferralTypeId)
            .then(() => this.setState({ confirmSwitchReferralTypeId: null }));
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
            <Dialog open={this.state.confirmArchiveReferral}
                    onClose={() => this.setState({confirmArchiveReferral: null})}>
              <DialogTitle id="confirm-archive-title">{"Archive referral?"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you wish to archive referral {this.state.confirmArchiveReferral?.id} for {this.props.guest?.fullName}?
                  </DialogContentText>
                  <FormControl>
                      <InputLabel htmlFor="archiveReason">Archive reason</InputLabel>
                      <Select id="archiveReason"
                              name="archiveReason"
                              value={this.state.confirmArchiveReferral?.archiveReason || 'GUEST_REQUESTED'}
                              onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveReason: ev.target.value} })}>
                          <MenuItem value="DUPLICATE_RECEIVING_FOOD_PARCELS">Duplicate - receiving food parcels</MenuItem>
                          <MenuItem value="DUPLICATE_RECEIVING_READY_MEALS">Duplicate - receiving ready meals</MenuItem>
                          <MenuItem value="GUEST_REQUESTED">Guest has requested for parcels to stop</MenuItem>
                          <MenuItem value="MOVED_AWAY">Guest has moved away</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                      </Select>
                  </FormControl><br/>
                  <TextField id="archiveComment"
                             label="Archive Comment"
                             multiline
                             InputLabelProps={{shrink: true}}
                             value={this.state.confirmArchiveReferral?.archiveComment || ''}
                             onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveComment: ev.target.value} })}/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => this.setState({confirmArchiveReferral: null})} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={this.confirmArchive} color="primary" autoFocus>
                      Archive
                  </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.confirmSwitchReferralTypeId} onClose={() => this.setState({confirmSwitchReferralTypeId: null})}>
                <DialogTitle id="confirm-switch-referral-type-title">Switch to {this.props.referrals.find(referral => referral.id === this.state.confirmSwitchReferralTypeId)?.referralType === "FOOD_BANK" ? "Ready meal" : "Food bank"} referral?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to change referral {this.state.confirmSwitchToReadyMealReferralId} for {this.props.guest?.fullName} to a {this.props.referrals.find(referral => referral.id === this.state.confirmSwitchReferralTypeId)?.referralType === "FOOD_BANK" ? "Ready meal" : "Food bank"} referral?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({confirmSwitchReferralTypeId: null})} color="primary">
                        No
                    </Button>
                    <Button onClick={this.confirmSwitchReferralType} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.referrals && this.props.referrals.map((referral, idx) => {
                  const editing = this.state.edit[referral.id]
                  const editedReferral = this.state.edit[referral.id] || referral
                  const errors = (editing && this.state.errors[referral.id]) || {}
                  return referral && (
                    <React.Fragment>
                      <TableRow key={referral.id} className={classes.tableBodyRowSummary}>
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="View referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(referral.id)}>
                              {this.state.open[referral.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        { Object.keys(this.props.tableHead).map((prop, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={key}>
                              {this.props.tableHead[prop](referral)}
                              {prop === "contactNumber" && referral.permissionToContact
                                && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                        <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                    </Tooltip>)}
                            </TableCell>
                          );
                        })}
                        <TableCell className={classes.tableCellSummary} key={"actions"}>
                          <Tooltip title="Edit referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setEdit(referral.id)}>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          {referral.status === 'ARCHIVED'
                              ? (<Tooltip title="Unarchive referral">
                                   <IconButton aria-label="unarchive referral" size="medium" onClick={() => this.props.unarchiveReferral(referral.id, 'unarchive')}>
                                     <RestorePageIcon/>
                                   </IconButton>
                                 </Tooltip>)
                              : (<React.Fragment>
                                   <Tooltip title={"Change referral to " + (referral.referralType === "FOOD_BANK" ? "Ready meal" : "Food bank") + " referral"}>
                                     <IconButton aria-label="switch referral type" size="medium"
                                                 onClick={() => this.setState({confirmSwitchReferralTypeId: referral.id})}>
                                       <SyncAltIcon/>
                                     </IconButton>
                                   </Tooltip>
                                   <Tooltip title="Archive referral">
                                     <IconButton aria-label="archive referral" size="medium"
                                                 onClick={() => this.setState({confirmArchiveReferral: { id: referral.id, archiveReason: 'GUEST_REQUESTED', archiveComment: '' }})}>
                                       <ArchiveRoundedIcon/>
                                     </IconButton>
                                   </Tooltip>
                                 </React.Fragment>)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={referral.id + "Details"} className={classes.tableBodyRowDetail}>
                        <TableCell className={classes.tableDetailsCell}/>
                        <TableCell className={classes.tableDetailsCell} colSpan={Object.keys(this.props.tableHead).length + 5} key={"details"}>
                          <Collapse in={this.state.open[referral.id]} timeout="auto" unmountOnExit>
                              <GridContainer>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="referralAgency">Referral Agency</InputLabel>
                                          <Select id="referralAgency" name="referralAgency" disabled={!editing} value={editedReferral.referralAgency || ''} onChange={ev => this.changeValue(referral.id, "referralAgency", ev.target.value)}>
                                              {this.props.getOptions('REFERRAL_AGENCY').map(option => (<MenuItem value={option.value} disabled={!option.active}>{option.value}</MenuItem>))}
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="nameOfAuthorisedSignatory"
                                                 label="Name of authorised signatory"
                                                 disabled={!editing}
                                                 error={errors['nameOfAuthorisedSignatory'] && errors['nameOfAuthorisedSignatory'].length > 0}
                                                 value={editedReferral.nameOfAuthorisedSignatory || ''}
                                                 onChange={ev => this.changeValue(referral.id, "nameOfAuthorisedSignatory", ev.target.value)}
                                                 helperText={errors['nameOfAuthorisedSignatory'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="emailOfAuthorisedSignatory"
                                                 label="Email of authorised signatory"
                                                 disabled={!editing}
                                                 error={errors['emailOfAuthorisedSignatory'] && errors['emailOfAuthorisedSignatory'].length > 0}
                                                 value={editedReferral.emailOfAuthorisedSignatory || ''}
                                                 onChange={ev => this.changeValue(referral.id, "emailOfAuthorisedSignatory", ev.target.value)}
                                                 helperText={errors['emailOfAuthorisedSignatory'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}/>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="reason"
                                                 label="Reason"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['reason'] && errors['reason'].length > 0}
                                                 value={editedReferral.reason || ''}
                                                 onChange={ev => this.changeValue(referral.id, "reason", ev.target.value)}
                                                 helperText={errors['reason'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="groupsMember">Groups</InputLabel>
                                          <Select id="groupsMember" name="groupsMember" multiple disabled={!editing} value={editedReferral.groupsMember || []} onChange={ev => this.changeValue(referral.id, "groupsMember", ev.target.value)}>
                                              {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value} disabled={!option.active}>{option.value}</MenuItem>))}
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="organisationsSupporting"
                                                 label="Referred or signposted to"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['organisationsSupporting'] && errors['organisationsSupporting'].length > 0}
                                                 value={editedReferral.organisationsSupporting || ''}
                                                 onChange={ev => this.changeValue(referral.id, "organisationsSupporting", ev.target.value)}
                                                 helperText={errors['organisationsSupporting'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="furtherInfo"
                                                 label="Further info"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['furtherInfo'] && errors['furtherInfo'].length > 0}
                                                 value={editedReferral.furtherInfo || ''}
                                                 onChange={ev => this.changeValue(referral.id, "furtherInfo", ev.target.value)}
                                                 helperText={errors['furtherInfo'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="nappiesCore"
                                                       coreLabel="Nappies"
                                                       id={referral.id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('NAPPIES').map(option => option.value)}
                                                       otherField="nappiesOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="babyMilkCore"
                                                       coreLabel="Baby milk"
                                                       id={referral.id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('BABY_MILK').map(option => option.value)}
                                                       otherField="babyMilkOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="toiletriesCore"
                                                       coreLabel="Toiletries"
                                                       id={referral.id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('TOILETRIES').map(option => option.value)}
                                                       otherField="toiletriesOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}/>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="permissionToContact" name="permissionToContact" disabled={!editing} checked={editedReferral.permissionToContact} onChange={ (ev) => this.changeValue(referral.id, "permissionToContact", ev.target.checked) } />}
                                          label="Permission to contact guest"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="coronavirus" name="coronavirus" disabled={!editing} checked={editedReferral.coronavirus} onChange={ (ev) => this.changeValue(referral.id, "coronavirus", ev.target.checked) } />}
                                          label="Coronavirus"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="homelessOrTemporaryAccommodation" name="homelessOrTemporaryAccommodation" disabled={!editing} checked={editedReferral.homelessOrTemporaryAccommodation} onChange={ (ev) => this.changeValue(referral.id, "homelessOrTemporaryAccommodation", ev.target.checked) } />}
                                          label="Homeless or temporary accommodation"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="cookingFacilities" name="cookingFacilities" disabled={!editing} checked={editedReferral.cookingFacilities} onChange={ (ev) => this.changeValue(referral.id, "cookingFacilities", ev.target.checked) } />}
                                          label="Cooking facilities"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}/>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl>
                                          <InputLabel htmlFor="modeOfDelivery">Mode of delivery</InputLabel>
                                          <Select id="modeOfDelivery" name="modeOfDelivery" disabled={!editing} value={editedReferral.modeOfDelivery || ""} onChange={ev => this.changeValue(referral.id, "modeOfDelivery", ev.target.value)}>
                                              <MenuItem value="COLLECTION">Collection</MenuItem>
                                              <MenuItem value="DELIVERY">Delivery</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="reasonForDelivery"
                                                 label="Reason for delivery"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['reasonForDelivery'] && errors['reasonForDelivery'].length > 0}
                                                 value={editedReferral.reasonForDelivery || ''}
                                                 onChange={ev => this.changeValue(referral.id, "reasonForDelivery", ev.target.value)}
                                                 helperText={errors['reasonForDelivery'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="frequencyOfDelivery">Mode of delivery</InputLabel>
                                          <Select id="frequencyOfDelivery" name="frequencyOfDelivery" disabled={!editing} value={editedReferral.frequencyOfDelivery || ""} onChange={ev => this.changeValue(referral.id, "frequencyOfDelivery", ev.target.value)}>
                                              <MenuItem value="WEEKLY">Weekly</MenuItem>
                                              <MenuItem value="FORTNIGHTLY">Fortnightly</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="numberOfDeliveries"
                                                 label="Number of deliveries"
                                                 disabled={!editing}
                                                 value={editedReferral.numberOfDeliveries}
                                                 onChange={ev => this.changeValue(referral.id, "numberOfDeliveries", ev.target.value)}
                                                 type="number"/>
                                  </GridItem>
                                  <GridItem xs={12}>
                                      <Button color="primary" disabled={!editing || Object.values(errors).some(err => err.length > 0)} onClick={e => this.editReferral(referral.id)}>Update referral</Button>
                                  </GridItem>
                                  <GridItem md={6}>
                                      <ReferralTimelineTable
                                          tableHeaderColor="primary"
                                          tableHead={{"ID": timelineEvent => timelineEvent.id,
                                              "Date": timelineEvent => timelineEvent.date}}
                                          timeline={referral.timeline}
                                          performDeliveryAction={this.props.performDeliveryAction}
                                          viewFurtherAssessment={(referralId, furtherAssessmentId) => this.props.viewFurtherAssessment(this.props.guest.id, referralId, furtherAssessmentId)}
                                          id={referral.id}
                                      />
                                  </GridItem>
                              </GridContainer>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(GuestReferralsTable);

GuestReferralsTable.defaultProps = {
  tableHeaderColor: "gray"
};

GuestReferralsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
