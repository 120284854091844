import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import Login from "components/Login/Login.js";
import SignUp from "components/SignUp/SignUp.js";
import AuthService from "services/AuthService.js";
import ApiService from "services/ApiService.js";

const hist = createBrowserHistory();

class App extends Component {

    constructor(props) {
        super(props);
        this.fetchUser = this.fetchUser.bind(this)
        this.fetchOptions = this.fetchOptions.bind(this)
        this.fetchCollectionsHubs = this.fetchCollectionsHubs.bind(this)
        this.addOption = this.addOption.bind(this)
        this.getOptions = this.getOptions.bind(this)
        this.state = {
            user: null,
            userDetails: null,
            options: {},
            collectionHubs: []
        }
    }

    async componentDidMount() {
        this.fetchOptions();
        this.fetchCollectionsHubs();
        AuthService.subscribe(x => {
            this.setState({ user: x });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.user !== this.state.user) {
            this.fetchUser()
            this.fetchCollectionsHubs()
        }
    }

    fetchUser() {
        if (this.state.user) {
            const response = ApiService.request('/users/me')
                .then(data => this.setState({userDetails: data}))
                .catch(err => {
                  AuthService.logout();
                })
        }
    }

    fetchOptions() {
        return ApiService.request('/option')
                         .then(data => this.setState({options: data}))
                         .catch(err => {
                           console.log(err)
                           this.setState({options: []})
                         })
    }

    fetchCollectionsHubs() {
        ApiService.request('/collectionhubs')
            .then(data => {
                this.setState({collectionHubs: data})
            })
            .catch(err => {
                console.log(err);
            });
    }

    getOptions(optionType) {
        return this.state.options[optionType] || [];
    }

    addOption(value, type, active = true) {
        return ApiService.request('/option', 'PUT', { value: value, type: type, active: active})
                         .then(this.fetchOptions)
    }

    render() {
        if (AuthService.getAccessToken()) {
            return(<Router history={hist} >
                       <Switch>
                           <Route path="/admin" render={(props) => (<Admin {...props}
                                                                           user={this.state.userDetails}
                                                                           getOptions={this.getOptions}
                                                                           collectionHubs={this.state.collectionHubs}
                                                                           addOption={this.addOption}/>)}/>
                           <Redirect from="/" to="/admin"/>
                       </Switch>
                   </Router>)
        }
        return(<Router history={hist} >
                   <Switch>
                       <Route path="/login"  render={(props) => (<Login {...props} />)}/>
                       <Route path="/sign-up"  render={(props) => (<SignUp {...props} />)}/>
                       <Redirect from="/" to="/login"/>
                   </Switch>
               </Router>)
    }
}

export default App