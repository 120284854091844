import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ReadyMealsTable from "components/Table/ReadyMealsTable.js";
import FurtherAssessment from "components/Table/FurtherAssessment.js";
import ReadyMealsPendingReferralsDialog from "views/ReadyMeals/ReadyMealsPendingReferralsDialog.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InputLabel from "@material-ui/core/InputLabel";
import ManageRoutesDialog from "views/ReadyMeals/ManageRoutesDialog.js";
import ReadyMealDialog from "views/ReadyMeals/ReadyMealDialog.js";
import ViewReadyMealsReferralDialog from "views/ReadyMeals/ViewReadyMealsReferralDialog.js";
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Card from "components/Card/Card.js";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CardHeader from "components/Card/CardHeader.js";
import {formatDate, parseDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor,
    grayColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import MapIcon from '@material-ui/icons/Map';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import endOfWeek from "date-fns/endOfWeek";
import setDay from "date-fns/setDay";
import Tooltip from '@material-ui/core/Tooltip';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import {format, isFuture, isToday, parse, isPast} from 'date-fns'
import DateFnsUtils from '@date-io/date-fns';
import {enGB} from 'date-fns/locale'

const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const referralColors = [grayColor[0], primaryColor[0], roseColor[0], successColor[0], infoColor[0], warningColor[0], dangerColor[0]]
const GUEST_EDITABLE_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

const REFERRAL_EDITABLE_FIELDS = ["referralAgency", "groupsMember",
    "nameOfAuthorisedSignatory", "phoneOfAuthorisedSignatory", "emailOfAuthorisedSignatory",
    "furtherInfo", "cookingFacilities", "homelessOrTemporaryAccommodation", "cannotAffordToPurchaseFood", "numberOfDeliveries",
    "permissionToContact", "appliances", "nameOfGp", "gpPractice", "carer", "carerResponsibilities"];

class ReadyMeals extends Component {

    constructor(props) {
      super(props);
      this.fetchReferrals = this.fetchReferrals.bind(this);
      this.fetchPendingReferrals = this.fetchPendingReferrals.bind(this);
      this.setOrderBy = this.setOrderBy.bind(this);
      this.setRoute = this.setRoute.bind(this);
      this.setGuest = this.setGuest.bind(this);
      this.addGuest = this.addGuest.bind(this);
      this.archivePendingReferral = this.archivePendingReferral.bind(this);
      this.archiveLiveReferral = this.archiveLiveReferral.bind(this);
      this.archiveReferral = this.archiveReferral.bind(this);
      this.switchLiveReferralTypeToFoodAid = this.switchLiveReferralTypeToFoodAid.bind(this);
      this.switchPendingReferralTypeToFoodAid = this.switchPendingReferralTypeToFoodAid.bind(this);
      this.switchReferralTypeToFoodAid = this.switchReferralTypeToFoodAid.bind(this);
      this.acceptPendingReferral = this.acceptPendingReferral.bind(this);
      this.editReferral = this.editReferral.bind(this);
      this.editPendingReferral = this.editPendingReferral.bind(this);
      this.editLiveReferral = this.editLiveReferral.bind(this);
      this.fetchReferral = this.fetchReferral.bind(this);
      this.computeCentre = this.computeCentre.bind(this);
      this.setViewReferralDialogId = this.setViewReferralDialogId.bind(this);
      this.endOfNextWeek = this.endOfNextWeek.bind(this);
      this.schedule = this.schedule.bind(this);
      this.delivered = this.delivered.bind(this);
      this.referralFailed = this.referralFailed.bind(this);
      this.referralDelivered = this.referralDelivered.bind(this);
      this.referralCollected = this.referralCollected.bind(this);
      this.scheduleReferral = this.scheduleReferral.bind(this);
      this.getDeliveriesReport = this.getDeliveriesReport.bind(this);
      this.viewFurtherAssessment = this.viewFurtherAssessment.bind(this);
      this.performDeliveryAction = this.performDeliveryAction.bind(this);
      this.getCsv = this.getCsv.bind(this);
      this.state = {
        referrals: {},
        referralsOrder: [],
        pendingReferrals: [],
        guests: [],
        numberOfResults: 0,
        orderBy: "timestamp",
        order: "desc",
        manageRoutesDialogOpen: false,
        pendingReferralsDialogOpen: false,
        acceptReferralsPermission: props.user?.roles?.includes('ROLE_ADMIN'),
        viewReferralDialogId: null,
        parcelStatusFilter: 'all'
      };
    }

    componentDidMount() {
        this.fetchReferrals()
        if (this.state.acceptReferralsPermission)
            this.fetchPendingReferrals()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var permission = this.props.user?.roles?.includes('ROLE_ADMIN')
        if (permission !== prevProps.user?.roles?.includes('ROLE_ADMIN')) {
            this.setState({ acceptReferralsPermission: permission })
            if (permission)
                this.fetchPendingReferrals()
        }

        if (prevState.orderBy !== this.state.orderBy
            || prevState.order !== this.state.order
            || prevState.parcelStatusFilter !== this.state.parcelStatusFilter
            || prevProps.searchParam !== this.props.searchParam) {
            this.fetchReferrals()
        }
    }

    endOfNextWeek() {
        var date = new Date();
        date.setDate(date.getDate() + 7);
        return endOfWeek(date, {weekStartsOn: 1});
    }

    setViewReferralDialogId(referralId) {
        this.fetchReferral(referralId)
          .then(() => {
            this.setState({viewReferralDialogId: referralId})
          })
    }

    performDeliveryAction(key, action, param1, param2) {
        var link = `/deliveries/${key}/${action}`;
        if (action === "sendScheduledText") {
            link = `${link}?startTime=${format(param1, 'hh:mm')}&endTime=${format(param2, 'hh:mm')}`;
        }
        ApiService.request(link, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[response.id] = response;
                this.setState({referrals: referrals})
            })
    }

    fetchReferrals() {
        ApiService.request(`/ready-meal-referrals/live?sort=${this.state.orderBy || "timestamp"},${this.state.order || "desc"}` +
                             `&parcelStatusFilter=${this.state.parcelStatusFilter}` +
                             `&${this.props.searchParam && `searchString=${this.props.searchParam}` || ""}`)
            .then(data => {
                this.setState({referrals: data.reduce(function(map, obj) {
                                                          map[obj.id] = obj;
                                                          return map;
                                                      }, {}),
                               referralsOrder: data.map(referral => referral.id),
                               numberOfResults: data.length})
            })
            .catch(err => {
                console.log(err);
            });
    }

    getDeliveriesReport(date) {
        ApiService.requestCsv(`/ready-meal-referrals/${this.state.printReportDialogOpen}Report?date=${format(date, 'dd-MM-yyyy')}`)
    }

    getCsv() {
        ApiService.requestCsv(`/ready-meal-referrals/csv`)
    }

    fetchPendingReferrals() {
        ApiService.request(`/ready-meal-referrals/pending?sort=timestamp,desc`)
            .then(data => {
                this.setState({pendingReferrals: data})
            })
            .catch(err => {
                console.log(err);
            });
    }

    setGuest(key, guestId) {
        return ApiService.request(`/referrals/${key}/setGuest?guestId=${guestId}`, 'PUT')
            .then(response => {
                var pendingReferrals = this.state.pendingReferrals;
                pendingReferrals[pendingReferrals.findIndex(referral => referral.referral.id === key)].referral = response;
                this.setState({ pendingReferrals: pendingReferrals});
                return response
            })
    }

    setRoute(referralId, route) {
        ApiService.request(`/ready-meal-referrals/${referralId}/setRoute?route=${route}`, 'PUT')
            .then(response => {
                var referrals = this.state.referrals;
                referrals[referralId] = response;
                this.setState({ referrals: referrals});
            })
    }

    schedule(date) {
        ApiService.request(`/ready-meal-referrals/schedule?date=${format(date, 'dd-MM-yyyy')}`, 'PUT')
            .then(response => {
                this.fetchReferrals()
            })
    }

    delivered() {
        ApiService.request(`/ready-meal-referrals/delivered`, 'PUT')
            .then(response => {
                this.fetchReferrals()
            })
    }

    scheduleReferral(referralId, date) {
        ApiService.request(`/ready-meal-referrals/${referralId}/schedule?date=${format(date, 'dd-MM-yyyy')}`, 'PUT')
            .then(response => {
              var referrals = this.state.referrals;
              referrals[referralId] = response
              this.setState({referrals: referrals});
            })
    }

    referralDelivered(referralId) {
        ApiService.request(`/ready-meal-referrals/${referralId}/delivered`, 'PUT')
            .then(response => {
              var referrals = this.state.referrals;
              referrals[referralId] = response
              this.setState({referrals: referrals});
            })
    }

    referralCollected(referralId) {
        ApiService.request(`/ready-meal-referrals/${referralId}/collected`, 'PUT')
            .then(response => {
              var referrals = this.state.referrals;
              referrals[referralId] = response
              this.setState({referrals: referrals});
            })
    }

    referralFailed(referralId) {
        ApiService.request(`/ready-meal-referrals/${referralId}/failed`, 'PUT')
            .then(response => {
              var referrals = this.state.referrals;
              referrals[referralId] = response
              this.setState({referrals: referrals});
            })
    }

    addGuest(key) {
        return ApiService.request(`/referrals/${key}/addGuest`, 'PUT')
            .then(response => {
                var pendingReferrals = this.state.pendingReferrals;
                pendingReferrals[pendingReferrals.findIndex(referral => referral.referral.id === key)].referral = response;
                this.setState({ pendingReferrals: pendingReferrals});
                return response
            })
    }

    archivePendingReferral(key, archiveReason, archiveComment, archiveDate) {
        return this.archiveReferral(key, archiveReason, archiveComment, archiveDate)
            .then(() => {
                this.setState({ pendingReferrals: this.state.pendingReferrals.filter(referral => referral.referral.id !== key)})
            })
    }

    archiveLiveReferral(key, archiveReason, archiveComment, archiveDate) {
        return this.archiveReferral(key, archiveReason, archiveComment, archiveDate)
            .then(() => {
                this.setState({ referralsOrder: this.state.referralsOrder.filter(referralId => referralId !== key)})
            })
    }

    archiveReferral(key, archiveReason, archiveComment, archiveDate) {
        return ApiService.request(`/referrals/${key}/archive?archiveReason=${archiveReason}&archiveDate=${formatDate(archiveDate || new Date())}`, 'PUT', archiveComment)
    }

    switchPendingReferralTypeToFoodAid(key) {
        return this.switchReferralTypeToFoodAid(key)
            .then(response => {
                this.setState({ pendingReferrals: this.state.pendingReferrals.filter(referral => referral.referral.id !== key)})
            })
    }

    switchLiveReferralTypeToFoodAid(key) {
        return this.switchReferralTypeToFoodAid(key)
            .then(response => {
                this.setState({ referralsOrder: this.state.referralsOrder.filter(referralId => referralId !== key)})
            })
    }

    switchReferralTypeToFoodAid(key) {
        return ApiService.request(`/referrals/${key}/switchReferralType`, 'PUT')
    }

    acceptPendingReferral(key) {
        ApiService.request(`/referrals/${key}/accept`, 'PUT')
            .then(() => {
                this.setState({ pendingReferrals: this.state.pendingReferrals.filter(referral => referral.referral.id !== key)})
            })
    }

    editPendingReferral(referral) {
        return this.editReferral(referral)
          .then(data => {
              var pendingReferrals = this.state.pendingReferrals;
              if (data.status === 'PENDING' || !data.guest) {
                  pendingReferrals[pendingReferrals.findIndex(r => r.referral.id === referral.id)].referral = data;
                  this.setState({pendingReferrals: pendingReferrals});
                  return data
              }
              else {
                  pendingReferrals = pendingReferrals.filter(r => r.referral.id !== referral.id)
                  this.setState({pendingReferrals: pendingReferrals});
                  return null
              }
          })
    }

    editLiveReferral(referral) {
        return this.editReferral(referral)
          .then(data => {
              var referrals = this.state.referrals;
              referrals[referral.id] = data
              this.setState({referrals: referrals});
          })
    }

    editReferral(referral) {
        var referralToSend = REFERRAL_EDITABLE_FIELDS.reduce(function (result, field) {
            result[field] = referral[field];
            return result;
        }, {});
        if (referral.guest) {
            var guest = referral.guest
            var guestToSend = GUEST_EDITABLE_FIELDS.reduce(function (result, field) {
                result[field] = guest[field];
                return result;
            }, {});
            referralToSend.guest = guestToSend
        }
        return ApiService.request(`/referrals/${referral.id}`, 'PUT', referralToSend)
            .catch(err => {
                console.log(err);
            });
    }

    fetchReferral(id) {
        return ApiService.request(`/referrals/${id}`)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[id] = data;
                this.setState({ referrals: referrals })
            })
            .catch(err => {
                console.log(err);
            });
    }

    viewFurtherAssessment(referralId, furtherAssessmentId) {
        this.setState({
            furtherAssessmentDialogFurtherAssessmentId: furtherAssessmentId,
            furtherAssessmentDialogReferralId: referralId
        })
    }

    setOrderBy(prop) {
        const isAsc = this.state.orderBy === prop && this.state.order === 'asc';
        this.setState({order: isAsc ? 'desc' : 'asc', orderBy: prop});
    }

    computeCentre() {
      var lats = this.state.referralsOrder
                     .map(id => this.state.referrals[id]?.guest?.lat || 0)
                     .filter(lat => lat != 0)
      var lngs = this.state.referralsOrder
                     .map(id => this.state.referrals[id]?.guest?.lng || 0)
                     .filter(lng => lng != 0)
      return {
        lat: lats.length > 0 && (lats.reduce((lat1, lat2) => lat1 + lat2) / lats.length) || 51.548965598331826,
        lng: lngs.length > 0 && (lngs.reduce((lng1, lng2) => lng1 + lng2) / lngs.length) || -0.2660128285399273
      }
    }

    render() {
        const { classes } = this.props;
        var pendingReferrals = this.state.pendingReferrals && this.state.pendingReferrals.length > 0;
        var showSchedule = !this.state.referralsOrder.every(referralId => this.state.referrals[referralId]?.scheduled)
        var showDelivered = this.state.referralsOrder.some(referralId => {
          var scheduledDate = parseDate(this.state.referrals[referralId]?.scheduledDelivery)
          return scheduledDate && (isPast(scheduledDate) || isToday(scheduledDate))
        })
        var manageRoutesDialogDisabled = !this.state.referralsOrder.some(referralId => this.state.referrals[referralId].scheduledDelivery)
        return (
          <Card>
            <CardHeader color="primary">
              <GridContainer alignItems='center'>
                  <GridItem md={6}>
                      <h3 className={classes.cardTitleWhite}>Ready Meals</h3>
                      <p className={classes.cardCategoryWhite}>Showing {this.state.numberOfResults} ready meal guests</p>
                  </GridItem>
                  <GridItem md={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{color: whiteColor}} shrink>Referral status</InputLabel>
                        <Select value={this.state.parcelStatusFilter}
                                onChange={ev => this.setState({parcelStatusFilter: ev.target.value})}
                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                            <MenuItem value="scheduled">Scheduled</MenuItem>
                        </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem md={3}>
                    <Tooltip title={`Manage Routes${manageRoutesDialogDisabled && ' - No deliveries scheduled' || ''}`}>
                      <IconButton aria-label="manageRoutes" size="large" onClick={() => this.setState({ manageRoutesDialogOpen: true })} disabled={manageRoutesDialogDisabled}>
                        <MapIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                      </IconButton>
                    </Tooltip>
                    {this.state.acceptReferralsPermission &&
                      (<Tooltip title={pendingReferrals ? `${this.state.pendingReferrals.length} new referrals` : 'No new referrals'}>
                        <IconButton aria-label="pendingReferralsOpen" size="large" onClick={pendingReferrals && (() => this.setState({ pendingReferralsDialogOpen: true })) || (() => {})}>
                          {pendingReferrals
                           ? (<Badge color="secondary" badgeContent={this.state.pendingReferrals.length}>
                                <NewReleasesIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                              </Badge>)
                           : (<NewReleasesIcon style={{ color: whiteColor, fontSize: '35px' }}/>)}
                        </IconButton>
                      </Tooltip>)}
                      {showSchedule &&
                        (<React.Fragment>
                          <Tooltip title="Schedule delivery for all pending parcels">
                            <IconButton aria-label="schedule" size="large" onClick={() => this.setState({ scheduleDialogOpen: true })}>
                              <ScheduleIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                            </IconButton>
                          </Tooltip>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                              <DatePicker open={this.state.scheduleDialogOpen || false}
                                          onOpen={() => this.setState({scheduleDialogOpen: true})}
                                          onClose={() => this.setState({scheduleDialogOpen: false})}
                                          value={setDay(new Date(), 5, {weekStartsOn: 1})}
                                          disablePast
                                          autoOk
                                          maxDate={this.endOfNextWeek()}
                                          onChange={date => this.schedule(date)}
                                          TextFieldComponent={() => null}/>
                          </MuiPickersUtilsProvider>
                        </React.Fragment>)}
                      {showDelivered &&
                        (<Tooltip title="Mark all scheduled deliveries as delivered">
                           <IconButton aria-label="delivered" size="large" onClick={() => this.delivered()}>
                             <CheckCircleOutlineRoundedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                           </IconButton>
                         </Tooltip>)}
                    <Tooltip title="Print deliveries report">
                      <IconButton aria-label="report" size="large" onClick={() => this.setState({ printReportDialogOpen: 'deliveries' })}>
                        <LocalShippingRoundedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Print packing report">
                      <IconButton aria-label="report" size="large" onClick={() => this.setState({ printReportDialogOpen: 'packing' })}>
                        <ShoppingCartIcon style={{color: whiteColor, fontSize: '35px'}}/>
                      </IconButton>
                    </Tooltip>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                        <DatePicker open={this.state.printReportDialogOpen || false}
                                    onClose={() => this.setState({printReportDialogOpen: false})}
                                    value={setDay(new Date(), 5, {weekStartsOn: 1})}
                                    disablePast
                                    autoOk
                                    maxDate={this.endOfNextWeek()}
                                    onChange={date => this.getDeliveriesReport(date)}
                                    TextFieldComponent={() => null}/>
                    </MuiPickersUtilsProvider>
                    <Tooltip title="Download as csv">
                      <IconButton aria-label="csv" size="large" onClick={() => this.getCsv()}>
                        <CloudDownloadOutlinedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                      </IconButton>
                    </Tooltip>
                  </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {this.state.manageRoutesDialogOpen &&
                 (<ManageRoutesDialog open={this.state.manageRoutesDialogOpen}
                                      closeManageRoutesDialog={() => this.setState({manageRoutesDialogOpen: false})}
                                      endOfNextWeek={this.endOfNextWeek}
                                      setRoute={this.setRoute}
                                      collectionHubs={this.props.collectionHubs}
                                      referrals={this.state.referrals}
                                      setViewReferralDialogId={this.setViewReferralDialogId}
                                      referralsOrder={this.state.referralsOrder}/>)}
              {pendingReferrals &&
                 (<ReadyMealsPendingReferralsDialog open={this.state.pendingReferralsDialogOpen}
                                                  handleClose={() => {
                                                    this.setState({ pendingReferralsDialogOpen: false });
                                                    this.fetchReferrals()
                                                  }}
                                                  pendingReferrals={this.state.pendingReferrals}
                                                  accept={this.acceptPendingReferral}
                                                  archive={this.archivePendingReferral}
                                                  switchReferralType={this.switchPendingReferralTypeToFoodAid}
                                                  editReferral={this.editReferral}
                                                  editPendingReferral={this.editPendingReferral}
                                                  setGuest={this.setGuest}
                                                  addGuest={this.addGuest}
                                                  getOptions={this.props.getOptions}/>)}
              {this.state.viewReferralDialogId &&
                 (<ReadyMealDialog open={this.state.viewReferralDialogId}
                                   handleClose={() => { this.setState({ viewReferralDialogId: null }); }}
                                   performDeliveryAction={this.performDeliveryAction}
                                   viewFurtherAssessment={this.viewFurtherAssessment}
                                   referral={this.state.referrals[this.state.viewReferralDialogId]}
                                   editReferral={this.editLiveReferral}
                                   getOptions={this.props.getOptions}/>)}
              {this.state.furtherAssessmentDialogReferralId &&
                 <FurtherAssessment open={this.state.furtherAssessmentDialogReferralId}
                                    readOnly={!(this.props.user && this.props.user.roles.includes('ROLE_ADMIN') || !this.state.furtherAssessmentDialogFurtherAssessmentId
                                        || this.state.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId).parcelsLeft > 0)}
                                    canEditFrequency={this.props.user?.roles?.includes('ROLE_ADVISOR')}
                                    onClose={() =>this.setState({
                                        furtherAssessmentDialogFurtherAssessmentId: null,
                                        furtherAssessmentDialogReferralId: null
                                    })}
                                    getOptions={this.props.getOptions}
                                    id={this.state.furtherAssessmentDialogFurtherAssessmentId}
                                    referral={this.state.referrals[this.state.furtherAssessmentDialogReferralId]}
                                    assessment={this.state.furtherAssessmentDialogFurtherAssessmentId
                                               && this.state.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId)}
                                    editFurtherAssessment={this.props.editFurtherAssessment}/>}
              <ReadyMealsTable
                tableHeaderColor="primary"
                tableHead={{"id": "ID",
                            "fullName": "Name",
                            "contactNumber": "Contact",
                            "address": "Address",
                            "postcode": "Postcode",
                            "numberOfPeople": "People",
                            "lastDeliveryDate": "Last delivery",
                            "deliveriesReceived": "Ready meals received",
                            "parcelsLeft": "Ready meals left",
                            "parcelStatus": "Status"}}
                mappingFunctions={{"id": referral => referral?.id,
                                   "fullName": referral => referral?.guest?.fullName,
                                   "contactNumber": referral => {
                                       return (<pre>
                                                {referral.guest?.contactNumbers?.join('\n')}
                                                {referral.guest?.permissionToContact && referral.guest?.contactNumbers && referral.guest?.contactNumbers > 0
                                                  && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                                          <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                                      </Tooltip>)}
                                               </pre>)
                                   },
                                   "address": referral => referral?.guest?.address,
                                   "postcode": referral => referral?.guest?.postcode,
                                   "numberOfPeople": referral => referral?.guest?.numberOfPeople,
                                   "lastDeliveryDate": referral => referral?.lastDeliveryDate,
                                   "deliveriesReceived": referral => referral?.deliveriesReceived,
                                   "parcelsLeft": referral => referral?.parcelsLeft,
                                   "parcelStatus": referral => referral?.parcelStatus + (referral?.scheduledDelivery && !referral?.parcelStatus?.includes(referral?.scheduledDelivery) ? `, Scheduled on ${referral?.scheduledDelivery}` : "")}}
                referralsOrder={this.state.referralsOrder}
                referrals={this.state.referrals}
                editReferral={this.editReferral}
                fetchReferral={this.fetchReferral}
                orderBy={this.state.orderBy}
                order={this.state.order}
                setOrderBy={this.setOrderBy}
                getOptions={this.props.getOptions}
                setViewReferralDialogId={this.setViewReferralDialogId}
                schedule={this.scheduleReferral}
                failed={this.referralFailed}
                archive={this.archiveLiveReferral}
                switchReferralType={this.switchLiveReferralTypeToFoodAid}
                delivered={this.referralDelivered}
                collected={this.referralCollected}
              />
            </CardBody>
          </Card>
        );

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(ReadyMeals))
