import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GridContainer from "components/Grid/GridContainer.js";
import {endOfWeek, format, isAfter, isBefore, isSameDay, isWithinInterval, parse, startOfWeek, subYears} from 'date-fns'
import {
    dangerColor,
    infoColor,
    primaryColor,
    successColor,
    warningColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "services/ApiService.js";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import Chart from 'react-apexcharts'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {enGB} from 'date-fns/locale'

const styles = theme => ({
  typography: {
    fontSize: 20,
  },
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  pagination: {
    alignItems: 'right'
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
});

class Reports extends Component {

    constructor(props) {
      super(props);
      this.fetchReport = this.fetchReport.bind(this);
      this.setPeriod = this.setPeriod.bind(this);
      this.formatWeekSelectLabel = this.formatWeekSelectLabel.bind(this);
      this.renderWrappedWeekDay = this.renderWrappedWeekDay.bind(this);
      this.handleWeekChange = this.handleWeekChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleQuarterChange = this.handleQuarterChange.bind(this);
      this.getQuarter = this.getQuarter.bind(this);
      this.getQuarterDate = this.getQuarterDate.bind(this);
      this.getQuarterDateFromDate = this.getQuarterDateFromDate.bind(this);
      this.getPicker = this.getPicker.bind(this);
      this.generateCsv = this.generateCsv.bind(this);
      this.generateReport = this.generateReport.bind(this);
      this.generateAnnualReport = this.generateAnnualReport.bind(this);
      this.state = {
          date: startOfWeek(new Date(), {weekStartsOn: 1}),
          period: 'WEEKLY',
          report: {},
          generateCsvDialog: false,
          csvCollectionHub: 'all',
          csvView: 'guest',
          collectionHubs: []
      }
    }

    componentDidMount() {
        this.fetchReport(this.state.period, this.state.date)
   }

    fetchReport(period, date, endDate) {
        var link = `/reports/${period || 'WEEKLY'}/${format((date || startOfWeek(new Date(), {weekStartsOn: 1})), 'dd-MM-yyyy')}`
        if (period === 'CUSTOM') {
            link = `${link}?endDate=${format((endDate || new Date()), 'dd-MM-yyyy')}`
        }
        ApiService.request(link)
            .then(data => {
                this.setState({report: data});
            })
            .catch(err => {
                console.log(err);
            });
    }

    generateCsv() {
        var link = `/reports/${this.state.period || "WEEKLY"}/${format((this.state.date || startOfWeek(new Date(), {weekStartsOn: 1})), 'dd-MM-yyyy')}/csv?collectionHub=${this.state.csvCollectionHub}&view=${this.state.csvView}`
        if (this.state.period === 'CUSTOM') {
            link = `${link}&endDate=${format((this.state.endDate || new Date()), 'dd-MM-yyyy')}`
        }
        ApiService.requestCsv(link)
            .then(response => {
                this.setState({generateCsvDialog: false})
            })
    }

    generateReport() {
        var link = `/reports/${this.state.period || "WEEKLY"}/${format((this.state.date || startOfWeek(new Date(), {weekStartsOn: 1})), 'dd-MM-yyyy')}/report`
        if (this.state.period === 'CUSTOM') {
            link = `${link}?endDate=${format((this.state.endDate || new Date()), 'dd-MM-yyyy')}`
        }
        ApiService.requestCsv(link)
            .then(response => {
                this.setState({generateCsvDialog: false})
            })
    }

    generateAnnualReport() {
        var date = this.state.date || new Date()
        if (isBefore(date, new Date(date.getFullYear(), 3, 1)))
            date = subYears(date, 1)
        ApiService.requestCsv(`/reports/annualreport/${date.getFullYear()}`)
            .then(response => {
                this.setState({generateCsvDialog: false})
            })
    }

    setPeriod(event) {
        var period = event.target.value;
        var date = period === 'WEEKLY' ? startOfWeek(new Date(), {weekStartsOn: 1}) : new Date();
        if (period === 'ANNUAL' && isBefore(date, new Date(date.getFullYear(), 3, 1)))
            date = subYears(date, 1)
        var endDate = period === 'CUSTOM' ? new Date() : null;
        this.setState({period: period, date: date, endDate: endDate, report: {}});
        this.fetchReport(period, date, endDate)
    }

    getQuarter(date) {
        return Math.ceil((date.getMonth() + 1) / 3);
    }

    getQuarterDate(year, quarter) {
        return new Date(year, quarter * 3 - 3, 1, 0, 0, 0, 0);
    }

    getQuarterDateFromDate(date) {
        return this.getQuarterDate(date.getFullYear(), this.getQuarter(date));
    }

    getPicker() {
        if (this.state.period === 'WEEKLY') {
          return (<DatePicker value={this.state.date}
                              autoOk
                              label="Week"
                              disableFuture
                              InputProps={{ style: {color: whiteColor} }}
                              InputLabelProps={{ style: {color: whiteColor} }}
                              renderDay={this.renderWrappedWeekDay}
                              onChange={this.handleWeekChange}
                              labelFunc={this.formatWeekSelectLabel}/>)
        }
        else if (this.state.period === 'MONTHLY') {
          return (<DatePicker openTo="year"
                              views={["year", "month"]}
                              label="Month"
                              disableFuture
                              InputProps={{ style: {color: whiteColor} }}
                              InputLabelProps={{ style: {color: whiteColor} }}
                              value={this.state.date}
                              onChange={this.handleDateChange}/>)
        }
        else if (this.state.period === 'QUARTERLY') {
          var today = new Date();
          var thisYear = today.getFullYear();
          var thisQuarter = this.getQuarter(today);
          var quarters = [];
          for (var year = 2017; year <= thisYear; year++) {
              for (var quarter = 1; quarter <= (year === thisYear ? thisQuarter : 4); quarter++) {
                  quarters.push(this.getQuarterDate(year, quarter))
              }
          }
          const { classes } = this.props;
          return (<FormControl fullWidth>
                    <InputLabel style={{color: whiteColor}} shrink>Quarter</InputLabel>
                    <Select value={format(this.getQuarterDateFromDate(this.state.date), 'dd-MM-yyyy')}
                            onChange={this.handleQuarterChange}
                            classes={{ root: classes.whiteColor, icon: classes.whiteColor}}>
                      {quarters.map(quarterDate => (<MenuItem value={format(quarterDate, 'dd-MM-yyyy')}>{quarterDate.getFullYear()}, Q{this.getQuarter(quarterDate)}</MenuItem>))}
                    </Select>
                  </FormControl>)
        }
        else if (this.state.period === 'ANNUAL') {
            return (<DatePicker openTo="year"
                                views={["year"]}
                                label="Year"
                                disableFuture
                                InputProps={{ style: {color: whiteColor} }}
                                InputLabelProps={{ style: {color: whiteColor} }}
                                value={this.state.date}
                                onChange={this.handleDateChange}/>)
        }
        else if (this.state.period === 'CUSTOM') {
          return (<React.Fragment>
                    <DatePicker value={this.state.date}
                                autoOk
                                label="Start"
                                disableFuture
                                InputProps={{ style: {color: whiteColor} }}
                                InputLabelProps={{ style: {color: whiteColor} }}
                                onChange={date => this.handleCustomPeriodChange(date, true)}/>
                    <DatePicker value={this.state.endDate}
                                autoOk
                                label="End"
                                disableFuture
                                InputProps={{ style: {color: whiteColor} }}
                                InputLabelProps={{ style: {color: whiteColor} }}
                                onChange={date => this.handleCustomPeriodChange(date, false)}/>
                  </React.Fragment>)
        }
    }

    handleWeekChange(date) {
        const weekStart = startOfWeek(date, {weekStartsOn: 1});
        this.setState({date: weekStart, report: {}});
        this.fetchReport(this.state.period, weekStart)
    }

    handleCustomPeriodChange(date, start) {
        if (start) {
            this.setState({date: date, report: {}});
            this.fetchReport(this.state.period, date, this.state.endDate)
        }
        else {
            this.setState({endDate: date, report: {}});
            this.fetchReport(this.state.period, this.state.date, date)
        }
    }

    handleDateChange(date) {
        this.setState({date: date, report: {}});
        this.fetchReport(this.state.period, date);
    }

    handleQuarterChange(event) {
        const quarterDate = parse(event.target.value, 'dd-MM-yyyy', new Date(2020, 0, 1, 0, 0, 0, 0));
        this.setState({date: quarterDate, report: {}});
        this.fetchReport(this.state.period, quarterDate);
    }

    formatWeekSelectLabel(date, invalidLabel) {
        return `Week of ${format(startOfWeek(date, {weekStartsOn: 1}), "MMM do")}`;
    }

    renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth) {
        const { classes } = this.props;

        const start = startOfWeek(selectedDate, {weekStartsOn: 1});
        const end = endOfWeek(selectedDate, {weekStartsOn: 1});

        const dayIsBetween = isWithinInterval(date, { start, end });
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);
        const isFuture = isAfter(date, endOfWeek(new Date(), {weekStartsOn: 1}));

        const wrapperClassName = clsx({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
          [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
          [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return isFuture ? "" : (
          <div className={wrapperClassName}>
            <IconButton className={dayClassName}>
              <span> {format(date, "d")} </span>
            </IconButton>
          </div>
        );
    }


    render() {
        const { classes } = this.props;
        var report = this.state.report;
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <GridContainer alignItems='center'>
                      <GridItem md={7}>
                          <h3 className={classes.cardTitleWhite}>Reports</h3>
                          {report.reportingPeriod && (<p className={classes.cardCategoryWhite}>Showing {report.reportingPeriod.period.toLowerCase()} report from {report.reportingPeriod.startOfPeriod} to {report.reportingPeriod.inclusiveEndOfPeriod}</p>)}
                      </GridItem>
                      <GridItem md={3}>
                        <Tooltip title="Download as CSV">
                          <IconButton aria-label="csv" size="large" onClick={() => this.setState({generateCsvDialog: true})}>
                            <CloudDownloadOutlinedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download Report">
                          <IconButton aria-label="report" size="large" onClick={() => this.generateReport()}>
                            <AssessmentOutlinedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download Annual Report">
                            <IconButton aria-label="report" size="large" onClick={() => this.generateAnnualReport()}>
                                <BlurLinearIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                            </IconButton>
                        </Tooltip>
                        <Dialog open={this.state.generateCsvDialog}>
                          <DialogTitle id="form-dialog-title">Generate CSV</DialogTitle>
                          <DialogContent>
                            <FormControl fullWidth>
                              <InputLabel shrink>Collection hub</InputLabel>
                              <Select value={this.state.csvCollectionHub} onChange={(ev) => this.setState({csvCollectionHub: ev.target.value})}>
                                <MenuItem value="all">All</MenuItem>
                                {this.props.collectionHubs.map(hub => (<MenuItem value={hub.name}>{hub.name}</MenuItem>))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth>
                              <InputLabel shrink>View</InputLabel>
                              <Select value={this.state.csvView} onChange={(ev) => this.setState({csvView: ev.target.value})}>
                                <MenuItem value="guest">Guest</MenuItem>
                                <MenuItem value="referral">Referral</MenuItem>
                              </Select>
                            </FormControl>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => this.setState({generateCsvDialog: false})} color="primary">
                              Cancel
                            </Button>
                            <Button onClick={() => this.generateCsv()} color="primary">
                              Generate
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </GridItem>
                      <GridItem md={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                            {this.getPicker()}
                        </MuiPickersUtilsProvider >
                        <FormControl fullWidth>
                          <InputLabel style={{color: whiteColor}} shrink>Period</InputLabel>
                          <Select value={this.state.period} onChange={this.setPeriod} classes={{ root: classes.whiteColor, icon: classes.whiteColor}}>
                            <MenuItem value="WEEKLY">Weekly</MenuItem>
                            <MenuItem value="MONTHLY">Monthly</MenuItem>
                            <MenuItem value="QUARTERLY">Quarterly</MenuItem>
                            <MenuItem value="ANNUAL">Annual</MenuItem>
                            <MenuItem value="CUSTOM">Custom</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>

                  <GridContainer>
                    <GridItem md={6}>
                      <Card chart>
                        <CardHeader color="success">
                          Food aid vouchers
                        </CardHeader>
                        <CardBody>
                          <GridContainer alignItems='center'>
                            <GridItem md={6}>
                              <Chart options={{
                                       labels: ['New', 'Exiting', 'Approved'],
                                       colors: [infoColor[0], successColor[0], warningColor[0]],
                                       legend: {
                                         show: false
                                       },
                                       dataLabels: {
                                         formatter(val, opts) {
                                           const name = opts.w.globals.labels[opts.seriesIndex]
                                           const num = opts.w.globals.series[opts.seriesIndex]
                                           return [name, num]
                                         }
                                       },
                                     }}
                                     series={[
                                       report.foodAidReferralStats?.newReferrals || 0,
                                       report.foodAidReferralStats?.existingReferrals || 0,
                                       report.foodAidReferralStats?.approvedReferrals || 0
                                     ]}
                                     type="pie"
                                     width='100%'/>
                            </GridItem>
                            <GridItem md={6}>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>New: </Box> {report.foodAidReferralStats?.newReferrals}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Existing: </Box> {report.foodAidReferralStats?.existingReferrals}</Typography>
                              {this.state.period === 'WEEKLY' && <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Post assessment: </Box> {report.foodAidReferralStats?.postAssessmentReferrals}</Typography>}
                              <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Valid vouchers: </Box> {report.foodAidReferralStats?.validVouchers}</Box></Typography>
                              {this.state.period === 'WEEKLY'
                                 && (<React.Fragment>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Requires further assessment: </Box> {report.foodAidReferralStats?.requiresAssessmentReferrals}</Typography>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Assessment rejected: </Box> {report.foodAidReferralStats?.rejectedReferrals}</Typography>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Requires reassessment: </Box> {report.foodAidReferralStats?.awaitingReassessmentReferrals}</Typography>
                                       <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Invalid vouchers: </Box> {report.foodAidReferralStats?.requiresAssessmentReferrals + report.foodAidReferralStats?.rejectedReferrals + report.foodAidReferralStats?.awaitingReassessmentReferrals || null}</Box></Typography>
                                     </React.Fragment>)}
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Duplicate referrals received: </Box> {report.foodAidReferralStats?.duplicateReferrals}</Typography>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem md={6}>
                      <Card chart>
                        <CardHeader color="info">
                          Ready meal vouchers
                        </CardHeader>
                        <CardBody>
                          <GridContainer alignItems='center'>
                            <GridItem md={6}>
                              <Chart options={{
                                       labels: ['New', 'Exiting', 'Approved'],
                                       colors: [infoColor[0], successColor[0], warningColor[0]],
                                       legend: {
                                         show: false
                                       },
                                       dataLabels: {
                                         formatter(val, opts) {
                                           const name = opts.w.globals.labels[opts.seriesIndex]
                                           const num = opts.w.globals.series[opts.seriesIndex]
                                           return [name, num]
                                         }
                                       },
                                     }}
                                     series={[
                                       report.readyMealReferralStats?.newReferrals || 0,
                                       report.readyMealReferralStats?.existingReferrals || 0,
                                       report.readyMealReferralStats?.approvedReferrals || 0
                                     ]}
                                     type="pie"
                                     width='100%'/>
                            </GridItem>
                            <GridItem md={6}>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>New: </Box> {report.readyMealReferralStats?.newReferrals}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Existing: </Box> {report.readyMealReferralStats?.existingReferrals}</Typography>
                              {this.state.period === 'WEEKLY' && <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Post assessment: </Box> {report.readyMealReferralStats?.postAssessmentReferrals}</Typography>}
                              <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Valid vouchers: </Box> {report.readyMealReferralStats?.validVouchers}</Box></Typography>
                              {this.state.period === 'WEEKLY'
                                 && (<React.Fragment>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Requires further assessment: </Box> {report.readyMealReferralStats?.requiresAssessmentReferrals}</Typography>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Assessment rejected: </Box> {report.readyMealReferralStats?.rejectedReferrals}</Typography>
                                       <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Requires reassessment: </Box> {report.readyMealReferralStats?.awaitingReassessmentReferrals}</Typography>
                                       <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Invalid vouchers: </Box> {report.readyMealReferralStats?.requiresAssessmentReferrals + report.readyMealReferralStats?.rejectedReferrals + report.readyMealReferralStats?.awaitingReassessmentReferrals || null}</Box></Typography>
                                     </React.Fragment>)}
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Duplicate referrals received: </Box> {report.readyMealReferralStats?.duplicateReferrals}</Typography>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem md={6}>
                      <Card chart>
                        <CardHeader color="primary">
                          Food aid parcels
                        </CardHeader>
                        <CardBody>
                          <GridContainer alignItems='center'>
                            <GridItem md={6}>
                              <Chart options={{
                                       labels: ['Collected from Sufra', 'Delivered by Sufra', 'Emergency parcels by Sufra', 'Collected from PCC', 'Delivered by PCC', 'Emergency parcels by PCC', 'Collected from AYP', 'Delivered by AYP', 'Emergency parcels by AYP'],
                                       colors: [successColor[0], successColor[1], successColor[2], infoColor[0], infoColor[1], infoColor[2], primaryColor[0], primaryColor[1], primaryColor[2]],
                                       legend: {
                                         show: false
                                       },
                                       dataLabels: {
                                         formatter(val, opts) {
                                           const name = opts.w.globals.labels[opts.seriesIndex]
                                           const num = opts.w.globals.series[opts.seriesIndex]
                                           return [name, num]
                                         }
                                       },
                                     }}
                                     series={[
                                       report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['Sufra'] || 0,
                                       report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['Sufra'] || 0,
                                       report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['Sufra'] || 0,
                                       report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['PCC'] || 0,
                                       report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['PCC'] || 0,
                                       report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['PCC'] || 0,
                                       report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['AYP'] || 0,
                                       report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['AYP'] || 0,
                                       report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['AYP'] || 0
                                     ]}
                                     type="pie"
                                     width='100%'/>
                            </GridItem>
                            <GridItem md={6}>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Collected from Sufra: </Box> {report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['Sufra']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Delivered by Sufra: </Box> {report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['Sufra']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Emergency parcels by Sufra: </Box> {report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['Sufra']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Collected from PCC: </Box> {report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['PCC']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Delivered by PCC: </Box> {report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['PCC']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Emergency parcels by PCC: </Box> {report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['PCC']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Collected from AYP: </Box> {report.foodAidReferralStats?.numberOfParcelsCollectedByHub && report.foodAidReferralStats?.numberOfParcelsCollectedByHub['AYP']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Delivered by AYP: </Box> {report.foodAidReferralStats?.numberOfParcelsDeliveredByHub && report.foodAidReferralStats?.numberOfParcelsDeliveredByHub['AYP']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Emergency parcels by AYP: </Box> {report.foodAidReferralStats?.emergencyParcelsDistributedByHub && report.foodAidReferralStats?.emergencyParcelsDistributedByHub['AYP']}</Typography>
                              <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Parcels distributed: </Box> {report.foodAidReferralStats?.totalParcelsDistributed}</Box></Typography>
                              {this.state.period === 'WEEKLY' && <Typography className={classes.typography} paragraph={true}><Box fontWeight='fontWeightBold' display='inline'>Parcels uncollected: </Box> {report.foodAidReferralStats?.parcelsUncollected}</Typography>}
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Households: </Box> {report.foodAidReferralStats?.totalUniqueHouseholds}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Recipients: </Box> {report.foodAidReferralStats?.totalRecipients}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Unique recipients: </Box> {report.foodAidReferralStats?.totalUniqueRecipients}</Typography>

                            </GridItem>
                            <GridItem md={12}>
                              <Chart options={{
                                       dataLabels: {
                                         enabled: true
                                       },
                                       xaxis: {
                                         categories: (report.foodAidReferralStats?.periodStats || []).map(stats => stats.startOfPeriod),
                                         title: {
                                           text: 'Date'
                                         }
                                       },
                                       yaxis: {
                                         title: {
                                           text: 'Number of parcels'
                                         },
                                         min: 0,
                                         max: Math.max.apply(Math, (report.foodAidReferralStats?.periodStats || []).map(stats => stats.totalParcels))
                                       }
                                     }}
                                     series={[{
                                         name: "Total parcels distributed",
                                         data: (report.foodAidReferralStats?.periodStats || []).map(stats => stats.totalParcels),
                                     }]}
                                     type="line"
                                     width='100%'/>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem md={6}>
                      <Card chart>
                        <CardHeader color="primary">
                          Ready meal parcels
                        </CardHeader>
                        <CardBody>
                          <GridContainer alignItems='center'>
                            <GridItem md={6}>
                              <Chart options={{
                                       labels: ['Collected from Sufra', 'Delivered by Sufra', 'Emergency parcels by Sufra'],
                                       colors: [successColor[0], successColor[1], successColor[2]],
                                       legend: {
                                         show: false
                                       },
                                       dataLabels: {
                                         formatter(val, opts) {
                                           const name = opts.w.globals.labels[opts.seriesIndex]
                                           const num = opts.w.globals.series[opts.seriesIndex]
                                           return [name, num]
                                         }
                                       },
                                     }}
                                     series={[
                                       report.readyMealReferralStats?.numberOfParcelsCollectedByHub && report.readyMealReferralStats?.numberOfParcelsCollectedByHub['Sufra'] || 0,
                                       report.readyMealReferralStats?.numberOfParcelsDeliveredByHub && report.readyMealReferralStats?.numberOfParcelsDeliveredByHub['Sufra'] || 0,
                                       report.readyMealReferralStats?.emergencyParcelsDistributedByHub && report.readyMealReferralStats?.emergencyParcelsDistributedByHub['Sufra'] || 0
                                     ]}
                                     type="pie"
                                     width='100%'/>
                            </GridItem>
                            <GridItem md={6}>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Collected from Sufra: </Box> {report.readyMealReferralStats?.numberOfParcelsCollectedByHub && report.readyMealReferralStats?.numberOfParcelsCollectedByHub['Sufra']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Delivered by Sufra: </Box> {report.readyMealReferralStats?.numberOfParcelsDeliveredByHub && report.readyMealReferralStats?.numberOfParcelsDeliveredByHub['Sufra']}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Emergency parcels by Sufra: </Box> {report.readyMealReferralStats?.emergencyParcelsDistributedByHub && report.readyMealReferralStats?.emergencyParcelsDistributedByHub['Sufra']}</Typography>
                              <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Parcels distributed: </Box> {report.readyMealReferralStats?.totalParcelsDistributed}</Box></Typography>
                              {this.state.period === 'WEEKLY' && <Typography className={classes.typography} paragraph={true}><Box fontWeight='fontWeightBold' display='inline'>Parcels uncollected: </Box> {report.readyMealReferralStats?.parcelsUncollected}</Typography>}
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Households: </Box> {report.readyMealReferralStats?.totalUniqueHouseholds}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Recipients: </Box> {report.readyMealReferralStats?.totalRecipients}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Unique recipients: </Box> {report.readyMealReferralStats?.totalUniqueRecipients}</Typography>

                            </GridItem>
                            <GridItem md={12}>
                              <Chart options={{
                                       dataLabels: {
                                         enabled: true
                                       },
                                       xaxis: {
                                         categories: (report.readyMealReferralStats?.periodStats || []).map(stats => stats.startOfPeriod),
                                         title: {
                                           text: 'Date'
                                         }
                                       },
                                       yaxis: {
                                         title: {
                                           text: 'Number of parcels'
                                         },
                                         min: 0,
                                         max: Math.max.apply(Math, (report.readyMealReferralStats?.periodStats || []).map(stats => stats.totalParcels))
                                       }
                                     }}
                                     series={[{
                                         name: "Total parcels distributed",
                                         data: (report.readyMealReferralStats?.periodStats || []).map(stats => stats.totalParcels),
                                     }]}
                                     type="line"
                                     width='100%'/>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem md={6}>
                      <Card chart>
                        <CardHeader color="rose">
                          Assessments
                        </CardHeader>
                        <CardBody>
                          <GridContainer alignItems='center'>
                            <GridItem md={6}>
                              <Chart options={{
                                       labels: ['Food aid referral - Approved', 'Food aid referral - Rejected', 'Ready meal referral - Rejected', 'Ready meal referral - Rejected'],
                                       colors: [successColor[0], dangerColor[0], successColor[2], dangerColor[2]],
                                       legend: {
                                         show: false
                                       },
                                       dataLabels: {
                                         formatter(val, opts) {
                                           const name = opts.w.globals.labels[opts.seriesIndex]
                                           const num = opts.w.globals.series[opts.seriesIndex]
                                           return [name, num]
                                         }
                                       },
                                     }}
                                     series={[
                                       report.foodAidReferralStats?.approvedAssessments || 0,
                                       report.foodAidReferralStats?.rejectedAssessments || 0,
                                       report.readyMealReferralStats?.approvedAssessments || 0,
                                       report.readyMealReferralStats?.rejectedAssessments || 0
                                     ]}
                                     type="pie"
                                     width='100%'/>
                            </GridItem>
                            <GridItem md={6}>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Food aid referral - approved assessments: </Box> {report.foodAidReferralStats?.approvedAssessments}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Food aid referral - rejected assessments: </Box> {report.foodAidReferralStats?.rejectedAssessments}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Ready meal referral - approved assessments: </Box> {report.readyMealReferralStats?.approvedAssessments}</Typography>
                              <Typography className={classes.typography}><Box fontWeight='fontWeightBold' display='inline'>Ready meal referral - rejected assessments: </Box> {report.readyMealReferralStats?.rejectedAssessments}</Typography>
                              <Typography className={classes.typography} paragraph={true}><Box borderTop={1} borderBottom={1} display='inline'><Box fontWeight='fontWeightBold' display='inline'>Total assessments carried out: </Box> {report.foodAidReferralStats?.approvedAssessments + report.foodAidReferralStats?.rejectedAssessments + report.readyMealReferralStats?.approvedAssessments + report.readyMealReferralStats?.rejectedAssessments || null}</Box></Typography>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        );

    }


}
export default withStyles(styles)(Reports);
