import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from "assets/img/sufra-logo-new.png";
import AuthService from "services/AuthService.js";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sufra-nwl.org.uk">
        sufra-nwl.org.uk
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class Login extends Component {

    constructor(props) {
      super(props);
      this.handleLogin = this.handleLogin.bind(this);
      this.onChangeUsername = this.onChangeUsername.bind(this);
      this.onChangePassword = this.onChangePassword.bind(this);
      this.rememberMe = this.rememberMe.bind(this);
      this.rememberUser = this.rememberUser.bind(this);
      this.forgetUser = this.forgetUser.bind(this);
      this.getRememberedUser = this.getRememberedUser.bind(this);

      this.state = {
        username: '',
        password: '',
        message: '',
        rememberMe: false,
        loading: false,
        usernameError: false,
        usernameMessage: '',
        passwordError: false,
        passwordMessage: ''
      };
    }

    componentDidMount() {
        const username = this.getRememberedUser();
        this.setState({username: username || "", rememberMe: username ? true : false });
    }

    onChangeUsername(e) {
      const value = e.target.value || '';
      const message = (value.length === 0 && 'Email must be set') || (!value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && 'Invalid email') || ''
      this.setState({
        username: value,
        usernameError: message.length > 0,
        usernameMessage: message
      });
    }

    onChangePassword(e) {
      const value = e.target.value || '';
      const message = (value.length === 0 && 'Password must be set') || ''
      this.setState({
        password: value,
        passwordError: message.length > 0,
        passwordMessage: message
      });
    }

    rememberUser() {
        try {
            localStorage.setItem('sufra_remembered_user', this.state.username);
        } catch (error) {
            // Error saving data
        }
    }

    getRememberedUser() {
        try {
          const username = localStorage.getItem('sufra_remembered_user');
          if (username !== null) {
            return username;
          }
        } catch (error) {
          // Error retrieving data
        }
    }

    forgetUser(){
        try {
          localStorage.removeItem('sufra_remembered_user');
        } catch (error) {
         // Error removing
        }
    }

    rememberMe(ev) {
        ev.target.checked ? this.rememberUser() : this.forgetUser();
        this.setState({rememberMe: ev.target.checked})
    }

    handleLogin(e) {
      e.preventDefault();

      this.setState({ loading: true });

      if (!this.usernameError && !this.passwordError) {
        if (this.state.rememberMe)
            this.rememberUser();
        AuthService.login(this.state.username.trim(), this.state.password)
                   .then(() => {},
                         error => {
                             const resMessage = (error && error.response && error.response.data && error.response.data.message) || (error && error.message) || "Login failed. If you have just signed up, your account may not have been approved yet.";
                             this.setState({
                                 loading: false,
                                 message: resMessage
                             });
                         });
      } else {
        this.setState({ loading: false });
      }
    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.img} />
                <form className={classes.form} onSubmit={this.handleLogin}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    error={this.state.usernameError}
                    helperText={this.state.usernameMessage}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    error={this.state.passwordError}
                    helperText={this.state.passwordMessage}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={this.state.rememberMe} color="primary" onChange={this.rememberMe}/>}
                    label="Remember me"
                  />
                  {this.state.message && (<Typography variant="body2" color="error" align="center">{this.state.message}</Typography>)}
                  <Button
                    type="submit"
                    fullWidth
                    disabled={this.state.loading || this.state.username.length === 0 || this.state.password.length === 0 || this.state.passwordError || this.state.usernameError}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Login
                  </Button>
                  <Grid container justify='center'>
                    <Grid item>
                      <Link href="/sign-up" variant="body2">
                        {"Don't have an account? Create an account now"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Container>
        );
    }
}

export default withStyles(styles)(Login);
