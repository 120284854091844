import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyleSmall.js";
import {Visibility} from "@material-ui/icons";

class ReferralAssessmentsTable extends Component {
    constructor(props) {
        super(props);
        this.setOpen = this.setOpen.bind(this)

        this.state = {
          open: {}
        };
    }

    setOpen(key) {
        const open = this.state.open;
        open[key] = !open[key];
        this.setState({open: open})
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                      <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.assessments && this.props.assessments.map((assessment, idx) => {
                  return assessment && (
                    <React.Fragment>
                      <TableRow key={assessment.id} className={classes.tableBodyRowSummary}>
                        { Object.values(this.props.tableHead).map((mappingFunction, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={assessment.id}>
                              {mappingFunction(assessment)}
                            </TableCell>
                          );
                        })}
                          <TableCell className={classes.tableCellSummary} key={assessment.id}>
                              <Tooltip title="View further assessment">
                                  <IconButton aria-label="expand row" size="small" onClick={() => this.props.viewFurtherAssessment(this.props.referral.id, assessment.id)}>
                                      <Visibility/>
                                  </IconButton>
                              </Tooltip>
                          </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(ReferralAssessmentsTable);

ReferralAssessmentsTable.defaultProps = {
  tableHeaderColor: "gray"
};

ReferralAssessmentsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
