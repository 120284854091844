import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FurtherAssessmentsTable from "components/Table/FurtherAssessmentsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import Pagination from '@material-ui/lab/Pagination';
import {formatDate} from "../../util/DateUtils.js"
import {whiteColor} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class FurtherAssessments extends Component {

    constructor(props) {
        super(props);
        this.fetchFurtherAssessments = this.fetchFurtherAssessments.bind(this);
        this.editFurtherAssessment = this.editFurtherAssessment.bind(this);
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.changePage = this.changePage.bind(this);
        this.fetchReferral = this.fetchReferral.bind(this);
        this.state = {
            referrals: {},
            referralsOrder: [],
            numberOfResults: 0,
            numberOfPages: 1,
            page: 1,
            itemsPerPage: 20,
            orderBy: 'timestamp',
            order: 'desc'
        };
    }

    componentDidMount() {
        this.fetchFurtherAssessments()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchParam !== this.props.searchParam
            || prevState.orderBy !== this.state.orderBy
            || prevState.order !== this.state.order
            || prevState.page !== this.state.page) {
            this.fetchFurtherAssessments()
        }
    }

    fetchFurtherAssessments() {
        var link = `/furtherassessments?sort=${this.state.orderBy || 'timestamp'},${this.state.order || 'desc'}` +
            `&page=${this.state.page - 1}` +
            `&size=${this.state.itemsPerPage}` +
            `${this.props.searchParam && `&searchString=${this.props.searchParam}` || ""}`
        ApiService.request(link)
            .then(data => {
                this.setState({referrals: data.content.reduce(function(map, obj) {
                                                                  map[obj.id] = obj;
                                                                  return map;
                                                              }, {}),
                               referralsOrder: data.content.map(referral => referral.id),
                               numberOfResults: data.totalElements,
                               numberOfPages: data.totalPages})
            })
            .catch(err => {
                console.log(err);
            });
    }

    editFurtherAssessment(furtherAssessmentId, furtherAssessment) {
        return ApiService.request(`/furtherassessments/${furtherAssessmentId}`, 'PUT', furtherAssessment)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[data.id] = data;
                this.setState({referrals: referrals});
            })
            .catch(err => {
                console.log(err);
            });
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        return ApiService.request(`/furtherassessments/referral/${referralId}`, 'PUT', furtherAssessment)
            .then(data => {
                var referrals = this.state.referrals;
                const assessmentIds = referrals[referralId].furtherAssessments.map(assessment => assessment.id);
                referrals[referralId] = data;
                this.setState({referrals: referrals});
                console.log(data.furtherAssessments)
                console.log(assessmentIds)
                console.log(data.furtherAssessments.find(assessment => !assessmentIds.includes(assessment.id)).id)
                return data.furtherAssessments.find(assessment => !assessmentIds.includes(assessment.id)).id
            })
            .catch(err => {
                console.log(err);
            });
    }

    getCsv() {
        ApiService.requestCsv('/furtherassessments/csv')
    }

    fetchReferral(id) {
        return ApiService.request(`/referrals/${id}`)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[id] = data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    setOrderBy(prop) {
        const isAsc = this.state.orderBy === prop && this.state.order === 'asc';
        this.setState({order: isAsc ? 'desc' : 'asc', orderBy: prop});
    }

    changePage(event, newValue) {
        this.setState({ page: newValue });
    }

    render() {
        const { classes } = this.props;
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <GridContainer alignItems='center'>
                      <GridItem md={7}>
                          <h3 className={classes.cardTitleWhite}>Further Assessment</h3>
                          {(this.props.searchParam && this.props.searchParam.length > 0 ) ? (<p className={classes.cardCategoryWhite}>Searching for: {this.props.searchParam}</p>) : ""}
                          <p className={classes.cardCategoryWhite}>Showing {this.state.numberOfResults} referrals</p>
                      </GridItem>
                      <GridItem md={1}>
                        <Tooltip title="Download as CSV">
                          <IconButton aria-label="csv" size="large" onClick={() => this.getCsv()}>
                            <CloudDownloadOutlinedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                      <GridItem md={4}>
                          <Pagination  count={this.state.numberOfPages} color="primary" showFirstButton
                                       showLastButton page={this.state.page} onChange={this.changePage}/>
                      </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <FurtherAssessmentsTable
                    tableHeaderColor="primary"
                    tableHead={{"id": "ID",
                                "referralAgency": "Referral Agency",
                                "fullName": "Full Name",
                                "contactNumber": "Contact Number",
                                "address": "Address",
                                "postcode": "Postcode",
                                "deliveriesReceived": "Number of deliveries",
                                "assessmentStatus": "Assessment status"}}
                    mappingFunctions={{"id": referral => referral.id,
                                       "referralAgency": referral => referral.referralAgency,
                                       "fullName": referral => referral.guest.fullName,
                                       "contactNumber": referral => referral.guest.contactNumbers.toString(),
                                       "address": referral => referral.guest.address,
                                       "postcode": referral => referral.guest.postcode,
                                       "deliveriesReceived": referral => referral.deliveriesReceived,
                                       "assessmentStatus":  referral => referral.furtherAssessmentStatus !== "NONE" && referral.furtherAssessmentStatus || ""}}
                    referralsOrder={this.state.referralsOrder}
                    referrals={this.state.referrals}
                    editFurtherAssessment={this.editFurtherAssessment}
                    createFurtherAssessment={this.createFurtherAssessment}
                    fetchReferral={this.fetchReferral}
                    setOrderBy={this.setOrderBy}
                    orderBy={this.state.orderBy}
                    order={this.state.order}
                    getOptions={this.props.getOptions}
                    user={this.props.user}
                  />
                </CardBody>
                <CardFooter>
                    <Pagination count={this.state.numberOfPages} color="primary" showFirstButton showLastButton
                                page={this.state.page} onChange={this.changePage}/>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        );

    }
}
export default withStyles(styles)(FurtherAssessments);
