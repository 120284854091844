import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import {Visibility} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import GridItem from "components/Grid/GridItem.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import {dangerColor, infoColor, successColor} from "assets/jss/material-dashboard-react.js";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {enGB} from "date-fns/locale";
import {parseDate} from "../../util/DateUtils";
import ChipInput from "material-ui-chip-input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {format} from "date-fns";

const validation = {}

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

const GUEST_REFERRAL_DIFFERENCE_WARNING = "Value on guest differs to value on referral"
const styles = (theme) => ({
})
class ReadyMealsByRouteTable extends Component {
    constructor(props) {
        super(props);
        this.getReferralActions = this.getReferralActions.bind(this);
        this.setOpen = this.setOpen.bind(this)

        this.state = {
            open: {}
        };
    }

    setOpen(route) {
        const open = this.state.open;
        open[route] = !open[route]
        this.setState({open: open})
    }

    getReferralActions(key) {
        const referral = this.props.referrals[key];
        return (<GridContainer>
            <GridItem md={1}>
                <Tooltip title="View referral">
                    <IconButton aria-label="expand row" size="small" onClick={() => this.props.setViewReferralDialogId(key)}>
                        <Visibility/>
                    </IconButton>
                </Tooltip>
            </GridItem>
        </GridContainer>)
    }

    render() {
        const { classes } = this.props;
        var maxRoute = this.props.referralsOrder.map(referralId => this.props.referrals[referralId].scheduledRoute || 0).reduce((r1, r2) => Math.max(r1, r2), 0)
        return (
          <div className={classes.tableResponsive}>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          <TableSortLabel active={this.props.orderBy === prop}
                                          direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                          onClick={ev => {this.props.setOrderBy(prop)}}>
                            {this.props.tableHead[prop]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key='route'
                    >
                      Route
                    </TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {Array.from(Array(maxRoute+1).keys())
                      .map(route => {
                        return (
                          <React.Fragment>
                            <TableRow key={route} className={classes.tableBodyRowSummary}>
                              <TableCell className={classes.tableDetailsCell}>
                                <Tooltip title="View referrals">
                                  <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(route)}>
                                    {this.state.open[route] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                              <TableCell className={classes.tableDetailsCell} colSpan={6} key={"details"}>{route === 0 ? 'No Route' : `Route ${route}`}</TableCell>
                            </TableRow>
                              {!this.state.open[route] && this.props.referralsOrder.filter(id => (this.props.referrals[id].scheduledRoute || 0) === route).map((id, idx) => {
                                return (<TableRow key={route + "Referral" + id} className={classes.tableBodyRowSummary}>
                                          <TableCell className={classes.tableCellSummary} />
                                          { Object.keys(this.props.tableHead).map((prop, key) => {
                                            return (
                                              <TableCell className={classes.tableCellSummary} key={key}>
                                                {this.props.mappingFunctions[prop](this.props.referrals[id])}
                                              </TableCell>
                                            );
                                          })}
                                          <TableCell className={classes.tableCellSummary} key={"route"}>
                                            <FormControl>
                                                <InputLabel htmlFor="route">Route</InputLabel>
                                                <Select id="route"
                                                        name="route"
                                                        value={this.props.referrals[id].scheduledRoute || '0'}
                                                        onChange={ev => this.props.setRoute(id, ev.target.value)}>
                                                    <MenuItem value="0">None</MenuItem>
                                                    {Array.from(Array(maxRoute+1).keys())
                                                         .map(route => <MenuItem value={route+1}>{route+1}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell className={classes.tableCellSummary} key={"actions"}>
                                            {this.getReferralActions(id)}
                                          </TableCell>
                                        </TableRow>)
                                })
                              }
                          </React.Fragment>
                        )
                      })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(tableStyles, styles)(ReadyMealsByRouteTable);

ReadyMealsByRouteTable.defaultProps = {
  tableHeaderColor: "gray"
};

ReadyMealsByRouteTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
