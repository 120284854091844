import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ReadyMealsTable from "components/Table/ReadyMealsTable.js";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {formatDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import MapIcon from '@material-ui/icons/Map';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import ChipInput from "material-ui-chip-input";
import {format} from "date-fns";
import {parseDate} from "../../util/DateUtils";
import {enGB} from "date-fns/locale";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";


const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}

const referralValidation = {}
const GUEST_REFERRAL_DIFFERENCE_WARNING = "Value on guest differs to value on referral"
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];
const APPLIANCES = ['fridgeFreezer', 'fridge', 'microwave']

class ReadyMealsPendingReferralsDialog extends Component {

    constructor(props) {
      super(props);
      this.confirmArchive = this.confirmArchive.bind(this);
      this.confirmSwitchToFoodAidReferral = this.confirmSwitchToFoodAidReferral.bind(this);
      this.changeGuestValue = this.changeGuestValue.bind(this);
      this.getGuestSelector = this.getGuestSelector.bind(this);
      this.fetchGuests = this.fetchGuests.bind(this);
      this.setGuest = this.setGuest.bind(this);
      this.addGuest = this.addGuest.bind(this);
      this.changeReferralValue = this.changeReferralValue.bind(this)
      this.changeGuestValue = this.changeGuestValue.bind(this)
      this.changeGuestValueByIdx = this.changeGuestValueByIdx.bind(this)
      this.addGuestValueToList = this.addGuestValueToList.bind(this)
      this.deleteGuestValueByIdx = this.deleteGuestValueByIdx.bind(this)
      this.deleteGuestValueByValue = this.deleteGuestValueByValue.bind(this)
      this.calculateDifferences = this.calculateDifferences.bind(this)

      var referral = JSON.parse(JSON.stringify(this.props.pendingReferrals[0].referral))
      this.state = {
        pendingReferralIdx: 0,
        editReferral: referral,
        errors: {},
        guestReferralDifferences: this.calculateDifferences(referral, referral.guest),
        confirmArchiveReferral: null,
        guests: [],
        newReferralAgencyDialog: false,
        newReferralAgency: null
      };
    }

    componentDidMount() {
        this.fetchGuests()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.pendingReferralIdx !== this.state.pendingReferralIdx || prevProps.pendingReferrals.length !== this.props.pendingReferrals.length) {
            var pendingReferralIdx = (this.state.pendingReferralIdx < 0 && 0)
              || (this.state.pendingReferralIdx >= this.props.pendingReferrals.length && this.props.pendingReferrals.length - 1)
              || this.state.pendingReferralIdx
            var referral = JSON.parse(JSON.stringify(this.props.pendingReferrals[pendingReferralIdx].referral))
            var guest = referral.guest
            this.setState({
              pendingReferralIdx: pendingReferralIdx,
              editReferral: referral,
              errors: {},
              guestReferralDifferences: this.calculateDifferences(referral, guest)
            })
        }
    }

    calculateDifferences(referral, guest) {
      return guest && GUEST_FIELDS.reduce(function (result, key) {
          result[key] = !(key === 'contactNumbers' && referral.contactNumber.split(/[,/]/).every(contactNumber => guest.contactNumbers.includes(contactNumber.trim()))
              || key === 'dietaryRequirementsCore' && referral.dietaryRequirementsCore.every(dietaryRequirement => guest.dietaryRequirementsCore.includes(dietaryRequirement))
              || key === 'languagesSpokenCore' && referral.languagesSpokenCore && referral.languagesSpokenCore.every(language => guest.languagesSpokenCore.includes(language))
              || key === 'ages' && referral.ages && guest.ages.length === referral.ages.length && referral.ages.every(age => guest.ages.includes(age)) && guest.ages.every(age => referral.ages.includes(age))
              || (typeof guest[key] === 'string' || typeof referral[key] === 'string') && (guest[key] || '') === (referral[key] || '')
              || guest[key] === referral[key])
          return result
      }, {})
    }

    fetchGuests() {
        ApiService.request("/guests/all")
            .then(data => {
                this.setState({guests: data})
            })
            .catch(err => {
                console.log(err);
            });
    }

    confirmArchive() {
        this.props.editReferral(this.state.editReferral)
          .then(() => {
            this.props.archive(this.state.confirmArchiveReferral.id, this.state.confirmArchiveReferral.archiveReason, this.state.confirmArchiveReferral.archiveComment, this.state.confirmArchiveReferral.archiveDate)
                .then(() => this.setState({ confirmArchiveReferral: null }))
          })
    }

    confirmSwitchToFoodAidReferral() {
        this.props.editReferral(this.state.editReferral)
          .then(() => {
            this.props.switchReferralType(this.state.confirmSwitchToFoodAidReferralId)
                .then(() => this.setState({ confirmSwitchToFoodAidReferralId: null }))
          })
    }

    accept() {
        var referral = this.props.pendingReferrals[this.state.pendingReferralIdx].referral
        this.props.editReferral(this.state.editReferral)
          .then(() => {
            this.props.accept(referral.id);
          })
    }

    save() {
        this.props.editPendingReferral(this.state.editReferral)
          .then(referral => {
            if (referral) {
              this.setState({editReferral: JSON.parse(JSON.stringify(referral))})
            }
          })
    }

    setGuest(referralId, guestId) {
        this.props.setGuest(referralId, guestId)
            .then((referral) => {
                var guest = referral.guest;
                var editReferral = this.state.editReferral
                editReferral.guest = JSON.parse(JSON.stringify(referral.guest))
                this.setState({
                    editReferral: editReferral,
                    guestReferralDifferences: this.calculateDifferences(referral, guest)
                });
            })
    }

    addGuest() {
        var referral = this.props.pendingReferrals[this.state.pendingReferralIdx].referral
        this.props.addGuest(referral.id)
            .then((referral) => {
                var guest = referral.guest;
                var guests = this.state.guests;
                guests.push(guest)
                var editReferral = this.state.editReferral
                editReferral.guest = JSON.parse(JSON.stringify(referral.guest))
                this.setState({
                    guests: guests,
                    editReferral: editReferral,
                    guestReferralDifferences: this.calculateDifferences(referral, guest)
                });
            })
    }

    changeReferralValue(field, value) {
        var editReferral = this.state.editReferral;
        var referralErrors = this.state.errors || {};
        editReferral[field] = value;
        referralErrors[field] = referralValidation[field] && referralValidation[field](value)
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    changeGuestValue(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field] = value;
        guestErrors[field] = guestValidation[field] && guestValidation[field](value)
        guestReferralDifferences[field] = null;
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors, guestReferralDifferences: guestReferralDifferences});
    }

    changeGuestValueByIdx(field, idx, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field][idx] = value;
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors, guestReferralDifferences: guestReferralDifferences});
    }

    deleteGuestValueByIdx(field, idx) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].splice(idx, 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors, guestReferralDifferences: guestReferralDifferences});
    }

    deleteGuestValueByValue(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].splice(editGuest[field].indexOf(value), 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors, guestReferralDifferences: guestReferralDifferences});
    }

    addGuestValueToList(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].push(value);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors, guestReferralDifferences: guestReferralDifferences});
    }

    getGuestSelector() {
        const filterOptions = createFilterOptions({
          stringify: guest => `${guest.id}__${guest.fullName}__${guest.address}__${guest.postcode}__${guest.contactNumbers.toString()}`,
        });
        const currentReferralGuestMatching = this.props.pendingReferrals[this.state.pendingReferralIdx];
        const currentReferral = currentReferralGuestMatching.referral;
        const currentGuest = currentReferralGuestMatching.referral.guest;
        const suggestedGuests = currentReferralGuestMatching.suggestedGuests || [];
        const guests = this.state.guests.filter(guest => suggestedGuests.includes(guest.id))
                           .concat(this.state.guests.filter(guest => !suggestedGuests.includes(guest.id)))
        return (<GridContainer>
                  <GridItem md={11}>
                    <Autocomplete id={"select-guest-" + currentReferral?.id}
                                  value={currentGuest}
                                  options={guests}
                                  loading={this.state.guests?.length === 0}
                                  getOptionLabel={(guest) => guest.fullName}
                                  fullWidth
                                  renderOption={(guest) => <Typography>{`${guest.fullName}, ${guest.address}, ${guest.postcode}, ${guest.contactNumbers.toString()}`}</Typography>}
                                  onChange={(event, newValue) => this.setGuest(currentReferral.id, newValue.id)}
                                  groupBy={(guest) => (suggestedGuests && suggestedGuests.includes(guest.id)) ? "Suggested guests" : "All guests"}
                                  getOptionSelected={(guest, value) => value.id === guest.id}
                                  autoComplete
                                  renderInput={(params) => <TextField {...params} label="Select guest" variant="outlined" />}
                                  filterOptions={filterOptions} />
                  </GridItem>
                  <GridItem md={1}>
                    <Tooltip title="Add new guest">
                      <IconButton>
                        <AddCircleOutlineOutlinedIcon style={{ color: successColor[0] }} onClick={() => this.addGuest()}/>
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                </GridContainer>)
    }

    render() {
        const { classes } = this.props;
        const referralAgencyFilter = createFilterOptions();
        var originalReferral = this.props.pendingReferrals[this.state.pendingReferralIdx].referral
        var referral = this.state.editReferral || {}
        var guest = referral.guest || {}
        var isGuestSet = guest?.id
        var guestReferralDifferences = this.state.guestReferralDifferences || {}
        var errors = this.state.errors || {}
        var guestErrors = errors.guest || {}
        return (
          <Dialog fullWidth maxWidth="md" open={this.props.open}>
            <DialogTitle>
              <GridContainer alignItems="center">
                <GridItem md={9}>
                  <Typography variant="h5">
                    {originalReferral?.id}: {originalReferral?.guest?.fullName || originalReferral?.fullName}
                  </Typography>
                </GridItem>
                <GridItem>
                  <Typography>
                    {this.state.pendingReferralIdx + 1} of {this.props.pendingReferrals?.length}
                  </Typography>
                </GridItem>
                <GridItem>
                  <Tooltip title="Previous referral">
                    <IconButton aria-label="previousReferral" size="large" onClick={() => this.setState({ pendingReferralIdx: this.state.pendingReferralIdx - 1 })} disabled={this.state.pendingReferralIdx <= 0}>
                      <NavigateBeforeIcon/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Next referral">
                    <IconButton aria-label="nextReferral" size="large" onClick={() => this.setState({ pendingReferralIdx: this.state.pendingReferralIdx + 1 })} disabled={this.state.pendingReferralIdx >= this.props.pendingReferrals.length-1}>
                      <NavigateNextIcon/>
                    </IconButton>
                  </Tooltip>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent>
              <Dialog open={this.state.confirmArchiveReferral} onClose={() => this.setState({confirmArchiveReferral: null})}>
                  <DialogTitle id="confirm-archive-title">{"Archive referral?"}</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Are you sure you wish to archive referral {this.state.confirmArchiveReferral?.id} for {this.state.confirmArchiveReferral?.guest?.fullName}?
                      </DialogContentText>
                      <FormControl>
                          <InputLabel htmlFor="archiveReason">Archive reason</InputLabel>
                          <Select id="archiveReason"
                                  name="archiveReason"
                                  value={this.state.confirmArchiveReferral?.archiveReason || 'OTHER'}
                                  onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveReason: ev.target.value} })}>
                          <MenuItem value="DUPLICATE_RECEIVING_FOOD_PARCELS">Duplicate - receiving food parcels</MenuItem>
                          <MenuItem value="DUPLICATE_RECEIVING_READY_MEALS">Duplicate - receiving ready meals</MenuItem>
                          <MenuItem value="GUEST_REQUESTED">Guest has requested for parcels to stop</MenuItem>
                          <MenuItem value="MOVED_AWAY">Guest has moved away</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                          </Select>
                      </FormControl><br/>
                      <TextField id="archiveComment"
                                 label="Archive Comment"
                                 multiline
                                 InputLabelProps={{shrink: true}}
                                 value={this.state.confirmArchiveReferral?.archiveComment || ''}
                                 onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveComment: ev.target.value} })}/>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => this.setState({confirmArchiveReferral: null})} color="primary">
                          No
                      </Button>
                      <Button onClick={this.confirmArchive} color="primary" autoFocus>
                          Yes
                      </Button>
                  </DialogActions>
              </Dialog>
              <Dialog open={this.state.confirmSwitchToFoodAidReferralId} onClose={() => this.setState({confirmSwitchToFoodAidReferralId: null})}>
                  <DialogTitle id="confirm-switch-referral-type-title">{"Switch to Food Bank referral?"}</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Are you sure you wish to change referral {this.state.confirmSwitchToFoodAidReferralId} for {this.state.pendingReferrals?.find(referral => referral.id === this.state.confirmSwitchToFoodAidReferralId)?.guest?.fullName} to a Food Bank referral?
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => this.setState({confirmSwitchToFoodAidReferralId: null})} color="primary">
                          No
                      </Button>
                      <Button onClick={this.confirmSwitchToFoodAidReferral} color="primary" autoFocus>
                          Yes
                      </Button>
                  </DialogActions>
              </Dialog>
              <Dialog open={this.state.newReferralAgencyDialog}
                      onClose={() => this.setState({newReferralAgencyDialog: false})}>
                  <DialogTitle id="confirm-archive-title">{"Create new referral agency"}</DialogTitle>
                  <DialogContent>
                    <TextField id="newReferralAgency"
                               label="Referral Agency"
                               error={this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === this.state.newReferralAgency)}
                               value={this.state.newReferralAgency || ''}
                               fullWidth
                               onChange={ev => this.setState({ newReferralAgency: ev.target.value })}
                               helperText={this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === this.state.newReferralAgency)
                                             && "Referral agency already exists"}/>
                  </DialogContent>
                  <DialogActions>
                        <Button onClick={() => this.setState({
                            newReferralAgencyDialog: false,
                            newReferralAgency: null
                        })} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.addNewReferralAgency} color="primary" autoFocus>
                            Save
                        </Button>
                  </DialogActions>
              </Dialog>
              <GridContainer alignItems="flex-start" spacing={10}>
                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Referee details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={6}>
                            <TextField id="referralTimestamp"
                                       label="Timestamp"
                                       InputProps={{readOnly: true}}
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.timestamp || ''}/>
                            <FormControl fullWidth>
                                <Autocomplete id="referralAgency" name="referralAgency"
                                              value={referral.referralAgency || ''}
                                              onChange={(ev, newValue) => {
                                                if (typeof newValue === 'string') {
                                                  this.setState({ newReferralAgencyDialog: true, newReferralAgency: newValue })
                                                }
                                                else if (newValue && newValue.inputValue) {
                                                  this.setState({ newReferralAgencyDialog: true, newReferralAgency: newValue.inputValue })
                                                }
                                                else {
                                                  this.changeReferralValue("referralAgency", newValue.value)}
                                                }
                                              }
                                              filterOptions={(options, params) => {
                                                const filtered = referralAgencyFilter(options, params);
                                                if (params.inputValue !== '' && !filtered.some(opt => opt.value === params.inputValue)) {
                                                  filtered.push({
                                                    inputValue: params.inputValue,
                                                    value: `Add "${params.inputValue}"`,
                                                  });
                                                }
                                                return filtered;
                                              }}
                                              disableClearable
                                              freeSolo
                                              options={this.props.getOptions('REFERRAL_AGENCY')}
                                              getOptionLabel={option => (typeof option === 'string') && option || option.inputValue || option.value}
                                              renderOption={option => <li>{option.value}</li>}
                                              renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} label="Referral Agency" />}/>
                                {!this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === referral.referralAgency) && <FormHelperText error={true}>Invalid referral agency</FormHelperText>}
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="nameOfAuthorisedSignatory"
                                       label="Referee name"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.nameOfAuthorisedSignatory || ''}
                                       error={errors['nameOfAuthorisedSignatory'] && errors['nameOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("nameOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['nameOfAuthorisedSignatory'] || ''}/>
                            <TextField id="phoneOfAuthorisedSignatory"
                                       label="Referee phone"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.phoneOfAuthorisedSignatory || ''}
                                       error={errors['phoneOfAuthorisedSignatory'] && errors['phoneOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("phoneOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['phoneOfAuthorisedSignatory'] || ''}/>
                            <TextField id="emailOfAuthorisedSignatory"
                                       label="Referee email"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.emailOfAuthorisedSignatory || ''}
                                       error={errors['emailOfAuthorisedSignatory'] && errors['emailOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("emailOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['emailOfAuthorisedSignatory'] || ''}/>
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </GridItem>

                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Guest details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={5}>
                              <TextField id="referralFullName"
                                         label="Referral full name"
                                         InputProps={{readOnly: true}}
                                         InputLabelProps={{shrink: true}}
                                         fullWidth
                                         value={referral?.fullName || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                (<Tooltip title="Use full name from latest referral">
                                     <IconButton onClick={e => this.changeGuestValue("fullName", referral.fullName)}>
                                         <ArrowForwardIcon/>
                                     </IconButton>
                                 </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {!isGuestSet && this.getGuestSelector()}
                              {isGuestSet &&
                                 (<TextField id="guestFullName"
                                             label="Guest full name"
                                             InputLabelProps={{shrink: true,}}
                                             fullWidth
                                             error={guestReferralDifferences['fullName'] || guestErrors['fullName'] && guestErrors['fullName'].length > 0}
                                             value={guest.fullName || ''}
                                             onChange={ev => this.changeGuestValue("fullName", ev.target.value)}
                                             helperText={guestReferralDifferences['fullName'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['fullName'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralAddress"
                                           label="Referral address"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.address || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use address from latest referral">
                                    <IconButton onClick={e => this.changeGuestValue("address", referral.address)}>
                                      <ArrowForwardIcon/>
                                    </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<TextField id="guestAddress"
                                             label="Guest address"
                                             InputLabelProps={{shrink: true}}
                                             margin="dense"
                                             fullWidth
                                             error={guestReferralDifferences['address'] || guestErrors['address'] && guestErrors['address'].length > 0}
                                             value={guest.address || ''}
                                             onChange={ev => this.changeGuestValue("address", ev.target.value)}
                                             helperText={guestReferralDifferences['address'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['address'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralPostcode"
                                           label="Referral postcode"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.postcode || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use postcode from latest referral">
                                    <IconButton onClick={e => this.changeGuestValue("postcode", referral.postcode)}>
                                      <ArrowForwardIcon/>
                                    </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<TextField id="guestPostcode"
                                             label="Guest postcode"
                                             InputLabelProps={{shrink: true,}}
                                             fullWidth
                                             error={guestReferralDifferences['postcode'] || guestErrors['postcode'] && guestErrors['postcode'].length > 0}
                                             value={guest.postcode || ''}
                                             onChange={ev => this.changeGuestValue("postcode", ev.target.value)}
                                             helperText={guestReferralDifferences['postcode'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['postcode'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralContactNumber"
                                           label="Referral contact number"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.contactNumber || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Add contact number(s) from referral">
                                      <IconButton onClick={e => referral?.contactNumber.split(/[,/]/).forEach(contactNumber => this.addGuestValueToList("contactNumbers", contactNumber.trim()))}>
                                          <Add/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<React.Fragment>
                                    <InputLabel shrink={true}
                                                error={guestReferralDifferences['contactNumbers'] || guestErrors['contactNumbers'] && guestErrors['contactNumbers'].some(error => error && error.length > 0)}>
                                        Guest contact numbers
                                    </InputLabel>
                                    {guest.contactNumbers.map((contactNumber, idx) => {
                                        return (<React.Fragment>
                                            <div>
                                                <TextField id={"guestContactNumber" + idx}
                                                           value={contactNumber || ''}
                                                           InputLabelProps={{shrink: true,}}
                                                           error={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] && guestErrors['contactNumbers'][idx].length > 0}
                                                           onChange={ev => this.changeGuestValueByIdx('contactNumbers', idx, ev.target.value)}
                                                           helperText={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] || ''}/>
                                                <IconButton aria-label="remove"
                                                            size="small"
                                                            onClick={() => this.deleteGuestValueByIdx('contactNumbers', idx)}>
                                                    <DeleteIcon/>
                                                </IconButton></div>
                                            {guestReferralDifferences['contactNumbers'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                                        </React.Fragment>)})}
                                    <IconButton aria-label="add"
                                                size="small"
                                                onClick={() => this.addGuestValueToList('contactNumbers', '')}>
                                        <AddCircleOutlineOutlinedIcon/>
                                    </IconButton>
                                  </React.Fragment>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralDisabled"
                                           label="Referral disabled"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.disabled ? 'Disabled' : 'None'}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use disabled from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("disabled", referral.disabled)}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<React.Fragment>
                                    <FormControlLabel control={<Checkbox checked={guest?.disabled || false}
                                                                         onChange={ev => this.changeGuestValue('disabled', ev.target.checked)}
                                                                         name="disabled" />}
                                                      label="Disabled"/>
                                    {guestReferralDifferences['disabled'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                                  </React.Fragment>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralDisabledInfo"
                                           label="Referral disabled info"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Add disabled info from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("disabledInfo", (guest.disabledInfo || '') + (referral.disabledInfo || ''))}>
                                          <Add/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<TextField id="guestDisabledInfo"
                                             label="Guest disabled information"
                                             multiline
                                             fullWidth
                                             InputLabelProps={{shrink: true,}}
                                             error={guestReferralDifferences['disabledInfo'] || guestErrors['disabledInfo'] && guestErrors['disabledInfo'].length > 0}
                                             value={guest?.disabledInfo || ''}
                                             onChange={ev => this.changeGuestValue("disabledInfo", ev.target.value)}
                                             helperText={guestReferralDifferences['disabledInfo'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['disabledInfo'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralAccessibilityIssues"
                                           label="Referral accessibility issues"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.accessibilityIssues || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Add accessibility issues from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("accessibilityIssues", (guest.accessibilityIssues || '') + (referral.accessibilityIssues || ''))}>
                                          <Add/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<TextField id="guestAccessibilityIssues"
                                             label="Guest accessibility issues"
                                             multiline
                                             fullWidth
                                             InputLabelProps={{shrink: true,}}
                                             error={guestReferralDifferences['accessibilityIssues'] || guestErrors['accessibilityIssues'] && guestErrors['accessibilityIssues'].length > 0}
                                             value={guest.accessibilityIssues || ''}
                                             onChange={ev => this.changeGuestValue("accessibilityIssues", ev.target.value)}
                                             helperText={guestReferralDifferences['accessibilityIssues'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['accessibilityIssues'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralGender"
                                           label="Referral gender"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.gender || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use gender from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("gender", referral.gender)}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<React.Fragment>
                                    <InputLabel htmlFor="gender" shrink={true} error={guestReferralDifferences['gender']}>Gender</InputLabel>
                                    <Select id="gender"
                                            name="gender"
                                            fullWidth
                                            value={guest.gender || ''}
                                            onChange={ev => this.changeGuestValue("gender", ev.target.value)}>
                                        <MenuItem value="FEMALE">Female</MenuItem>
                                        <MenuItem value="MALE">Male</MenuItem>
                                        <MenuItem value="NON_BINARY">Non-Binary</MenuItem>
                                        <MenuItem value="">Prefer not to say</MenuItem>
                                    </Select>
                                    {guestReferralDifferences['gender'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                                  </React.Fragment>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralNumberOfPeople"
                                           label="Referral number of people"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.numberOfPeople || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use number of people from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("numberOfPeople", referral.numberOfPeople)}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<TextField id="guestNumberOfPeople"
                                             label="Guest number of people"
                                             InputLabelProps={{shrink: true,}}
                                             fullWidth
                                             type="number"
                                             error={guestReferralDifferences['numberOfPeople'] || guestErrors['numberOfPeople'] && guestErrors['numberOfPeople'].length > 0}
                                             value={guest.numberOfPeople}
                                             onChange={ev => this.changeGuestValue("numberOfPeople", ev.target.value)}
                                             helperText={guestReferralDifferences['numberOfPeople'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['numberOfPeople'] || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralEthnicity"
                                           label="Referral ethnicity"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={(referral?.ethnicityCore || '') + (referral?.ethnicityOther && ` - ${referral?.ethnicityOther}` || '')}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use ethnicity from latest referral">
                                      <IconButton onClick={e => {
                                          this.changeGuestValue("ethnicityCore", referral.ethnicityCore);
                                          this.changeGuestValue("ethnicityOther", referral.ethnicityOther)
                                      }}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<OptionWithOther coreField="ethnicityCore"
                                                   coreLabel="Guest ethnicity"
                                                   fullWidth
                                                   id={'guest'}
                                                   disabled={false}
                                                   object={guest || {}}
                                                   changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                                   options={this.props.getOptions('ETHNICITY').map(option => option.value)}
                                                   otherField="ethnicityOther"
                                                   helperText={guestReferralDifferences['ethnicityCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['ethnicityOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralFaith"
                                           label="Referral faith"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={(referral?.faithCore || '') + (referral?.faithOther && ` - ${referral?.faithOther}` || '')}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use faith from latest referral">
                                      <IconButton onClick={e => {
                                          this.changeGuestValue("faithCore", referral.faithCore);
                                          this.changeGuestValue("faithOther", referral.faithOther)
                                      }}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<OptionWithOther coreField="faithCore"
                                                   coreLabel="Guest faith"
                                                   fullWidth
                                                   id={'guest'}
                                                   disabled={false}
                                                   object={guest || {}}
                                                   changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                                   options={this.props.getOptions('FAITH').map(option => option.value)}
                                                   otherField="faithOther"
                                                   helperText={guestReferralDifferences['faithCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['faithOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralDOB"
                                           label="Referral DOB"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.dob || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use DOB from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("dob", referral.dob)}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<React.Fragment>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                      <KeyboardDatePicker value={guest.dob && parseDate(guest.dob) || null}
                                                          autoOk
                                                          label="Guest DOB"
                                                          InputLabelProps={{shrink: true,}}
                                                          variant="inline"
                                                          onChange={(date) => this.changeGuestValue("dob", format(date, 'dd-MM-yyyy'))}
                                                          format="dd-MM-yyyy"/>
                                      {guestReferralDifferences['dob'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                                    </MuiPickersUtilsProvider >
                                  </React.Fragment>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralAges"
                                           label="Referral ages"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.ages?.toString() || ''}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Use ages from latest referral">
                                      <IconButton onClick={e => this.changeGuestValue("ages", referral.ages.slice())}>
                                          <ArrowForward/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<React.Fragment>
                                    <ChipInput value={guest.ages || []}
                                               label="Guest ages"
                                               InputLabelProps={{shrink: true,}}
                                               fullWidth
                                               onBeforeAdd={(chip) => chip.match(/^\d+m?$/)}
                                               onAdd={(age) => this.addGuestValueToList("ages", age)}
                                               onDelete={(age) => this.deleteGuestValueByValue("ages", age)}
                                               allowDuplicates={true} />
                                    {guestReferralDifferences['ages'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                                  </React.Fragment>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralDietaryRequirements"
                                           label="Referral dietary requirements"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.dietaryRequirementsCore?.toString() || '' + (referral?.dietaryRequirementsOther && ` - ${referral?.dietaryRequirementsOther}` || '')}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Add dietary requirements from latest referral">
                                      <IconButton onClick={e => {
                                          this.changeGuestValue("dietaryRequirementsCore", guest.dietaryRequirementsCore.concat(referral.dietaryRequirementsCore));
                                          this.changeGuestValue("dietaryRequirementsOther", (guest.dietaryRequirementsOther || '') + (referral.dietaryRequirementsOther || ''))
                                      }}>
                                          <Add/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<OptionWithOther coreField="dietaryRequirementsCore"
                                                   coreLabel="Guest dietary requirements"
                                                   fullWidth
                                                   id={'guest'}
                                                   disabled={false}
                                                   multiple
                                                   object={guest || {}}
                                                   changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                                   options={this.props.getOptions('DIETARY_REQUIREMENTS').map(option => option.value)}
                                                   otherField="dietaryRequirementsOther"
                                                   helperText={guestReferralDifferences['dietaryRequirementsCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['dietaryRequirementsOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>)}
                          </GridItem>

                          <GridItem md={5}>
                                <TextField id="referralLanguagesSpoken"
                                           label="Referral languages spoken"
                                           InputProps={{readOnly: true}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={referral?.languagesSpokenCore?.toString() || '' + (referral?.languagesSpokenOther && ` - ${referral?.languagesSpokenOther}` || '')}/>
                          </GridItem>
                          <GridItem md={1}>
                              {isGuestSet &&
                                 (<Tooltip title="Add languages spoken from latest referral">
                                      <IconButton onClick={e => {
                                          this.changeGuestValue("languagesSpokenCore", guest.languagesSpokenCore.concat(referral.languagesSpokenCore));
                                          this.changeGuestValue("languagesSpokenOther", (guest.languagesSpokenOther || '') + (referral.languagesSpokenOther || ''))
                                      }}>
                                          <Add/>
                                      </IconButton>
                                  </Tooltip>)}
                          </GridItem>
                          <GridItem md={5}>
                              {isGuestSet &&
                                 (<OptionWithOther coreField="languagesSpokenCore"
                                                   coreLabel="Guest languages spoken"
                                                   fullWidth
                                                   id={'guest'}
                                                   disabled={false}
                                                   multiple
                                                   object={guest || {}}
                                                   changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                                   options={this.props.getOptions('LANGUAGES_SPOKEN').map(option => option.value)}
                                                   otherField="languagesSpokenOther"
                                                   helperText={guestReferralDifferences['languagesSpokenCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['languagesSpokenOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>)}
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </GridItem>
                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Referral details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink={true} htmlFor="groupsMember">Groups</InputLabel>
                                <Select id="groupsMember" name="groupsMember" multiple value={referral?.groupsMember || []} onChange={ev => this.changeReferralValue("groupsMember", ev.target.value)}>
                                    {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value} disabled={!option.active}>{option.value}</MenuItem>))}
                                </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}/>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.homelessOrTemporaryAccommodation || false}
                                                 onChange={ev => this.changeReferralValue('homelessOrTemporaryAccommodation', ev.target.checked)}
                                                 name="homelessOrTemporaryAccommodation" />}
                              label="Homeless or temporary accommodation"/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.cannotAffordToPurchaseFood || false}
                                                 onChange={ev => this.changeReferralValue('cannotAffordToPurchaseFood', ev.target.checked)}
                                                 name="cannotAffordToPurchaseFood" />}
                              label="Cannot afford to purchase food"/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControl fullWidth>
                              <InputLabel shrink={true}>Appliances</InputLabel>
                              <Select id="appliances"
                                      name="appliances"
                                      multiple
                                      value={APPLIANCES.filter(appliance => referral[appliance])}
                                      onChange={ev => APPLIANCES.forEach(appliance => this.changeReferralValue(appliance, ev.target.value.includes(appliance)))}>
                                <MenuItem value='fridge'>Fridge</MenuItem>
                                <MenuItem value='fridgeFreezer'>Fridge freezer</MenuItem>
                                <MenuItem value='microwave'>Microwave</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.cookingFacilities || false}
                                                 onChange={ev => this.changeReferralValue('cookingFacilities', ev.target.checked)}
                                                 name="cookingFacilities" />}
                              label="Cooking facilities"/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="numberOfDeliveries"
                                       label="Number of deliveries"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.numberOfDeliveries || ''}
                                       error={errors['numberOfDeliveries'] && errors['numberOfDeliveries'].length > 0}
                                       onChange={ev => this.changeReferralValue("numberOfDeliveries", ev.target.value)}
                                       helperText={errors['numberOfDeliveries'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="furtherInfo"
                                       label="Further info"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       multiline
                                       value={referral?.furtherInfo || ''}
                                       error={errors['furtherInfo'] && errors['furtherInfo'].length > 0}
                                       onChange={ev => this.changeReferralValue("furtherInfo", ev.target.value)}
                                       helperText={errors['furtherInfo'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                                control={<Checkbox id="permissionToContact"
                                                   name="permissionToContact"
                                                   checked={referral?.permissionToContact || false}
                                                   onChange={ (ev) => this.changeReferralValue("permissionToContact", ev.target.checked) } />}
                                label="Permission to contact guest"/>
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              {referral?.existingReferralWarning && <FormHelperText error={true}>{referral?.existingReferralWarning}</FormHelperText>}
              <Button onClick={() => this.accept()} disabled={!isGuestSet || referral?.existingReferralWarning || referral?.status !== 'PENDING'}>Accept</Button>
              <Button onClick={() => this.setState({confirmArchiveReferral: { ...referral, archiveReason: referral?.archiveReason || referral?.existingReferralWarning?.includes('Food Bank') && 'DUPLICATE_RECEIVING_FOOD_PARCELS' || referral?.existingReferralWarning?.includes('Ready Meal') && 'DUPLICATE_RECEIVING_READY_MEALS' || 'OTHER'}})}
                      disabled={!isGuestSet || referral?.status === 'ARCHIVED'}>
                Archive
              </Button>
              <Button onClick={() => this.setState({confirmSwitchToFoodAidReferralId: referral.id})}>
                Change to Food Bank referral
              </Button>
              <Button onClick={() => this.save()}>Save</Button>
              <Button onClick={this.props.handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        );

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(ReadyMealsPendingReferralsDialog))
