import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ReadyMealsTable from "components/Table/ReadyMealsTable.js";
import Card from "@material-ui/core/Card";
import ReferralTimelineTable from "components/Table/ReferralTimelineTable.js";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {formatDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import MapIcon from '@material-ui/icons/Map';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import ChipInput from "material-ui-chip-input";
import {format} from "date-fns";
import {parseDate} from "../../util/DateUtils";
import {enGB} from "date-fns/locale";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";


const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}

const referralValidation = {}
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];
const APPLIANCES = ['fridgeFreezer', 'fridge', 'microwave']

class ReadyMealDialog extends Component {

    constructor(props) {
      super(props);
      this.changeGuestValue = this.changeGuestValue.bind(this);
      this.changeReferralValue = this.changeReferralValue.bind(this)
      this.changeGuestValue = this.changeGuestValue.bind(this)
      this.changeGuestValueByIdx = this.changeGuestValueByIdx.bind(this)
      this.addGuestValueToList = this.addGuestValueToList.bind(this)
      this.deleteGuestValueByIdx = this.deleteGuestValueByIdx.bind(this)
      this.deleteGuestValueByValue = this.deleteGuestValueByValue.bind(this)

      this.state = {
        editReferral: JSON.parse(JSON.stringify(this.props.referral)),
        errors: {},
      };
    }

    save() {
        this.props.editReferral(this.state.editReferral)
          .then(referral => {
            if (referral) {
              this.setState({editReferral: JSON.parse(JSON.stringify(referral))})
            }
          })
    }

    changeReferralValue(field, value) {
        var editReferral = this.state.editReferral;
        var referralErrors = this.state.errors || {};
        editReferral[field] = value;
        referralErrors[field] = referralValidation[field] && referralValidation[field](value)
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    changeGuestValue(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        editGuest[field] = value;
        guestErrors[field] = guestValidation[field] && guestValidation[field](value)
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    changeGuestValueByIdx(field, idx, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        editGuest[field][idx] = value;
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    deleteGuestValueByIdx(field, idx) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        editGuest[field].splice(idx, 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    deleteGuestValueByValue(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        editGuest[field].splice(editGuest[field].indexOf(value), 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    addGuestValueToList(field, value) {
        var editReferral = this.state.editReferral;
        var editGuest = editReferral.guest;
        var referralErrors = this.state.errors || {};
        var guestErrors = referralErrors.guest || {};
        editGuest[field].push(value);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        referralErrors.guest = guestErrors
        editReferral.guest = editGuest
        this.setState({editReferral: editReferral, errors: referralErrors});
    }

    render() {
        const { classes } = this.props;
        const referralAgencyFilter = createFilterOptions();
        var originalReferral = this.props.referral
        var referral = this.state.editReferral || {}
        var guest = referral.guest || {}
        var errors = this.state.errors || {}
        var guestErrors = errors.guest || {}
        return (
          <Dialog fullWidth maxWidth="md" open={this.props.open}>
            <DialogTitle>
              <GridContainer alignItems="center">
                <GridItem md={9}>
                  <Typography variant="h5">
                    {originalReferral?.id}: {originalReferral?.guest?.fullName}
                  </Typography>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent>
              <GridContainer alignItems="flex-start" spacing={10}>
                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Referee details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={6}>
                            <TextField id="referralTimestamp"
                                       label="Timestamp"
                                       InputProps={{readOnly: true}}
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.timestamp || ''}/>
                            <FormControl fullWidth>
                                <Autocomplete id="referralAgency" name="referralAgency"
                                              value={referral.referralAgency || ''}
                                              onChange={(ev, newValue) => this.changeReferralValue("referralAgency", newValue.value)}
                                              filterOptions={(options, params) => referralAgencyFilter(options, params)}
                                              disableClearable
                                              freeSolo
                                              options={this.props.getOptions('REFERRAL_AGENCY')}
                                              getOptionLabel={option => (typeof option === 'string') && option || option.inputValue || option.value}
                                              renderOption={option => <li>{option.value}</li>}
                                              renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} label="Referral Agency" />}/>
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="nameOfAuthorisedSignatory"
                                       label="Referee name"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.nameOfAuthorisedSignatory || ''}
                                       error={errors['nameOfAuthorisedSignatory'] && errors['nameOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("nameOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['nameOfAuthorisedSignatory'] || ''}/>
                            <TextField id="phoneOfAuthorisedSignatory"
                                       label="Referee phone"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.phoneOfAuthorisedSignatory || ''}
                                       error={errors['phoneOfAuthorisedSignatory'] && errors['phoneOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("phoneOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['phoneOfAuthorisedSignatory'] || ''}/>
                            <TextField id="emailOfAuthorisedSignatory"
                                       label="Referee email"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.emailOfAuthorisedSignatory || ''}
                                       error={errors['emailOfAuthorisedSignatory'] && errors['emailOfAuthorisedSignatory'].length > 0}
                                       onChange={ev => this.changeReferralValue("emailOfAuthorisedSignatory", ev.target.value)}
                                       helperText={errors['emailOfAuthorisedSignatory'] || ''}/>
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </GridItem>

                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Guest details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={6}>
                              <TextField id="fullName"
                                         label="Full name"
                                         InputLabelProps={{shrink: true,}}
                                         fullWidth
                                         error={guestErrors['fullName'] && guestErrors['fullName'].length > 0}
                                         value={guest.fullName || ''}
                                         onChange={ev => this.changeGuestValue("fullName", ev.target.value)}
                                         helperText={guestErrors['fullName'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <InputLabel shrink={true}>Contact numbers</InputLabel>
                            {guest.contactNumbers.map((contactNumber, idx) => {
                                return (<React.Fragment>
                                          <div>
                                              <TextField id={"contactNumber" + idx}
                                                         value={contactNumber || ''}
                                                         InputLabelProps={{shrink: true,}}
                                                         error={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] && guestErrors['contactNumbers'][idx].length > 0}
                                                         onChange={ev => this.changeGuestValueByIdx('contactNumbers', idx, ev.target.value)}
                                                         helperText={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] || ''}/>
                                              <IconButton aria-label="remove"
                                                          size="small"
                                                          onClick={() => this.deleteGuestValueByIdx('contactNumbers', idx)}>
                                                  <DeleteIcon/>
                                              </IconButton></div>
                                        </React.Fragment>)})}
                            <IconButton aria-label="add"
                                        size="small"
                                        onClick={() => this.addGuestValueToList('contactNumbers', '')}>
                                <AddCircleOutlineOutlinedIcon/>
                            </IconButton>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="address"
                                       label="Address"
                                       InputLabelProps={{shrink: true}}
                                       margin="dense"
                                       fullWidth
                                       error={guestErrors['address'] && guestErrors['address'].length > 0}
                                       value={guest.address || ''}
                                       onChange={ev => this.changeGuestValue("address", ev.target.value)}
                                       helperText={guestErrors['address'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="postcode"
                                       label="Postcode"
                                       InputLabelProps={{shrink: true,}}
                                       fullWidth
                                       error={guestErrors['postcode'] && guestErrors['postcode'].length > 0}
                                       value={guest.postcode || ''}
                                       onChange={ev => this.changeGuestValue("postcode", ev.target.value)}
                                       helperText={guestErrors['postcode'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={guest?.disabled || false}
                                                 onChange={ev => this.changeGuestValue('disabled', ev.target.checked)}
                                                 name="disabled" />}
                              label="Disabled"/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="disabledInfo"
                                       label="Disabled information"
                                       multiline
                                       fullWidth
                                       InputLabelProps={{shrink: true,}}
                                       error={guestErrors['disabledInfo'] && guestErrors['disabledInfo'].length > 0}
                                       value={guest?.disabledInfo || ''}
                                       onChange={ev => this.changeGuestValue("disabledInfo", ev.target.value)}
                                       helperText={guestErrors['disabledInfo'] || ''}/>
                          </GridItem>
                          <GridItem md={4}>
                            <InputLabel htmlFor="gender" shrink={true}>Gender</InputLabel>
                            <Select id="gender"
                                    name="gender"
                                    fullWidth
                                    value={guest.gender || ''}
                                    onChange={ev => this.changeGuestValue("gender", ev.target.value)}>
                                <MenuItem value="FEMALE">Female</MenuItem>
                                <MenuItem value="MALE">Male</MenuItem>
                                <MenuItem value="NON_BINARY">Non-Binary</MenuItem>
                                <MenuItem value="">Prefer not to say</MenuItem>
                            </Select>
                          </GridItem>
                          <GridItem md={4}>
                            <OptionWithOther coreField="ethnicityCore"
                                             coreLabel="Ethnicity"
                                             fullWidth
                                             id={'guest'}
                                             disabled={false}
                                             object={guest || {}}
                                             changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                             options={this.props.getOptions('ETHNICITY').map(option => option.value)}
                                             otherField="ethnicityOther"
                                             helperText={''}/>
                          </GridItem>
                          <GridItem md={4}>
                            <OptionWithOther coreField="faithCore"
                                             coreLabel="Faith"
                                             fullWidth
                                             id={'guest'}
                                             disabled={false}
                                             object={guest || {}}
                                             changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                             options={this.props.getOptions('FAITH').map(option => option.value)}
                                             otherField="faithOther"
                                             helperText={''}/>
                          </GridItem>
                          <GridItem md={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                              <KeyboardDatePicker value={guest.dob && parseDate(guest.dob) || null}
                                                  autoOk
                                                  label="DOB"
                                                  InputLabelProps={{shrink: true,}}
                                                  variant="inline"
                                                  onChange={(date) => this.changeGuestValue("dob", format(date, 'dd-MM-yyyy'))}
                                                  format="dd-MM-yyyy"/>
                            </MuiPickersUtilsProvider>
                          </GridItem>
                          <GridItem md={4}>
                            <TextField id="numberOfPeople"
                                       label="Number of people"
                                       InputLabelProps={{shrink: true,}}
                                       fullWidth
                                       type="number"
                                       error={guestErrors['numberOfPeople'] && guestErrors['numberOfPeople'].length > 0}
                                       value={guest.numberOfPeople}
                                       onChange={ev => this.changeGuestValue("numberOfPeople", ev.target.value)}
                                       helperText={guestErrors['numberOfPeople'] || ''}/>
                          </GridItem>
                          <GridItem md={4}>
                            <ChipInput value={guest.ages || []}
                                       label="Ages"
                                       onBeforeAdd={(chip) => chip.match(/^\d+m?$/)}
                                       fullWidth
                                       onAdd={(age) => this.addGuestValueToList("ages", age)}
                                       onDelete={(age) => this.deleteGuestValueByValue("ages", age)}
                                       InputLabelProps={{shrink: true,}}
                                       allowDuplicates={true} />
                          </GridItem>
                          <GridItem md={6}>
                            <OptionWithOther coreField="dietaryRequirementsCore"
                                             coreLabel="Dietary requirements"
                                             fullWidth
                                             id={'guest'}
                                             disabled={false}
                                             multiple
                                             object={guest || {}}
                                             changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                             options={this.props.getOptions('DIETARY_REQUIREMENTS').map(option => option.value)}
                                             otherField="dietaryRequirementsOther"
                                             helperText={''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <OptionWithOther coreField="languagesSpokenCore"
                                             coreLabel="Languages spoken"
                                             fullWidth
                                             id={'guest'}
                                             disabled={false}
                                             multiple
                                             object={guest || {}}
                                             changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                             options={this.props.getOptions('LANGUAGES_SPOKEN').map(option => option.value)}
                                             otherField="languagesSpokenOther"
                                             helperText={''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="accessibilityIssues"
                                       label="Accessibility issues"
                                       multiline
                                       fullWidth
                                       InputLabelProps={{shrink: true,}}
                                       error={guestErrors['accessibilityIssues'] && guestErrors['accessibilityIssues'].length > 0}
                                       value={guest.accessibilityIssues || ''}
                                       onChange={ev => this.changeGuestValue("accessibilityIssues", ev.target.value)}
                                       helperText={guestErrors['accessibilityIssues'] || ''}/>
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                  </GridItem>
                  <GridItem md={12}>
                    <Card>
                      <CardHeader title="Referral details" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <GridContainer>
                          <GridItem md={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink={true} htmlFor="groupsMember">Groups</InputLabel>
                                <Select id="groupsMember" name="groupsMember" multiple value={referral?.groupsMember || []} onChange={ev => this.changeReferralValue("groupsMember", ev.target.value)}>
                                    {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value} disabled={!option.active}>{option.value}</MenuItem>))}
                                </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}/>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.homelessOrTemporaryAccommodation || false}
                                                 onChange={ev => this.changeReferralValue('homelessOrTemporaryAccommodation', ev.target.checked)}
                                                 name="homelessOrTemporaryAccommodation" />}
                              label="Homeless or temporary accommodation"/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.cannotAffordToPurchaseFood || false}
                                                 onChange={ev => this.changeReferralValue('cannotAffordToPurchaseFood', ev.target.checked)}
                                                 name="cannotAffordToPurchaseFood" />}
                              label="Cannot afford to purchase food"/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="gpPractice"
                                       label="GP practice"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.gpPractice || ''}
                                       error={errors['gpPractice'] && errors['gpPractice'].length > 0}
                                       onChange={ev => this.changeReferralValue("gpPractice", ev.target.value)}
                                       helperText={errors['gpPractice'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="nameOfGp"
                                       label="Name of GP"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.nameOfGp || ''}
                                       error={errors['nameOfGp'] && errors['nameOfGp'].length > 0}
                                       onChange={ev => this.changeReferralValue("nameOfGp", ev.target.value)}
                                       helperText={errors['nameOfGp'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.carer || false}
                                                 onChange={ev => this.changeReferralValue('carer', ev.target.checked)}
                                                 name="carer" />}
                              label="Carer"/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="carerResponsibilities"
                                       label="Carer responsibilities"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       multiline
                                       value={referral?.carerResponsibilities || ''}
                                       error={errors['carerResponsibilities'] && errors['carerResponsibilities'].length > 0}
                                       onChange={ev => this.changeReferralValue("carerResponsibilities", ev.target.value)}
                                       helperText={errors['carerResponsibilities'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControl fullWidth>
                              <InputLabel shrink={true}>Appliances</InputLabel>
                              <Select id="appliances"
                                      name="appliances"
                                      multiple
                                      value={APPLIANCES.filter(appliance => referral[appliance])}
                                      onChange={ev => APPLIANCES.forEach(appliance => this.changeReferralValue(appliance, ev.target.value.includes(appliance)))}>
                                <MenuItem value='fridge'>Fridge</MenuItem>
                                <MenuItem value='fridgeFreezer'>Fridge freezer</MenuItem>
                                <MenuItem value='microwave'>Microwave</MenuItem>
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              control={<Checkbox checked={referral?.cookingFacilities || false}
                                                 onChange={ev => this.changeReferralValue('cookingFacilities', ev.target.checked)}
                                                 name="cookingFacilities" />}
                              label="Cooking facilities"/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="numberOfDeliveries"
                                       label="Number of deliveries"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       value={referral?.numberOfDeliveries || ''}
                                       error={errors['numberOfDeliveries'] && errors['numberOfDeliveries'].length > 0}
                                       onChange={ev => this.changeReferralValue("numberOfDeliveries", ev.target.value)}
                                       helperText={errors['numberOfDeliveries'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <TextField id="furtherInfo"
                                       label="Further info"
                                       InputLabelProps={{shrink: true}}
                                       fullWidth
                                       multiline
                                       value={referral?.furtherInfo || ''}
                                       error={errors['furtherInfo'] && errors['furtherInfo'].length > 0}
                                       onChange={ev => this.changeReferralValue("furtherInfo", ev.target.value)}
                                       helperText={errors['furtherInfo'] || ''}/>
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                                control={<Checkbox id="permissionToContact"
                                                   name="permissionToContact"
                                                   checked={referral?.permissionToContact || false}
                                                   onChange={ (ev) => this.changeReferralValue("permissionToContact", ev.target.checked) } />}
                                label="Permission to contact guest"/>
                          </GridItem>
                        </GridContainer>
                      </CardContent>
                    </Card>
                    <Card>
                      <CardHeader title="Referral timeline" titleTypographyProps={{variant: 'overline'}}/>
                      <CardContent>
                        <ReferralTimelineTable tableHeaderColor="primary"
                                               tableHead={{"ID": timelineEvent => timelineEvent.id,
                                                           "Date": timelineEvent => timelineEvent.date}}
                                               timeline={originalReferral && originalReferral.timeline || []}
                                               performDeliveryAction={this.props.performDeliveryAction}
                                               viewFurtherAssessment={this.props.viewFurtherAssessment}
                                               id={referral.id}/>
                      </CardContent>
                    </Card>
                  </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.save()}>Save</Button>
              <Button onClick={this.props.handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        );

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(ReadyMealDialog))
