import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from "assets/img/sufra-logo-new.png";
import AuthService from "services/AuthService.js";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sufra-nwl.org.uk">
        sufra-nwl.org.uk
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignUp extends Component {

    constructor(props) {
      super(props);
      this.handleSignUp = this.handleSignUp.bind(this);
      this.onChangeName = this.onChangeName.bind(this);
      this.onChangeEmail = this.onChangeEmail.bind(this);
      this.onChangePassword = this.onChangePassword.bind(this);
      this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);

      this.state = {
        nameState: { value: '',
                     error: false,
                     message: '' },
        emailState: { value: '',
                      error: false,
                      message: '' },
        passwordState: { value: '',
                         error: false,
                         message: '' },
        confirmPasswordState: { value: '',
                                error: false,
                                message: '' },
        message: '',
        loading: false
      };
    }

    onChangeName(e) {
      const value = e.target.value || '';
      const message = (value.length === 0 && 'Name must be set') || ''
      this.setState({
        nameState: { value: value,
                     error: message.length > 0,
                     message: message },
        message: ''
      });
    }

    onChangeEmail(e) {
      const value = e.target.value || '';
      const message = (value.length === 0 && 'Email must be set') || (!value.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && 'Invalid email') || ''
      this.setState({
        emailState: { value: value,
                      error: message.length > 0,
                      message: message },
        message: ''
      });
    }

    onChangePassword(e) {
      const value = e.target.value || '';
      const message = (value.length === 0 && 'Password must be set')
                        || ((value.length < 6 || value.length > 20) && 'Password must be between 6 and 20 characters') || ''
                        || (!value.match(/^[a-zA-Z0-9!*&^,#_.+-]{6,20}$/) && 'Password can consist of letters, numbers and special characters !*&^,#_.+-') || ''

      const confirmPasswordState = this.state.confirmPasswordState;
      confirmPasswordState.message = (confirmPasswordState.value.length > 0 && confirmPasswordState.value !== value && 'Password must match') || ''
      confirmPasswordState.error = confirmPasswordState.message.length > 0
      this.setState({
        passwordState: { value: value,
                         error: message.length > 0,
                         message: message },
        confirmPasswordState: confirmPasswordState,
        message: ''
      });
    }

    onChangeConfirmPassword(e) {
      const value = e.target.value || '';
      const message = (value.length > 0 && value !== this.state.passwordState.value && 'Password must match') || ''
      this.setState({
        confirmPasswordState: { value: value,
                                error: message.length > 0,
                                message: message },
        message: ''
      });
    }


    handleSignUp(e) {
      e.preventDefault();

      this.setState({
        loading: true
      });

      if (this.state.nameState.error) {
        this.setState({loading: false, message: this.state.nameState.message})
      }
      else if (this.state.emailState.error) {
        this.setState({loading: false, message: this.state.emailState.message})
      }
      else if (this.state.passwordState.error) {
        this.setState({loading: false, message: this.state.passwordState.message})
      }
      else if (this.state.confirmPasswordState.error) {
        this.setState({loading: false, message: this.state.confirmPasswordState.message})
      }
      else if (this.state.nameState.value.length === 0) {
        this.setState({loading: false, message: 'Name must be set'})
      }
      else if (this.state.emailState.value.length === 0) {
        this.setState({loading: false, message: 'Email must be set'})
      }
      else if (this.state.passwordState.value.length === 0) {
        this.setState({loading: false, message: 'Password must be set'})
      }
      else if (this.state.confirmPasswordState.value.length === 0) {
        this.setState({loading: false, message: 'Confirm password must be set'})
      }
      else {
        AuthService.register(this.state.nameState.value, this.state.emailState.value, this.state.passwordState.value)
            .then(response => {
                this.props.history.push("/login");
            },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.setState({
              loading: false,
              message: resMessage
            });
          }
        );
      }
    }

    render() {
        const { classes } = this.props;

            return (
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                  <img src={logo} alt="logo" className={classes.img} />
                  <Typography component="h1" variant="h5">
                    Sign up
                  </Typography>
                  <form className={classes.form} onSubmit={this.handleSignUp}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="name"
                      label="Full Name"
                      name="name"
                      autoComplete="name"
                      autoFocus
                      value={this.state.nameState.value}
                      onChange={this.onChangeName}
                      error={this.state.nameState.error}
                      helperText={this.state.nameState.message}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={this.state.emailState.value}
                      onChange={this.onChangeEmail}
                      error={this.state.emailState.error}
                      helperText={this.state.emailState.message}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={this.state.passwordState.value}
                      onChange={this.onChangePassword}
                      error={this.state.passwordState.error}
                      helperText={this.state.passwordState.message}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="confirmPassword"
                      label="Password"
                      type="password"
                      id="confirmPassword"
                      autoComplete="current-password"
                      value={this.state.confirmPasswordState.value}
                      onChange={this.onChangeConfirmPassword}
                      error={this.state.confirmPasswordState.error}
                      helperText={this.state.confirmPasswordState.message}
                    />
                    {this.state.message && (<Typography variant="body2" color="error" align="center">{this.state.message}</Typography>)}
                    <Button
                      type="submit"
                      fullWidth
                      disabled={this.state.loading}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign Up
                    </Button>
                    <Grid container justify='center'>
                      <Grid item>
                        <Link href="/login" variant="body2">
                          {"Have an account? Log in"}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Container>
            );

    }
}

export default withStyles(styles)(SignUp);
