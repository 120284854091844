import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MergeTypeIcon from '@material-ui/icons/MergeType';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {parseDate} from "../../util/DateUtils.js"
import Button from "components/CustomButtons/Button.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import DateFnsUtils from '@date-io/date-fns';
import {enGB} from 'date-fns/locale'
import GuestReferralsTable from "components/Table/GuestReferralsTable.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChipInput from 'material-ui-chip-input'
import MenuItem from "@material-ui/core/MenuItem";

import {infoColor} from "assets/jss/material-dashboard-react.js";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import FurtherAssessment from "./FurtherAssessment";

const validation = {'fullName': value => (value.length === 0 && 'Name must be set') || '',
                    'contactNumber': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
                    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
                    'address':  value => (value.length === 0 && 'Address must be set') || ''}
const styles = (theme) => ({
  chipPaper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
})

class GuestsTable extends Component {
    constructor(props) {
        super(props);
        this.setOpen = this.setOpen.bind(this)
        this.setEdit = this.setEdit.bind(this)
        this.editGuest = this.editGuest.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.addValue = this.addValue.bind(this)
        this.deleteValue = this.deleteValue.bind(this)
        this.addValueToList = this.addValueToList.bind(this)
        this.deleteValueByIdx = this.deleteValueByIdx.bind(this)
        this.changeValueByIdx = this.changeValueByIdx.bind(this)
        this.mergeGuestInto = this.mergeGuestInto.bind(this)
        this.resetMergeGuest = this.resetMergeGuest.bind(this)
        this.viewFurtherAssessment = this.viewFurtherAssessment.bind(this)
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this)

        this.state = {
            open: {},
            edit: {},
            errors: {},
            mergeGuestId: null,
            mergeGuestIntoId: null,
            furtherAssessmentDialogFurtherAssessmentId: null,
            furtherAssessmentDialogReferralId: null,
            furtherAssessmentDialogGuestId: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.guestsOrder !== this.props.guestsOrder) {
            this.setState({open: {}, edit: {}, errors: {}})
        }
    }

    setOpen(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;
        if (!open[key]) {
            this.props.fetchGuest(key).then(() => {
                open[key] = true;
                this.setState({open: open})
            });
        }
        else {
            delete edit[key];
            delete errors[key];
            open[key] = false;
            this.setState({open: open, edit: edit, errors: errors});
        }
    }

    viewFurtherAssessment(guestId, referralId, furtherAssessmentId) {
        this.setState({
            furtherAssessmentDialogFurtherAssessmentId: furtherAssessmentId,
            furtherAssessmentDialogReferralId: referralId,
            furtherAssessmentDialogGuestId: guestId
        })
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        this.props.createFurtherAssessment(referralId, furtherAssessment)
            .then(id => {
                this.setState({
                    furtherAssessmentDialogFurtherAssessmentId: id,
                    furtherAssessmentDialogReferralId: referralId
                })
            })
    }

    changeValue(id, field, value) {
        var edit = this.state.edit;
        var errors = this.state.errors;
        edit[id][field] = value;
        errors[id][field] = (validation[field] && validation[field](value)) || ''
        this.setState({edit: edit, errors: errors});
    }

    deleteValue(id, field, value) {
        var edit = this.state.edit;
        var valueList = edit[id][field];
        var idxToRemove = valueList.indexOf(value);
        valueList.splice(idxToRemove, 1)
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    addValue(id, field, value) {
        var edit = this.state.edit;
        var valueList = edit[id][field].slice();
        valueList.push(value);
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    deleteValueByIdx(id, field, idx) {
        var edit = this.state.edit;
        var valueList = edit[id][field];
        valueList.splice(idx, 1)
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    changeValueByIdx(id, field, idx, value) {
        var edit = this.state.edit;
        var valueList = edit[id][field];
        valueList[idx] = value;
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    addValueToList(id, field) {
        var edit = this.state.edit;
        var valueList = edit[id][field];
        valueList.push('');
        edit[id][field] = valueList;
        this.setState({edit: edit});
    }

    editGuest(key) {
        this.props.editGuest(this.state.edit[key]);
        delete this.state.edit[key];
        delete this.state.errors[key];
    }

    setEdit(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;

        if (!open[key]) {
            this.props.fetchGuest(key).then(() => {
                open[key] = true;
                edit[key] = Object.assign({}, this.props.guests[key]);
                errors[key] = {};
                this.setState({edit: edit, open: open, errors: errors})
            });
        }
        else {
            if (!edit[key]) {
                edit[key] = Object.assign({}, this.props.guests[key]);
                errors[key] = {};
            }
            else {
                delete edit[key];
                delete errors[key]
            }
            this.setState({edit: edit, errors: errors});
        }
    }

    mergeGuestInto() {
      this.props.mergeGuestInto(this.state.mergeGuestId, this.state.mergeGuestIntoId);
      this.setState({mergeGuestId: null, mergeGuestIntoId: null})
    }

    resetMergeGuest() {
      this.setState({mergeGuestId: null, mergeGuestIntoId: null})
    }

    render() {
        const { classes } = this.props;
        const filterOptions = createFilterOptions({
          stringify: guestId => this.props.allGuests[guestId].fullName + ((", " + this.props.allGuests[guestId].address) || "") + ((", " + this.props.allGuests[guestId].postcode) || "") + ((", " + this.props.allGuests[guestId].contactNumbers.toString()) || ""),
        });
        return (
          <div className={classes.tableResponsive}>
              <FurtherAssessment open={this.state.furtherAssessmentDialogReferralId}
                                 readOnly={!(this.props.user && this.props.user.roles.includes('ROLE_ADMIN') || !this.state.furtherAssessmentDialogFurtherAssessmentId
                                     || this.props.guests[this.state.furtherAssessmentDialogGuestId].referrals.find((referral) => referral.id === this.state.furtherAssessmentDialogReferralId).furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId).parcelsLeft > 0)}
                                 canEditFrequency={this.props.user?.roles?.includes('ROLE_ADVISOR')}
                                 onClose={() =>this.setState({
                                     furtherAssessmentDialogFurtherAssessmentId: null,
                                     furtherAssessmentDialogReferralId: null,
                                     furtherAssessmentDialogGuestId: null
                                 })}
                                 getOptions={this.props.getOptions}
                                 id={this.state.furtherAssessmentDialogFurtherAssessmentId}
                                 referral={this.props.guests[this.state.furtherAssessmentDialogGuestId]?.referrals.find((referral) => referral.id === this.state.furtherAssessmentDialogReferralId)}
                                 assessment={this.state.furtherAssessmentDialogFurtherAssessmentId
                                            && this.props.guests[this.state.furtherAssessmentDialogGuestId].referrals.find((referral) => referral.id === this.state.furtherAssessmentDialogReferralId).furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId)}
                                 editFurtherAssessment={this.props.editFurtherAssessment}
                                 createFurtherAssessment={this.createFurtherAssessment}/>
            <Dialog open={this.state.mergeGuestId} onClose={this.resetMergeGuest}>
              <DialogTitle id="form-dialog-title">Merge guest into</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Select the guest to merge this guest into
                </DialogContentText>
                <Autocomplete id={"select-merge-guest"}
                              options={this.props.allGuestsOrder}
                              getOptionLabel={(guestId) => this.props.allGuests[guestId] && this.props.allGuests[guestId].fullName || 'LOADING'}
                              style={{ width: 300 }}
                              renderOption={(guestId) => <Typography>{this.props.allGuests[guestId].fullName + ((", " + this.props.allGuests[guestId].address) || "") + ((", " + this.props.allGuests[guestId].postcode) || "") + ((", " + this.props.allGuests[guestId].contactNumbers.toString()) || "")}</Typography>}
                              onChange={(event, newValue) => this.setState({mergeGuestIntoId: newValue})}
                              getOptionSelected={(guestId, value) => value === guestId}
                              autoComplete
                              renderInput={(params) => <TextField {...params} label="Select guest" variant="outlined" />}
                              filterOptions={filterOptions} />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.resetMergeGuest} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => this.mergeGuestInto()} color="primary">
                  Merge
                </Button>
              </DialogActions>
            </Dialog>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          <TableSortLabel active={this.props.orderBy === prop}
                                          direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                          onClick={ev => {this.props.setOrderBy(prop)}}>
                            {this.props.tableHead[prop]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.guestsOrder.map((guestId, idx) => {
                  const guest = this.props.guests[guestId];
                  const editing = this.state.edit[guestId]
                  const editedGuest = this.state.edit[guestId] || guest
                  const errors = (editing && this.state.errors[guest.id]) || {}
                  return guest && (
                    <React.Fragment>
                      <TableRow key={guest.id} className={classes.tableBodyRowSummary}>
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="View guest">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(guest.id)}>
                              {this.state.open[guest.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        { Object.keys(this.props.tableHead).map((prop, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={key}>
                              {this.props.mappingFunctions[prop](guest)}
                              {prop === "contactNumber" && guest.permissionToContact
                                && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                        <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                    </Tooltip>)}
                            </TableCell>
                          );
                        })}
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="Edit guest">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setEdit(guest.id)}>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow key={guest.id + "Details"} className={classes.tableBodyRowDetail}>
                        <TableCell className={classes.tableDetailsCell}/>
                        <TableCell className={classes.tableDetailsCell} colSpan={Object.keys(this.props.tableHead).length + 2} key={"details"}>
                          <Collapse in={this.state.open[guest.id]} timeout="auto" unmountOnExit>
                            <form>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={11}/>
                                <GridItem xs={12} sm={12} md={1}>
                                  <Tooltip title="Merge guest into">
                                    <IconButton onClick={e => this.setState({mergeGuestId: guestId})}>
                                      <MergeTypeIcon/>
                                    </IconButton>
                                  </Tooltip>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="fullName"
                                             label="Full Name"
                                             disabled={!editing}
                                             error={errors['fullName'] && errors['fullName'].length > 0}
                                             value={editedGuest.fullName || ''}
                                             onChange={ev => this.changeValue(guest.id, "fullName", ev.target.value)}
                                             helperText={errors['fullName'] || ''}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <InputLabel>Contact Numbers</InputLabel>
                                    {editedGuest.contactNumbers.map((contactNumber, idx) => {
                                        return (<React.Fragment>
                                            <div>
                                                <TextField id={"contactNumber" + guest.id + idx}
                                                           disabled={!editing}
                                                           value={contactNumber || ''}
                                                           onChange={ev => this.changeValueByIdx(guest.id, 'contactNumbers', idx, ev.target.value)}/>
                                                <IconButton aria-label="remove"
                                                            disabled={!editing}
                                                            size="small"
                                                            onClick={() => this.deleteValueByIdx(guest.id, 'contactNumbers', idx)}>
                                                    <DeleteIcon/>
                                                </IconButton></div>
                                        </React.Fragment>)
                                    })}
                                    <IconButton aria-label="add"
                                                size="small"
                                                disabled={!editing}
                                                onClick={() => this.addValueToList(guest.id, 'contactNumbers')}>
                                        <AddCircleOutlineOutlinedIcon/>
                                    </IconButton>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="address"
                                             label="Address"
                                             disabled={!editing}
                                             error={errors['address'] && errors['address'].length > 0}
                                             value={editedGuest.address || ''}
                                             onChange={ev => this.changeValue(guest.id, "address", ev.target.value)}
                                             helperText={errors['address'] || ''}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="postcode"
                                             label="Postcode"
                                             disabled={!editing}
                                             error={errors['postcode'] && errors['postcode'].length > 0}
                                             value={editedGuest.postcode || ''}
                                             onChange={ev => this.changeValue(guest.id, "postcode", ev.target.value)}
                                             helperText={errors['postcode'] || ''}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControlLabel
                                    control={<Checkbox id="disabled" name="disabled" disabled={!editing} checked={editedGuest.disabled} onChange={ (ev) => this.changeValue(guest.id, "disabled", ev.target.checked) } />}
                                    label="Disabled"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="disabledInfo"
                                             label="Disabled information"
                                             disabled={!editing}
                                             multiline
                                             error={errors['disabledInfo'] && errors['disabledInfo'].length > 0}
                                             value={editedGuest.disabledInfo || ''}
                                             onChange={ev => this.changeValue(guest.id, "disabledInfo", ev.target.value)}
                                             helperText={errors['disabledInfo'] || ''}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="accessibilityIssues"
                                             label="Accessibility issues"
                                             disabled={!editing}
                                             multiline
                                             error={errors['accessibilityIssues'] && errors['accessibilityIssues'].length > 0}
                                             value={editedGuest.accessibilityIssues || ''}
                                             onChange={ev => this.changeValue(guest.id, "accessibilityIssues", ev.target.value)}
                                             helperText={errors['accessibilityIssues'] || ''}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControl>
                                    <InputLabel htmlFor="gender">Gender</InputLabel>
                                    <Select id="gender" name="gender" disabled={!editing} value={editedGuest.gender || ''} onChange={ev => this.changeValue(guest.id, "gender", ev.target.value)}>
                                      <MenuItem value="FEMALE">Female</MenuItem>
                                      <MenuItem value="MALE">Male</MenuItem>
                                      <MenuItem value="NON_BINARY">Non-Binary</MenuItem>
                                      <MenuItem value="">Prefer not to say</MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="numberOfPeople"
                                             label="Number people"
                                             disabled={!editing}
                                             value={editedGuest.numberOfPeople}
                                             onChange={ev => this.changeValue(guest.id, "numberOfPeople", ev.target.value)}
                                             type="number"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <ChipInput value={editedGuest.ages || []}
                                             label="Ages"
                                             disabled={!editing}
                                             onBeforeAdd={(chip) => chip.match(/^\d+m?$/)}
                                             onAdd={(age) => this.addValue(guestId, "ages", age)}
                                             onDelete={(age) => this.deleteValue(guestId, "ages", age)}
                                             allowDuplicates={true} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="ethnicityCore"
                                                   coreLabel="Ethnicity"
                                                   id={guest.id}
                                                   disabled={!editing}
                                                   object={editedGuest || {}}
                                                   changeValue={this.changeValue}
                                                   fullWidth
                                                   options={this.props.getOptions('ETHNICITY').map(option => option.value)}
                                                   otherField="ethnicityOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                    <KeyboardDatePicker value={editedGuest.dob && parseDate(editedGuest.dob) || null}
                                                autoOk
                                                label="DOB"
                                                variant="inline"
                                                onChange={(date) => this.changeValue(guest.id, "dob", date)}
                                                disabled={!editing}
                                                format="dd-MM-yyyy"/>
                                  </MuiPickersUtilsProvider >
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="faithCore"
                                                   coreLabel="Faith"
                                                   id={guest.id}
                                                   disabled={!editing}
                                                   object={editedGuest || {}}
                                                   changeValue={this.changeValue}
                                                   fullWidth
                                                   options={this.props.getOptions('FAITH').map(option => option.value)}
                                                   otherField="faithOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="dietaryRequirementsCore"
                                                   coreLabel="Dietary requirements"
                                                   id={guest.id}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedGuest || {}}
                                                   fullWidth
                                                   changeValue={this.changeValue}
                                                   options={this.props.getOptions('DIETARY_REQUIREMENTS').map(option => option.value)}
                                                   otherField="dietaryRequirementsOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="languagesSpokenCore"
                                                   coreLabel="Languages spoken"
                                                   id={guest.id}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedGuest || {}}
                                                   fullWidth
                                                   changeValue={this.changeValue}
                                                   options={this.props.getOptions('LANGUAGES_SPOKEN').map(option => option.value)}
                                                   otherField="languagesSpokenOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Button color="primary" disabled={!editing || Object.values(errors).some(err => err.length > 0)} onClick={e => this.editGuest(guest.id)}>Update guest</Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GuestReferralsTable
                                    tableHeaderColor="primary"
                                    tableHead={{"ID": referral => referral.id,
                                                "Timestamp": referral => referral.timestamp,
                                                "Referral Agency": referral => referral.referralAgency,
                                                "Referral Type": referral => referral.referralType === 'FOOD_BANK' ? "Food Bank" : "Ready Meal",
                                                "Status": referral => referral.status}}
                                    referrals={guest.referrals}
                                    guest={guest}
                                    switchReferralType={this.props.switchReferralType}
                                    archiveReferral={this.props.archiveReferral}
                                    unarchiveReferral={this.props.unarchiveReferral}
                                    performDeliveryAction={this.props.performDeliveryAction}
                                    editReferral={this.props.editReferral}
                                    getOptions={this.props.getOptions}
                                    viewFurtherAssessment={this.viewFurtherAssessment}
                                  />
                                </GridItem>
                              </GridContainer>
                            </form>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(tableStyles, styles)(GuestsTable);

GuestsTable.defaultProps = {
  tableHeaderColor: "gray"
};

GuestsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
