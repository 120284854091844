import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import {Visibility} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import GridItem from "components/Grid/GridItem.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import {dangerColor, infoColor, successColor} from "assets/jss/material-dashboard-react.js";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DateFnsUtils from "@date-io/date-fns";
import {parseDate} from "../../util/DateUtils";
import ChipInput from "material-ui-chip-input";
import FormHelperText from "@material-ui/core/FormHelperText";
import endOfWeek from "date-fns/endOfWeek";
import {DatePicker, MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker} from "@material-ui/pickers";
import {format, isFuture, isToday, parse} from 'date-fns'
import {enGB} from 'date-fns/locale'
import setDay from "date-fns/setDay";
import ScheduleIcon from '@material-ui/icons/Schedule';

const validation = {}

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

const GUEST_REFERRAL_DIFFERENCE_WARNING = "Value on guest differs to value on referral"
const styles = (theme) => ({
})
class ReadyMealsTable extends Component {
    constructor(props) {
        super(props);
        this.getReferralActions = this.getReferralActions.bind(this);
        this.confirmArchive = this.confirmArchive.bind(this);
        this.confirmSwitchToFoodAidReferral = this.confirmSwitchToFoodAidReferral.bind(this);
        this.endOfNextWeek = this.endOfNextWeek.bind(this);

        this.state = {
            confirmArchiveReferral: null,
        };
    }

    endOfNextWeek() {
        var date = new Date();
        date.setDate(date.getDate() + 7);
        return endOfWeek(date, {weekStartsOn: 1});
    }

    getReferralActions(key) {
        const referral = this.props.referrals[key];
        var scheduled = referral.scheduled
        return (<GridContainer>
            <GridItem md={1}>
                <Tooltip title="Archive referral">
                    <IconButton aria-label="archive referral" size="small"
                                onClick={() => this.setState({confirmArchiveReferral: { id: key, archiveReason: 'GUEST_REQUESTED', archiveComment: '' }})}>
                        <ArchiveRoundedIcon/>
                    </IconButton>
                </Tooltip>
            </GridItem>
            <GridItem md={1}>
                <Tooltip title="Change referral to Food Bank referral">
                    <IconButton aria-label="switch referral" size="small"
                                onClick={() => this.setState({confirmSwitchToFoodAidReferralId: key })}>
                        <SyncAltIcon/>
                    </IconButton>
                </Tooltip>
            </GridItem>
            <GridItem md={1}>
                <Tooltip title="View referral">
                    <IconButton aria-label="expand row" size="small" onClick={() => this.props.setViewReferralDialogId(key)}>
                        <Visibility/>
                    </IconButton>
                </Tooltip>
            </GridItem>
            {scheduled &&
              (<GridItem md={1}>
                   <Tooltip title="Ready meals delivered">
                       <IconButton aria-label="expand row" size="small" onClick={() => this.props.delivered(key)}>
                           <CheckCircleOutlineRoundedIcon style={{ color: successColor[2]}}/>
                       </IconButton>
                   </Tooltip>
               </GridItem>)}
            {scheduled &&
              (<GridItem md={1}>
                   <Tooltip title="Ready meal delivery failed">
                       <IconButton aria-label="expand row" size="small" onClick={() => this.props.failed(key)}>
                           <ErrorOutlineRoundedIcon style={{ color: dangerColor[2]}}/>
                       </IconButton>
                   </Tooltip>
               </GridItem>)}
            {!scheduled &&
              (<GridItem md={1}>
                 <React.Fragment>
                   <Tooltip title="Schedule ready meal delivery">
                       <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ scheduleDialogOpen: key })}>
                           <ScheduleIcon/>
                       </IconButton>
                   </Tooltip>
                 </React.Fragment>
               </GridItem>)}
            {!scheduled &&
              (<GridItem md={1}>
                   <Tooltip title="Ready meal collected">
                       <IconButton aria-label="expand row" size="small" onClick={() => this.props.collected(key)}>
                           <CheckCircleOutlineRoundedIcon style={{ color: successColor[2]}}/>
                       </IconButton>
                   </Tooltip>
               </GridItem>)}
        </GridContainer>)
    }

    confirmArchive() {
        this.props.archive(this.state.confirmArchiveReferral.id, this.state.confirmArchiveReferral.archiveReason, this.state.confirmArchiveReferral.archiveComment, new Date())
            .then(() => this.setState({ confirmArchiveReferral : null }));
    }

    confirmSwitchToFoodAidReferral() {
        this.props.switchReferralType(this.state.confirmSwitchToFoodAidReferralId)
            .then(() => this.setState({ confirmSwitchToFoodAidReferralId : null }));
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
            <Dialog open={this.state.confirmArchiveReferral}
                    onClose={() => this.setState({confirmArchiveReferral: null})}>
              <DialogTitle id="confirm-archive-title">{"Archive referral?"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you wish to archive referral {this.state.confirmArchiveReferral?.id} for {this.props.referrals[this.state.confirmArchiveReferral?.id]?.guest?.fullName}?
                  </DialogContentText>
                  <FormControl>
                      <InputLabel htmlFor="archiveReason">Archive reason</InputLabel>
                      <Select id="archiveReason"
                              name="archiveReason"
                              value={this.state.confirmArchiveReferral?.archiveReason || 'GUEST_REQUESTED'}
                              onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveReason: ev.target.value} })}>
                          <MenuItem value="DUPLICATE_RECEIVING_FOOD_PARCELS">Duplicate - receiving food parcels</MenuItem>
                          <MenuItem value="DUPLICATE_RECEIVING_READY_MEALS">Duplicate - receiving ready meals</MenuItem>
                          <MenuItem value="GUEST_REQUESTED">Guest has requested for parcels to stop</MenuItem>
                          <MenuItem value="MOVED_AWAY">Guest has moved away</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                      </Select>
                  </FormControl><br/>
                  <TextField id="archiveComment"
                             label="Archive Comment"
                             multiline
                             InputLabelProps={{shrink: true}}
                             value={this.state.confirmArchiveReferral?.archiveComment || ''}
                              onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveComment: ev.target.value} })}/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => this.setState({confirmArchiveReferral: null})} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={this.confirmArchive} color="primary" autoFocus>
                      Archive
                  </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.confirmSwitchToFoodAidReferralId} onClose={() => this.setState({confirmSwitchToFoodAidReferralId: null})}>
                <DialogTitle id="confirm-switch-referral-type-title">{"Switch to Food Bank referral?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to change referral {this.state.confirmSwitchToFoodAidReferralId} for {this.props.referrals[this.state.confirmSwitchToFoodAidReferralId]?.guest?.fullName} to a Food Bank referral?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({confirmSwitchToFoodAidReferralId: null})} color="primary">
                        No
                    </Button>
                    <Button onClick={this.confirmSwitchToFoodAidReferral} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                <DatePicker open={this.state.scheduleDialogOpen || false}
                            onClose={() => this.setState({scheduleDialogOpen: false})}
                            value={setDay(new Date(), 5, {weekStartsOn: 1})}
                            disablePast
                            autoOk
                            maxDate={this.endOfNextWeek()}
                            onChange={date => this.props.schedule(this.state.scheduleDialogOpen, date)}
                            TextFieldComponent={() => null}/>
            </MuiPickersUtilsProvider>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          <TableSortLabel active={this.props.orderBy === prop}
                                          direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                          onClick={ev => {this.props.setOrderBy(prop)}}>
                            {this.props.tableHead[prop]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.referralsOrder.map((id, idx) => {
                    return (<TableRow key={"Referral" + id} className={classes.tableBodyRowSummary}>
                              <TableCell className={classes.tableCellSummary} />
                              { Object.keys(this.props.tableHead).map((prop, key) => {
                                return (
                                  <TableCell className={classes.tableCellSummary} key={key}>
                                    {this.props.mappingFunctions[prop](this.props.referrals[id])}
                                  </TableCell>
                                );
                              })}
                              <TableCell className={classes.tableCellSummary} key={"actions"}>
                                {this.getReferralActions(id)}
                              </TableCell>
                            </TableRow>)
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(tableStyles, styles)(ReadyMealsTable);

ReadyMealsTable.defaultProps = {
  tableHeaderColor: "gray"
};

ReadyMealsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
