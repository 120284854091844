import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from "@material-ui/core/MenuItem";
import {parseDate} from "../../util/DateUtils.js"
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from '@date-io/date-fns';
import {enGB} from 'date-fns/locale'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {format, isValid} from "date-fns";
import {ArrowDropDown} from "@material-ui/icons";

const ReadOnlyTextFieldComponent = (props) => (
    <TextField {...props}
               InputLabelProps={{shrink: true}}
               InputProps={{readOnly: true}}/>
);

const TextFieldComponent = (props) => (
    <TextField {...props}
               InputLabelProps={{shrink: true}}/>
);

const VALIDATION = {
    "status": (value, assessment) => ((!value || value.length === 0) && 'Status must be set'),
    "nameOfAdvisor": (value, assessment) => ((!value || value.length === 0) && 'Name of advisor must be set'),
    "dateOfAssessment": (value, assessment) => {
        if (!value || typeof value === 'string' && value.length === 0)
            return "Date of assessment must be set"
        else if (value instanceof Date && !isValid(value))
            return "Invalid date"
        else
            return null
    },
    "currentSituation": (value, assessment) => ((!value || value.length === 0) && 'Current situation must be set'),
    "actionPlan": (value, assessment) => ((!value || value.length === 0) && 'Action plan must be set'),
    "income": (value, assessment) => {
        if (assessment.incomeExpenditureReviewConducted) {
            if (!value || value.length === 0)
                return [{'label': 'Income must be set'}]
            else
                return value.map(inc => {
                    return {
                        'label': (!inc['label'] || inc['label'].length === 0) && 'Income must be set',
                        'amount': (!inc['amount'] || inc['amount'].length === 0) && 'Amount must be set' || isNaN(inc['amount']) && 'Amount must be a valid number'
                    }
                })
        }
    },
    "expenditure": (value, assessment) => {
        if (assessment.incomeExpenditureReviewConducted) {
            if (!value || value.length === 0)
                return [{'label': 'Expenditure must be set'}]
            else
                return value.map(inc => {
                    return {
                        'label': (!inc['label'] || inc['label'].length === 0) && 'Expenditure must be set',
                        'amount': (!inc['amount'] || inc['amount'].length === 0) && 'Amount must be set' || isNaN(inc['amount']) && 'Amount must be a valid number'
                    }
                })

        }
    },
    "incomeExpenditureComments": (value, assessment) => null,
    "furtherComments": (value, assessment) => null,
    "numberOfParcels": (value, assessment) => {
        if (assessment.status && assessment.status === "REJECTED")
            return value && value.length > 0 && "No parcels are permitted if further food aid is rejected"
        else if (!value || value.length === 0)
            return "Number of parcels must be set"
        else if (typeof value === 'string' && !value.match(/^\d+$/))
            return "Value must be a number"
        else if (parseInt(value) < 0)
            return "Value must be positive"
        else if (parseInt(value) > 6)
            return "A maximum of 6 parcels is permitted"
        else
            return null
    },
    "groupsMember": (value, assessment) => null,
    "organisationsSupporting": (value, assessment) => null
}

class FurtherAssessmentWeekly extends Component {
    constructor(props) {
        super(props);
        this.editFurtherAssessment = this.editFurtherAssessment.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.changeIncomeExpenditureAmount = this.changeIncomeExpenditureAmount.bind(this)
        this.formatIncomeExpenditureAmount = this.formatIncomeExpenditureAmount.bind(this)
        this.changeIncomeExpenditureLabel = this.changeIncomeExpenditureLabel.bind(this)
        this.renderIncomeExpenditure = this.renderIncomeExpenditure.bind(this)
        this.addIncomeExpenditure = this.addIncomeExpenditure.bind(this)
        this.removeIncomeExpenditure = this.removeIncomeExpenditure.bind(this)
        this.addIncomeExpenditure = this.addIncomeExpenditure.bind(this)
        this.resetEditedFurtherAssessment = this.resetEditedFurtherAssessment.bind(this)

        this.state = {
            editedAssessment: {},
            error: {},
            displayMessage: false,
            edit: false,
        };
    }

    componentDidMount() {
        this.resetEditedFurtherAssessment();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.readOnly !== this.props.readOnly || this.state.edit !== prevState.edit || this.props.id !== prevProps.id || this.props.referral?.id !== prevProps.referral?.id) {
            this.setState({editedAssessment: this.resetEditedFurtherAssessment()});
        }
    }

    resetEditedFurtherAssessment() {
        console.log(`reset: readonly: ${this.props.readOnly}, edit: ${this.state.edit}, id: ${this.props.id}`)
        if (!this.props.readOnly && this.state.edit) {
            return Object.assign({}, this.props.assessment);
        }
        else if (this.props.id === null && this.props.referral) {
            var latest = this.props.referral.furtherAssessments && this.props.referral.furtherAssessments[0] || this.props.referral;
            console.log(latest)
            console.log({}, this.props.assessment, {organisationsSupporting: latest.organisationsSupporting || '', groupsMember: latest.groupsMember || []})
            return {organisationsSupporting: latest.organisationsSupporting || '', groupsMember: latest.groupsMember || [], dateOfAssessment: format(new Date(), 'dd-MM-yyyy')};
        }
        return {};
    }

    changeValue(field, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;
        editedAssessment[field] = value;
        error[field] = VALIDATION[field] && VALIDATION[field](value, editedAssessment);
        if (field === 'status')
            error['numberOfParcels'] = VALIDATION['numberOfParcels'](editedAssessment.numberOfParcels, editedAssessment)
        this.setState({editedAssessment: editedAssessment, error: error});
    }

    changeIncomeExpenditureLabel(field, index, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;

        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[index] || {};
        editedFieldElement['label'] = value;
        editedField[index] = editedFieldElement;
        editedAssessment[field] = editedField;

        error[field] = VALIDATION[field] && VALIDATION[field](editedField, editedAssessment)

        this.setState({editedAssessment: editedAssessment, error: error});
    }

    changeIncomeExpenditureAmount(field, index, value) {
        var editedAssessment = this.state.editedAssessment;
        var error = this.state.error;

        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[index] || {};
        editedFieldElement['amount'] = value;
        editedField[index] = editedFieldElement;
        editedAssessment[field] = editedField;

        error[field] = VALIDATION[field] && VALIDATION[field](editedField, editedAssessment)

        this.setState({editedAssessment: editedAssessment, error: error});
    }

    formatIncomeExpenditureAmount(field, index, value) {
        var editedAssessment = this.state.editedAssessment;
        var editedField = editedAssessment[field] || [];
        var editedFieldElement = editedField[index] || {};
        editedFieldElement['amount'] = value && value.length > 0 && !isNaN(value) && parseFloat(value).toFixed(2) || value;
        editedField[index] = editedFieldElement;
        editedAssessment[field] = editedField;
        this.setState({editedAssessment: editedAssessment});
    }

    editFurtherAssessment() {
        var error = this.state.error;
        let editedAssessment = this.state.editedAssessment;
        Object.keys(VALIDATION).forEach(field => error[field] = VALIDATION[field] && VALIDATION[field](editedAssessment[field], editedAssessment))
        if (Object.values(error).some(err => typeof err === 'string' && err || Array.isArray(err) && err.some(incExpErr => Object.values(incExpErr).some(e => e)))) {
            this.setState({displayMessage: true, error: error})
        }
        else if (this.props.id) {
            this.props.editFurtherAssessment(this.props.id, editedAssessment)
                .then(() => {
                    this.setState({
                        editedAssessment: {},
                        error: {},
                        displayMessage: false,
                        edit: false
                    })
                });
        }
        else {
            this.props.createFurtherAssessment(this.props.referral.id, editedAssessment)
                .then(() => {
                    this.setState({
                        editedAssessment: {},
                        error: {},
                        displayMessage: false,
                        edit: false
                    })
                });
        }
    }

    addIncomeExpenditure(field) {
        var assessment = this.state.editedAssessment;
        var fieldValue = assessment[field] || [];
        fieldValue[fieldValue.length] = {};
        assessment[field] = fieldValue;
        this.setState({editedAssessment: assessment})
    }

    removeIncomeExpenditure(field, idx) {
        var assessment = this.state.editedAssessment;
        var fieldValue = assessment[field] || [];
        fieldValue.splice(idx, 1);
        assessment[field] = fieldValue;
        this.setState({editedAssessment: assessment})
    }

    renderIncomeExpenditure(assessment, field, editing) {
        var fieldValue = assessment[field] || [];
        if (fieldValue.length === 0) {
            fieldValue[0] = {};
        }

        return fieldValue.map((fieldElement, idx) => {
            var fieldError = this.state.error[field] && this.state.error[field][idx] || {}
            return (<React.Fragment>
                      <GridItem xs={10} sm={12} md={6}>
                        <TextField id={field + "Label" + idx}
                                   InputProps={{readOnly: !editing}}
                                   InputLabelProps={{shrink: true}}
                                   fullWidth
                                   value={fieldElement.label || ''}
                                   error={fieldError['label']}
                                   helperText={fieldError['label']}
                                   onChange={ev => this.changeIncomeExpenditureLabel(field, idx, ev.target.value)}/>
                      </GridItem>
                      <GridItem xs={10} sm={12} md={5}>
                        <TextField id={field + "Amount" + idx}
                                   InputLabelProps={{shrink: true}}
                                   fullWidth
                                   value={fieldElement.amount || ''}
                                   InputProps={{
                                       readOnly: !editing,
                                       startAdornment: (<InputAdornment position="start">£</InputAdornment>)
                                   }}
                                   error={fieldError['amount']}
                                   helperText={fieldError['amount']}
                                   onChange={ev => this.changeIncomeExpenditureAmount(field, idx, ev.target.value)}
                                   onBlur={ev => this.formatIncomeExpenditureAmount(field, idx, ev.target.value)}/>
                      </GridItem>
                      <GridItem xs={10} sm={12} md={1}>
                        <IconButton aria-label="remove"
                                    size="small"
                                    disabled={!editing}
                                    onClick={() => this.removeIncomeExpenditure(field, idx)}>
                          <DeleteIcon/>
                        </IconButton>
                      </GridItem>
                    </React.Fragment>)
        })
    }

    render() {
        const { classes } = this.props;
        const editing = this.props.id === null && this.props.referral || !this.props.readOnly && this.state.edit;
        const editedAssessment = (editing && this.state.editedAssessment) || this.props.assessment || {}
        return editedAssessment && (
            <Dialog open={this.props.referral}
                    onClose={() => {
                        this.setState({
                            editedAssessment: {},
                            errors: {},
                            displayMessage: null,
                            edit: false
                        })
                        this.props.onClose();
                    }}
                    fullWidth
                    maxWidth="md">
                <DialogTitle id="edit-further-assessment-title">
                    {`${editing && (this.props.id && "Edit" || "Create") || "View"} Further Assessment${this.props.id && (": " + this.props.id) || ""}`}
                </DialogTitle>
                <DialogContent>
                    <form>
                        <GridContainer>
                            <GridItem md={11}/>
                            {!this.props.readOnly && this.props.id && (<React.Fragment>
                                <GridItem md={1}>
                                    <Tooltip title="Edit further assessment">
                                        <IconButton aria-label="expand row"
                                                    size="large"
                                                    disabled={this.state.edit}
                                                    onClick={() => this.setState({edit: !this.state.edit})}>
                                            <EditOutlinedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </GridItem>
                            </React.Fragment>)}
                            <GridItem xs={10} sm={12} md={6}>
                                <TextField id="nameOfAdvisor"
                                           label="Name of advisor"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth
                                           value={editedAssessment.nameOfAdvisor || ''}
                                           error={this.state.error.nameOfAdvisor}
                                           helperText={this.state.error.nameOfAdvisor}
                                           onChange={ev => this.changeValue("nameOfAdvisor", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                    <KeyboardDatePicker
                                        key={this.props.id + editing && 'edit' || ''}
                                        value={editedAssessment.dateOfAssessment && parseDate(editedAssessment.dateOfAssessment) || null}
                                        autoOk
                                        clearable
                                        label="Date of assessment"
                                        variant="inline"
                                        onChange={(date) => this.changeValue("dateOfAssessment", isValid(date) && format(date, 'dd-MM-yyyy') || date)}
                                        disableFuture
                                        error={this.state.error.dateOfAssessment}
                                        helperText={this.state.error.dateOfAssessment}
                                        TextFieldComponent={!editing && ReadOnlyTextFieldComponent || TextFieldComponent}
                                        format="dd-MM-yyyy"/>
                                </MuiPickersUtilsProvider>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={12}>
                                <TextField id="currentSituation"
                                           label="Description of guests' current situation"
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.currentSituation || ''}
                                           error={this.state.error.currentSituation}
                                           helperText={this.state.error.currentSituation}
                                           InputProps={{
                                               readOnly: !editing,
                                               endAdornment: (<InputAdornment position="end">
                                                   <Tooltip classes={{tooltip: classes.tooltip}}
                                                            title="To access food aid guests should be in a financial crisis. For guests who are shielding but financially stable, please refer to a Hubs Coordinator for guidance.">
                                                       <HelpOutlineIcon/>
                                                   </Tooltip>
                                               </InputAdornment>)
                                           }}
                                           onChange={ev => this.changeValue("currentSituation", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={9}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="groupsMember" shrink={true}>Groups</InputLabel>
                                    <Select id="groupsMember"
                                            name="groupsMember"
                                            multiple
                                            readOnly={!editing}
                                            IconComponent={() => <ArrowDropDown style={!editing && { display: "none" } || {}} />}
                                            value={editedAssessment.groupsMember || []}
                                            onChange={ev => this.changeValue("groupsMember", ev.target.value)}>
                                        {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value}
                                                                                                  disabled={!option.active}>{option.value}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={9}>
                                <TextField id="organisationsSupporting"
                                           label="Referred or signposted to"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           fullWidth
                                           error={this.state.error.organisationsSupporting}
                                           value={editedAssessment.organisationsSupporting || ''}
                                           helperText={this.state.error.organisationsSupporting}
                                           onChange={ev => this.changeValue("organisationsSupporting", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={12}>
                                <TextField id="actionPlan"
                                           label="Action plan agreed with guest"
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.actionPlan || ''}
                                           error={this.state.error.actionPlan}
                                           helperText={this.state.error.actionPlan}
                                           InputProps={{
                                               readOnly: !editing,
                                               endAdornment: (<InputAdornment position="end">
                                                   <Tooltip classes={{tooltip: classes.tooltip}}
                                                            title="This can include (but is not limited to) applications for state support, support accessing COVID relief payments and signposting to other organisations.">
                                                       <HelpOutlineIcon/>
                                                   </Tooltip>
                                               </InputAdornment>)
                                           }}
                                           onChange={ev => this.changeValue("actionPlan", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={12}>
                                <InputLabel htmlFor="incomeExpenditureReviewConducted" shrink={true}>Income expenditure review conducted</InputLabel>
                                <Checkbox id="incomeExpenditureReviewConducted"
                                          name="incomeExpenditureReviewConducted"
                                          disabled={!editing}
                                          checked={editedAssessment.incomeExpenditureReviewConducted}
                                          onChange={(ev) => this.changeValue("incomeExpenditureReviewConducted", ev.target.checked, this.validateNumberOfParcels)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={12}>
                                {editedAssessment.incomeExpenditureReviewConducted
                                && (<GridContainer>
                                    <GridItem xs={10} sm={12} md={6}>
                                        <GridContainer>
                                            <GridItem xs={10} sm={12} md={12}>
                                                <InputLabel shrink={true}>Income</InputLabel>
                                            </GridItem>
                                            {this.renderIncomeExpenditure(editedAssessment, "income", editing)}
                                            <GridItem xs={10} sm={12} md={1}>
                                                <IconButton aria-label="add"
                                                            size="small"
                                                            disabled={!editing}
                                                            onClick={() => this.addIncomeExpenditure("income")}>
                                                    <AddCircleOutlineOutlinedIcon/>
                                                </IconButton>
                                            </GridItem>
                                            <GridItem xs={10} sm={12} md={12}>
                                                <InputLabel shrink={true}>ُExpenditure</InputLabel>
                                            </GridItem>
                                            {this.renderIncomeExpenditure(editedAssessment, "expenditure", editing)}
                                            <GridItem xs={10} sm={12} md={1}>
                                                <IconButton aria-label="add"
                                                            size="small"
                                                            disabled={!editing}
                                                            onClick={() => this.addIncomeExpenditure("expenditure")}>
                                                    <AddCircleOutlineOutlinedIcon/>
                                                </IconButton>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={10} sm={12} md={6}>
                                        <TextField id="incomeExpenditureComments"
                                                   label="Comments"
                                                   InputProps={{readOnly: !editing}}
                                                   InputLabelProps={{shrink: true}}
                                                   multiline
                                                   rows={3}
                                                   rowsMax={Infinity}
                                                   fullWidth
                                                   value={editedAssessment.incomeExpenditureComments || ''}
                                                   error={this.state.error.incomeExpenditureComments}
                                                   helperText={this.state.error.incomeExpenditureComments}
                                                   onChange={ev => this.changeValue("incomeExpenditureComments", ev.target.value)}/>
                                    </GridItem>
                                </GridContainer>)}
                            </GridItem>
                            <GridItem xs={10} sm={12} md={12}>
                                <TextField id="furtherComments"
                                           label="Further comments"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           multiline
                                           rows={3}
                                           rowsMax={Infinity}
                                           fullWidth
                                           value={editedAssessment.furtherComments || ''}
                                           error={this.state.error.furtherComments}
                                           helperText={this.state.error.furtherComments}
                                           onChange={ev => this.changeValue("furtherComments", ev.target.value)}/>
                            </GridItem>
                            <GridItem xs={10} sm={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="status" shrink={true}>Further food aid</InputLabel>
                                    <Select id="status"
                                            name="status" disabled={!editing}
                                            value={editedAssessment.status || 'NONE'}
                                            IconComponent={() => <ArrowDropDown style={!editing && { display: "none" } || {}} />}
                                            error={this.state.error.status}
                                            onChange={ev => this.changeValue("status", ev.target.value, this.validateNumberOfParcels)}>
                                        <MenuItem value="NONE"></MenuItem>
                                        <MenuItem value="APPROVED">Approved</MenuItem>
                                        <MenuItem value="REJECTED">Rejected</MenuItem>
                                    </Select>
                                    <FormHelperText>{this.state.error.status}</FormHelperText>
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField id="numberOfParcels"
                                           label="Number of parcels"
                                           InputProps={{readOnly: !editing}}
                                           InputLabelProps={{shrink: true}}
                                           disabled={editedAssessment.status && editedAssessment.status === "REJECTED"}
                                           fullWidth
                                           value={editedAssessment.numberOfParcels}
                                           error={this.state.error.numberOfParcels}
                                           helperText={this.state.error.numberOfParcels}
                                           onChange={ev => this.changeValue("numberOfParcels", ev.target.value, this.validateNumberOfParcels)}
                                           type="number"/>
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent>
                <DialogActions>
                    {this.props.id !== null && this.state.edit && (
                        <Button onClick={() => {
                            this.setState({
                                editedAssessment: {},
                                errors: {},
                                displayMessage: null,
                                edit: false
                            })
                        }} color="primary">
                            Cancel
                        </Button>
                    )}
                    {(!this.state.edit || this.props.readOnly || (this.props.id === null && this.props.referral)) && (
                        <Button onClick={() => {
                            this.setState({
                                editedAssessment: {},
                                errors: {},
                                displayMessage: null,
                                edit: false
                            })
                            this.props.onClose();
                        }} color="primary">
                            {this.props.id === null && 'Cancel' || 'Close'}
                        </Button>
                    )}
                    {(this.props.id === null && this.props.referral || !this.props.readOnly && this.state.edit) && (
                        <Button onClick={this.editFurtherAssessment} color="primary" autoFocus>
                            {this.props.id === null && 'Create' || 'Save'}
                        </Button>)}
                    {this.state.displayMessage && (
                        <FormHelperText error={true}>Please fix errors before saving</FormHelperText>)}}
                </DialogActions>
            </Dialog>);
    }
}

export default withStyles(styles)(FurtherAssessmentWeekly);
