import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ReadyMealsTable from "components/Table/ReadyMealsTable.js";
import ReadyMealsByRouteTable from "components/Table/ReadyMealsByRouteTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import {formatDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InputIcon from '@material-ui/icons/Input';
import PeopleIcon from '@material-ui/icons/People';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}
const GUEST_REFERRAL_DIFFERENCE_WARNING = "Value on guest differs to value on referral"
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

class ReadyMealsPendingReferralsDialog extends Component {

    constructor(props) {
      super(props);
      this.state = {
        tab: props.startingTab
      };
    }

    componentDidMount() {
      if (this.props.guestId)
        ApiService.request(`/guests/${this.props.guestId}`)
            .then(data => {
                this.setState({guest: data})
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        const { classes } = this.props;
        var guest = this.state.guest || {};
        return (
          <Dialog fullScreen open={this.props.open}>
            <DialogTitle>
              <AppBar>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {guest.id}: {guest.fullName}
                  </Typography>
                </Toolbar>
              </AppBar>
            </DialogTitle>
            <div style={{marginTop: 20}}>
              <DialogContent>
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <MapIcon/>
                  <Typography variant='subtitle1'>{guest.address}, {guest.postcode}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <PhoneIcon/>
                  <Typography variant='subtitle1'>{guest.contactNumbers?.join(", ")}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <PeopleIcon/>
                  <Typography variant='subtitle1'>{guest.numberOfPeople}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <InputIcon/>
                  <Typography variant='subtitle1'>{guest.totalNumberOfReferrals}</Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <LocalShippingIcon/>
                  <Typography variant='subtitle1'>{guest.totalNumberOfParcels}</Typography>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={this.state.tab} onChange={(ev, newValue) => {console.log(newValue); this.setState({tab: newValue})}} aria-label="basic tabs example">
                    <Tab label="Details" value="details"/>
                    <Tab label="Referrals" value="referrals"/>
                  </Tabs>
                </Box>
              </DialogContent>
            </div>
            <DialogActions>
              <Button onClick={this.props.handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        );

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(ReadyMealsPendingReferralsDialog))
