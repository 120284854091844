import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import ReadyMealsByRouteTable from "components/Table/ReadyMealsByRouteTable.js";
import ReadyMealsTable from "components/Table/ReadyMealsTable.js";
import Card from "@material-ui/core/Card";
import ReferralTimelineTable from "components/Table/ReferralTimelineTable.js";
import {isBefore} from 'date-fns'
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {formatDate} from "../../util/DateUtils.js"
import CardBody from "components/Card/CardBody.js";
import {
    dangerColor,
    infoColor,
    primaryColor,
    roseColor,
    successColor,
    warningColor,
    whiteColor,
    grayColor
} from "assets/jss/material-dashboard-react.js";
import ApiService from "services/ApiService.js";
import MapIcon from '@material-ui/icons/Map';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import FormHelperText from "@material-ui/core/FormHelperText";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import ChipInput from "material-ui-chip-input";
import {format} from "date-fns";
import {parseDate} from "../../util/DateUtils";
import {enGB} from "date-fns/locale";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import Input from "@material-ui/core/Input";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from '@material-ui/icons/DateRange';
import Checkbox from "@material-ui/core/Checkbox";

const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  rightAlignedItem: {
    marginLeft: "auto",
    marginRight: -12
  }
};
const referralColors = [grayColor[0], primaryColor[0], roseColor[0], successColor[0], infoColor[0], warningColor[0], dangerColor[0]]

class ManageRoutesDialog extends Component {

    constructor(props) {
      super(props);
      this.getMarker = this.getMarker.bind(this)
      this.state = {
      };
    }

    componentDidMount() {
      this.setState({ manageRoutesDate: this.props.referralsOrder
                                            .filter(referralId => this.props.referrals[referralId].scheduledDelivery)
                                            .map(referralId => this.props.referrals[referralId].scheduledDelivery)
                                            .reduce((date1, date2) => isBefore(date1, date2) ? date1 : date2) })
    }

    getMarker(name, route) {
      return `http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${name.substring(0, 2).toUpperCase()}|${referralColors[route].substring(1)}`
    }

    render() {
        const { classes } = this.props;
        var scheduledDate = formatDate(this.state.manageRoutesDate)
        var scheduledReferralsForDate = this.props.referralsOrder.filter(referralId => this.props.referrals[referralId].scheduledDelivery === scheduledDate)
        return (
         <Dialog fullScreen open={this.props.open}>
            <AppBar position="static">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.props.closeManageRoutesDialog} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Manage Routes
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                  <DatePicker value={this.state.manageRoutesDate}
                              autoOk
                              disablePast
                              maxDate={this.props.endOfNextWeek()}
                              onChange={(date) => this.setState({manageRoutesDate: date})}
                              classes={{root: classes.rightAlignedItem}}
                              TextFieldComponent={(props) => (<Input onChange={props.onChange}
                                                                     value={props.value}
                                                                     onClick={props.onClick}
                                                                     style={{color: whiteColor, marginLeft: 'auto', marginRight: -12}}
                                                                     endAdornment={<InputAdornment
                                                                         position="end">
                                                                         <IconButton>
                                                                             <DateRangeIcon
                                                                                 style={{color: whiteColor}}/>
                                                                         </IconButton>
                                                                     </InputAdornment>}/>)}/>
              </MuiPickersUtilsProvider>
            </Toolbar>
            </AppBar>
            <div style={{marginTop: 80}}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                      <ReadyMealsByRouteTable
                        tableHeaderColor="primary"
                        tableHead={{"id": "ID",
                                    "fullName": "Name",
                                    "contactNumber": "Contact",
                                    "address": "Address",
                                    "postcode": "Postcode",
                                    "parcelStatus": "Status"}}
                        mappingFunctions={{"id": referral => referral.id,
                                           "fullName": referral => referral.guest.fullName,
                                           "contactNumber": referral => {
                                               return (<pre>
                                                        {referral.guest.contactNumbers.join('\n')}
                                                        {referral.guest.permissionToContact && referral.guest.contactNumbers && referral.guest.contactNumbers > 0
                                                          && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                                                  <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                                              </Tooltip>)}
                                                       </pre>)
                                           },
                                           "address": referral => referral.guest.address,
                                           "postcode": referral => referral?.guest?.postcode,
                                           "parcelStatus": referral => referral?.parcelStatus + (referral?.scheduledDelivery && !referral?.parcelStatus?.includes(referral?.scheduledDelivery) ? `, Scheduled on ${referral?.scheduledDelivery}` : "")}}
                        referralsOrder={scheduledReferralsForDate}
                        referrals={this.props.referrals}
                        setRoute={this.props.setRoute}
                        setViewReferralDialogId={this.props.setViewReferralDialogId}
                      />
              </GridItem>
              <GridItem md={6} style={{position: 'relative'}}>
                <Map google={this.props.google}
                     zoom={15}
                     initialCenter={{lat: 51.548969, lng: -0.266440}} mapTypeId="hybrid">
                  {this.props.collectionHubs[0] && (<Marker position={this.props.collectionHubs[0]}
                                                            title={this.props.collectionHubs[0].name}
                                                            name={this.props.collectionHubs[0].name}
                                                            icon={{url: this.getMarker(this.props.collectionHubs[0].name, 0)}}/>)}
                  {scheduledReferralsForDate.map((id, idx) => {
                                                   var referral = this.props.referrals[id];
                                                   return referral?.guest?.lat && (<Marker position={referral.guest}
                                                                                           title={referral.guest.fullName}
                                                                                           name={referral.guest.fullName}
                                                                                           icon={{url: this.getMarker(referral.guest.fullName, referral?.scheduledRoute || 0)}}/>)
                                                 })}
                </Map>
              </GridItem>
            </GridContainer>
            </div>
          </Dialog>)

    }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyC4-VuEjg4Z8H2Y7zeOFMOcDOSv0_GKu2Y",

})(withStyles(styles)(ManageRoutesDialog))
