import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import GuestsTable from "components/Table/GuestsTable.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import {formatDate} from "../../util/DateUtils.js"
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "services/ApiService.js";
import Pagination from '@material-ui/lab/Pagination';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {whiteColor} from "assets/jss/material-dashboard-react.js";

const EDITABLE_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];
const REFERRAL_EDITABLE_FIELDS = ["referralAgency", "coronavirus", "reason", "groupsMember",
    "nameOfAuthorisedSignatory", "phoneOfAuthorisedSignatory", "emailOfAuthorisedSignatory",
    "nappiesCore", "nappiesOther", "toiletriesCore", "toiletriesOther", "babyMilkCore", "babyMilkOther",
    "homelessOrTemporaryAccommodation", "cookingFacilities", "modeOfDelivery", "reasonForDelivery",
    "organisationsSupporting", "furtherInfo", "frequencyOfDelivery", "numberOfDeliveries", "permissionToContact",
    "cannotAffordToPurchaseFood", "appliances"];
const styles = {
  whiteColor: {
    color: "white"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

class Guests extends Component {

    constructor(props) {
        super(props);
        this.doSearch = this.doSearch.bind(this);
        this.changePage = this.changePage.bind(this);
        this.editGuest = this.editGuest.bind(this);
        this.fetchGuest = this.fetchGuest.bind(this);
        this.archiveReferral = this.archiveReferral.bind(this);
        this.switchReferralType = this.switchReferralType.bind(this);
        this.unarchiveReferral = this.unarchiveReferral.bind(this);
        this.performDeliveryAction = this.performDeliveryAction.bind(this);
        this.editReferral = this.editReferral.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.fetchAllGuests = this.fetchAllGuests.bind(this);
        this.mergeGuestInto = this.mergeGuestInto.bind(this);
        this.editFurtherAssessment = this.editFurtherAssessment.bind(this);
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this);

      this.state = {
          numberOfResults: 0,
          numberOfPages: 1,
          page: 1,
          itemsPerPage: 20,
          guestsOrder: [],
          guests: {},
          orderBy: "fullName",
          order: "asc",
          allGuests: {},
          allGuestsOrder: [],
          searchController: new AbortController()
      }
    }

    componentDidMount() {
        this.doSearch(this.state.page, this.state.orderBy, this.state.order)
        this.fetchAllGuests()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchParam !== this.props.searchParam) {
            this.state.page = 1;
            this.doSearch(1, this.state.orderBy, this.state.order)
        }
    }

    doSearch(page, orderBy, order) {
        var path = this.props.searchParam?.length > 0 ? `/guests/search/${this.props.searchParam}` : '/guests'
        this.state.searchController.abort()
        this.state.searchController = new AbortController()
        const { signal } = this.state.searchController;
        ApiService.request(`${path}?page=${(page || 1) - 1}&size=${this.state.itemsPerPage}&sort=${orderBy || "fullName"},${order || "asc"}`, 'GET', null, { signal })
            .then(data => {
                console.log("data received")
                var guestsMap = data.content.reduce(function(map, obj) {
                                                        map[obj.id] = obj;
                                                        return map;
                                                    }, {})
                this.setState({numberOfResults: data.totalElements,
                               numberOfPages: data.totalPages,
                               guests: guestsMap,
                               guestsOrder: data.content.map(guest => guest.id)
                              });
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchGuest(id) {
        return ApiService.request(`/guests/${id}`)
            .then(data => {
                var guests = this.state.guests;
                guests[id] = data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchAllGuests() {
        ApiService.request('/guests/all')
            .then(data => {
                this.setState({allGuests: data.reduce(function(map, obj) {
                                                       map[obj.id] = obj;
                                                       return map;
                                                   }, {}),
                               allGuestsOrder: data.map(guest => guest.id)
                              })
            })
            .catch(err => {
                console.log(err);
            });
    }

    mergeGuestInto(guestId, mergeIntoGuestId) {
        ApiService.request(`/guests/${guestId}/mergeInto?mergeIntoGuestId=${mergeIntoGuestId}`, 'PUT')
            .then(data => {
                var guests = this.state.guests;
                var guestsOrder = this.state.guestsOrder;
                guests[mergeIntoGuestId] = data;
                delete guests[guestId];
                guestsOrder.splice(guestsOrder.indexOf(guestId), 1);
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    getCsv() {
        ApiService.requestCsv(`/guests/csv?sort=${this.state.orderBy || "fullName"},${this.state.order || "asc"}${this.props.searchParam?.length > 0 ? `&search=${this.props.searchParam}` : ''}`)
    }

    changePage(event, newValue) {
        this.state.page = newValue;
        this.doSearch(newValue, this.state.orderBy, this.state.order);
    }

    setOrderBy(prop) {
        const isAsc = this.state.orderBy === prop && this.state.order === 'asc';
        this.state.orderBy = prop;
        this.state.order = isAsc ? 'desc' : 'asc'
        this.doSearch(this.state.page, prop, isAsc ? 'desc' : 'asc')
    }

    editGuest(guest) {
        var guestToSend = EDITABLE_FIELDS.reduce(function(result, field) {
                                                     result[field] = guest[field];
                                                     return result;
                                                 }, {});
        guestToSend.dob = formatDate(guestToSend.dob);
        ApiService.request(`/guests/${guest.id}`, 'PUT', guestToSend)
            .then(data => {
                var guests = this.state.guests;
                guests[guest.id] = data;
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    editReferral(referral) {
        var referralToSend = REFERRAL_EDITABLE_FIELDS.reduce(function(result, field) {
                                                                 result[field] = referral[field];
                                                                 return result;
                                                             }, {});
        ApiService.request(`/referrals/${referral.id}`, 'PUT', referralToSend)
            .then(data => {
                var guests = this.state.guests;
                guests[data.guest.id].referrals.forEach((referral, index) => {
                    if(data.id === referral.id) {
                        guests[data.guest.id].referrals[index] = data;
                    }
                });
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    editFurtherAssessment(furtherAssessmentId, furtherAssessment) {
        return ApiService.request(`/furtherassessments/${furtherAssessmentId}`, 'PUT', furtherAssessment)
            .then(data => {
                var guests = this.state.guests;
                const referrals = guests[data.guest.id].referrals;
                referrals[referrals.findIndex(referral => referral.id === data.id)] = data;
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        return ApiService.request(`/furtherassessments/referral/${referralId}`, 'PUT', furtherAssessment)
            .then(data => {
                var guests = this.state.guests;
                const referrals = guests[data.guest.id].referrals;
                const referralIdx = referrals.findIndex(referral => referral.id === data.id);
                const assessmentIds = referrals[referralIdx].furtherAssessments.map(assessment => assessment.id);
                referrals[referralIdx] = data;
                this.setState({guests: guests});
                return data.furtherAssessments.find(assessment => !assessmentIds.includes(assessment.id)).id
            })
            .catch(err => {
                console.log(err);
            });
    }

    archiveReferral(key, archiveReason, archiveComment, archiveDate) {
        return ApiService.request(`/referrals/${key}/archive?archiveReason=${archiveReason}&archiveDate=${formatDate(archiveDate || new Date())}`, 'PUT', archiveComment)
            .then(data => {
                var guests = this.state.guests;
                var referrals = guests[data.guest.id].referrals
                referrals[referrals.findIndex(r => r.id === data.id)] = data
                guests[data.guest.id].referrals = referrals
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    switchReferralType(key) {
        return ApiService.request(`/referrals/${key}/switchReferralType`, 'PUT')
            .then(data => {
                var guests = this.state.guests;
                var referrals = guests[data.guest.id].referrals
                referrals[referrals.findIndex(r => r.id === data.id)] = data
                guests[data.guest.id].referrals = referrals
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    unarchiveReferral(key) {
        return ApiService.request(`/referrals/${key}/unarchive`, 'PUT')
            .then(data => {
                var guests = this.state.guests;
                var referrals = guests[data.guest.id].referrals
                referrals[referrals.findIndex(r => r.id === data.id)] = data
                guests[data.guest.id].referrals = referrals
                this.setState({guests: guests});
            })
            .catch(err => {
                console.log(err);
            });
    }

    performDeliveryAction(deliveryId, action, value) {
        ApiService.request(`/deliveries/${deliveryId}/${action}`, 'PUT')
            .then(response => {
                var guests = this.state.guests;
                guests[response.guest.id].referrals.forEach((referral, index) => {
                    if(response.id === referral.id) {
                        guests[response.guest.id].referrals[index] = response;
                    }
                });
                this.setState({guests: guests});
            })
    }

    render() {
        const { classes } = this.props;
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <GridContainer alignItems='center'>
                      <GridItem md={7}>
                          <h3 className={classes.cardTitleWhite}>Guests</h3>
                          {(this.props.searchParam && this.props.searchParam.length > 0 ) ? (<p className={classes.cardCategoryWhite}>Searching for: {this.props.searchParam}</p>) : ""}
                          <p className={classes.cardCategoryWhite}>Showing {this.state.numberOfResults} guests</p>
                      </GridItem>
                      <GridItem md={1}>
                        <Tooltip title="Download as CSV">
                          <IconButton aria-label="csv" size="large" onClick={() => this.getCsv()}>
                            <CloudDownloadOutlinedIcon style={{ color: whiteColor, fontSize: '35px' }}/>
                          </IconButton>
                        </Tooltip>
                      </GridItem>
                      <GridItem md={4}>
                          <Pagination count={this.state.numberOfPages} color="primary" showFirstButton showLastButton page={this.state.page} onChange={this.changePage}/>
                      </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <GuestsTable
                    tableHeaderColor="primary"
                    tableHead={{"id": "ID",
                                "fullName": "Full Name",
                                "contactNumber": "Contact Number",
                                "address": "Address",
                                "postcode": "Postcode"}}
                    mappingFunctions={{"id": guest => guest.id,
                                       "fullName": guest => guest.fullName,
                                       "contactNumber": guest => guest.contactNumbers.toString(),
                                       "address": guest => guest.address,
                                       "postcode": guest => guest.postcode}}
                    guests={this.state.guests}
                    guestsOrder={this.state.guestsOrder}
                    allGuests={this.state.allGuests}
                    allGuestsOrder={this.state.allGuestsOrder}
                    editGuest={this.editGuest}
                    fetchGuest={this.fetchGuest}
                    archiveReferral={this.archiveReferral}
                    unarchiveReferral={this.unarchiveReferral}
                    switchReferralType={this.switchReferralType}
                    performDeliveryAction={this.performDeliveryAction}
                    editReferral={this.editReferral}
                    orderBy={this.state.orderBy}
                    order={this.state.order}
                    setOrderBy={this.setOrderBy}
                    mergeGuestInto={this.mergeGuestInto}
                    getOptions={this.props.getOptions}
                    user={this.props.user}
                    editFurtherAssessment={this.editFurtherAssessment}
                    createFurtherAssessment={this.createFurtherAssessment}
                  />
                </CardBody>
                <CardFooter >
                  <Pagination count={this.state.numberOfPages} color="primary" showFirstButton showLastButton page={this.state.page} onChange={this.changePage}/>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        );

    }


}
export default withStyles(styles)(Guests);
