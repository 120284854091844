import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FurtherAssessment from "components/Table/FurtherAssessment.js";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {infoColor} from "assets/jss/material-dashboard-react.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import ReferralAssessmentsTable from "./ReferralAssessmentsTable";
import {Fab} from "@material-ui/core";
import {Add} from "@material-ui/icons";

class FurtherAssessmentsTable extends Component {
    constructor(props) {
        super(props);
        this.setOpen = this.setOpen.bind(this)
        this.viewFurtherAssessment = this.viewFurtherAssessment.bind(this)
        this.newFurtherAssessment = this.newFurtherAssessment.bind(this)
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this)

        this.state = {
            open: {},
            furtherAssessmentDialogFurtherAssessmentId: null,
            furtherAssessmentDialogReferralId: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.referralsOrder !== this.props.referralsOrder) {
            this.setState({open: {}})
        }
    }

    setOpen(key) {
        const open = this.state.open;
        open[key] = !open[key];
        if (open[key]) {
            this.props.fetchReferral(key).then(() => {
                this.setState({open: open})
            });
        }
        else {
            this.setState({open: open});
        }
    }

    viewFurtherAssessment(referralId, furtherAssessmentId) {
        this.setState({
            furtherAssessmentDialogFurtherAssessmentId: furtherAssessmentId,
            furtherAssessmentDialogReferralId: referralId
        })
    }

    newFurtherAssessment(referralId) {
        this.props.fetchReferral(referralId).then(() => this.setState({
            furtherAssessmentDialogFurtherAssessmentId: null,
            furtherAssessmentDialogReferralId: referralId
        }))
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        return this.props.createFurtherAssessment(referralId, furtherAssessment)
            .then(id => {
                this.setState({
                    furtherAssessmentDialogFurtherAssessmentId: id,
                    furtherAssessmentDialogReferralId: referralId
                })
            })
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
              <FurtherAssessment
                  open={this.state.furtherAssessmentDialogReferralId}
                  readOnly={!(this.props.user && this.props.user.roles.includes('ROLE_ADMIN') || !this.state.furtherAssessmentDialogFurtherAssessmentId
                             || this.props.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId).parcelsLeft > 0)}
                  canEditFrequency={this.props.user?.roles?.includes('ROLE_ADVISOR')}
                  onClose={() =>this.setState({
                      furtherAssessmentDialogFurtherAssessmentId: null,
                      furtherAssessmentDialogReferralId: null
                  })}
                  getOptions={this.props.getOptions}
                  id={this.state.furtherAssessmentDialogFurtherAssessmentId}
                  referral={this.props.referrals[this.state.furtherAssessmentDialogReferralId]}
                  assessment={this.state.furtherAssessmentDialogFurtherAssessmentId
                             && this.props.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId)}
                  editFurtherAssessment={this.props.editFurtherAssessment}
                  createFurtherAssessment={this.createFurtherAssessment}/>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          <TableSortLabel active={this.props.orderBy === prop}
                                          direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                          onClick={ev => {this.props.setOrderBy(prop)}}>
                            {this.props.tableHead[prop]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  <TableCell/>
                </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.referralsOrder.map((id, idx) => {
                  const referral = this.props.referrals[id];
                  return referral && (
                    <React.Fragment>
                      <TableRow key={id} className={classes.tableBodyRowSummary}>
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="View referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(id)}>
                              {this.state.open[id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        { Object.keys(this.props.tableHead).map((prop, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={key}>
                              {this.props.mappingFunctions[prop](referral)}
                              {prop === "contactNumber" && referral.permissionToContact
                                && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                      <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                    </Tooltip>)}
                            </TableCell>
                          );
                        })}
                          <TableCell className={classes.tableCellSummary}>
                              <Tooltip title="New assessment">
                                  <Fab size="small" color="primary" aria-label="add" onClick={() => this.newFurtherAssessment(id)}>
                                      <Add/>
                                  </Fab>
                              </Tooltip>
                          </TableCell>
                      </TableRow>
                      <TableRow key={id + "Details"} className={classes.tableBodyRowDetail}>
                        <TableCell className={classes.tableDetailsCell}/>
                        <TableCell className={classes.tableDetailsCell} colSpan={Object.keys(this.props.tableHead).length + 1} key={"details"}>
                          <Collapse in={this.state.open[id]} timeout="auto" unmountOnExit>
                            <GridContainer>
                                <GridItem md={1}/>
                              <GridItem md={10}>
                                  <ReferralAssessmentsTable tableHeaderColor="primary"
                                                            tableHead={{
                                                                "ID": assessment => assessment.id,
                                                                "Status": assessment => assessment.status,
                                                                "Name of advisor": assessment => assessment.nameOfAdvisor,
                                                                "Date of assessment": assessment => assessment.dateOfAssessment,
                                                                "Number of parcels / Date of food aid expiry": assessment => assessment.numberOfParcels || assessment.dateOfFoodAidExpiry
                                                            }}
                                                            referral={referral}
                                                            assessments={referral.furtherAssessments}
                                                            viewFurtherAssessment={this.viewFurtherAssessment}/>
                              </GridItem>
                            </GridContainer>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(FurtherAssessmentsTable);

FurtherAssessmentsTable.defaultProps = {
  tableHeaderColor: "gray"
};

FurtherAssessmentsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
