import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";
import TableBody from "@material-ui/core/TableBody";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import TableCell from "@material-ui/core/TableCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {dangerColor, successColor, warningColor} from "assets/jss/material-dashboard-react.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {Visibility} from "@material-ui/icons";

class ReferralTimelineTable extends Component {
    constructor(props) {
        super(props);
        this.getEventCell = this.getEventCell.bind(this);
        this.getActions = this.getActions.bind(this);
    }

    getActions(timelineEvent) {
        return timelineEvent.type === 'PARCEL'
            && (<React.Fragment>
                <Tooltip title="Delete collection/delivery">
                    <IconButton onClick={() => this.props.performDeliveryAction(timelineEvent.source.id, "delete")}>
                        <DeleteIcon style={{color: dangerColor[2]}}/>
                    </IconButton>
                </Tooltip>
                {(timelineEvent.source.status === 'SCHEDULED' || timelineEvent.source.status === 'DELIVERED')
                && (<Tooltip title="Delivery failed">
                    <IconButton onClick={() => this.props.performDeliveryAction(timelineEvent.source.id, "failed")}>
                        <ErrorOutlineRoundedIcon style={{color: warningColor[2]}}/>
                    </IconButton>
                </Tooltip>)}
                {(timelineEvent.source.status === 'SCHEDULED' || timelineEvent.source.status === 'FAILED')
                && (<Tooltip title="Delivery successful">
                    <IconButton onClick={() => this.props.performDeliveryAction(timelineEvent.source.id, "delivered")}>
                        <CheckCircleOutlineRoundedIcon style={{color: successColor[2]}}/>
                    </IconButton>
                </Tooltip>)}
            </React.Fragment>) ||
            timelineEvent.type === 'FURTHER_ASSESSMENT' &&
            (<Tooltip title="View further assessment">
                <IconButton aria-label="expand row" size="small"
                            onClick={() => this.props.viewFurtherAssessment(this.props.id, timelineEvent.source.id)}>
                    <Visibility/>
                </IconButton>
            </Tooltip>)
    }

    getEventCell(timelineEvent) {
        const { classes } = this.props;
        const text = timelineEvent.source && timelineEvent.source.textMessage;
        const textStatus = text && text.status;
        return (
          <React.Fragment>
            <GridContainer justify="space-between">
              <GridItem>
                <Typography className={classes.typography}>{timelineEvent.event}</Typography>
              </GridItem>
              <GridItem>
                    {(textStatus === "FAILED" && (<Tooltip title={"Message failed to send on " + text.date + ": " + text.error}>
                                                    <ErrorOutlineRoundedIcon style={{ color: warningColor[2], fontSize: '20px' }}/>
                                                  </Tooltip>))
                  || (textStatus === "SUCCESSFUL" && (<Tooltip title={"Message delivered on " + text.date}>
                                                        <CheckCircleRoundedIcon style={{ color: successColor[2], fontSize: '20px' }}/>
                                                      </Tooltip>))
                  || (textStatus === "SENT" && (<Tooltip title={"Message sent on " + text.date}>
                                                  <CheckCircleOutlineRoundedIcon style={{ color: successColor[2], fontSize: '20px' }}/>
                                                </Tooltip>))}
              </GridItem>
            </GridContainer>
          </React.Fragment>
        )
    }

    render() {
        const { classes } = this.props;
        return (
          <div className={classes.tableResponsive}>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          {prop}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"event"}
                    >
                      Event
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"actions"}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.timeline && this.props.timeline.map((timelineEvent, idx) => {
                  return (
                    <React.Fragment>
                      <TableRow key={timelineEvent.id} className={classes.tableBodyRow}>
                        { Object.values(this.props.tableHead).map((mappingFunction, key) => {
                          return (
                            <TableCell className={classes.tableCell} key={key}>
                              {mappingFunction(timelineEvent)}
                            </TableCell>
                          );
                        })}
                        <TableCell className={classes.tableCell} key={"actions"}>
                          {this.getEventCell(timelineEvent)}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={"actions"}>
                          {this.getActions(timelineEvent)}
                        </TableCell>

                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(ReferralTimelineTable);

ReferralTimelineTable.defaultProps = {
  tableHeaderColor: "gray"
};

ReferralTimelineTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
