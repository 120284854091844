import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DialogActions from '@material-ui/core/DialogActions';
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {enGB} from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import IconButton from '@material-ui/core/IconButton';
import {formatDate} from "../../util/DateUtils.js"
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

function EmergencyParcelDialog(props) {
  const { handleClose, open, saveEmergencyParcel, collectionHubs } = props;

  const [date, setDate] = useState(new Date());
  const [comments, setComments] = useState('');
  const [numberOfParcels, setNumberOfParcels] = useState(1);
  const [collectionHub, setCollectionHub] = useState('Sufra');

  const onClose = () => {
    props.handleClose();
    setDate(new Date());
    setComments('');
    setNumberOfParcels(1);
    setCollectionHub('Sufra')
  };

  const onSave = () => {
    saveEmergencyParcel(date, numberOfParcels, collectionHub, comments).then(data => onClose())
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Distribute Emergency Parcel</DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem md={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
              <DatePicker value={date}
                          label="Date of parcel"
                          clearable
                          autoOk
                          disableFuture
                          format="dd-MM-yyyy"
                          onChange={setDate}/>
            </MuiPickersUtilsProvider>
          </GridItem>
          <GridItem md={4}>
            <TextField id="numberOfParcels"
                       label="Number of parcels"
                       value={numberOfParcels}
                       onChange={ev => setNumberOfParcels(ev.target.value > 0 && ev.target.value || 1)}
                       type="number"/>
          </GridItem>
          <GridItem md={4}/>
          <GridItem md={4}>
            <FormControl fullWidth>
                <InputLabel shrink>Collection hub</InputLabel>
                <Select value={collectionHub}
                        onChange={ev => setCollectionHub(ev.target.value)}>
                    {collectionHubs.filter(hub => hub.active).map(hub => (<MenuItem value={hub.name}>{hub.name}</MenuItem>))}
                </Select>
            </FormControl>
          </GridItem>
          <GridItem md={8}>
            <TextField id="comments"
                       label="Comments"
                       multiline
                       value={comments}
                       fullWidth
                       onChange={ev => setComments(ev.target.value)}/>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EmergencyParcelDialog;