/*eslint-disable*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function OptionWithOther(props) {
  return (
    <React.Fragment>
      <FormControl fullWidth={props.fullWidth}>
        <InputLabel htmlFor={props.coreField}
                    shrink
                    error={props.helperText}>{props.coreLabel}</InputLabel>
        <Select id={props.coreField + props.id}
                name={props.coreField}
                multiple={props.multiple}
                disabled={props.disabled}
                style={!props.fullWidth && {width: 400} || {}}
                value={props.object[props.coreField] || []}
                onChange={ev => props.changeValue(props.id, props.coreField, ev.target.value)}>
          {props.options.map(option => (<MenuItem value={option}>{option}</MenuItem>))}
          <MenuItem value="Other">Other</MenuItem>
        </Select>
        <FormHelperText error={props.helperText}>{props.helperText}</FormHelperText>
      </FormControl>
      {(props.object[props.coreField] && (props.object[props.coreField] === 'Other' || (typeof props.object[props.coreField] !== 'string' && props.object[props.coreField].includes("Other"))))
        && (<TextField id={props.otherField + props.id}
                       label="Other"
                       InputLabelProps={{shrink: true}}
                       disabled={props.disabled}
                       fullWidth={props.fullWidth}
                       style={!props.fullWidth && {width: 400} || {}}
                       value={props.object[props.otherField] || ''}
                       multiline={props.multilineOther}
                       onChange={ev => props.changeValue(props.id, props.otherField, ev.target.value)}/>)}
    </React.Fragment>
  );
}

OptionWithOther.propTypes = {
  coreField: PropTypes.string,
  coreLabel: PropTypes.string,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  object: PropTypes.object,
  changeValue: PropTypes.func,
  options: PropTypes.array,
  otherField: PropTypes.string,
};
