import React, { Component } from "react";
// react plugin for creating charts
// @material-ui/core
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import Contacts from "@material-ui/icons/Contacts";
import Input from "@material-ui/icons/Input";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import startOfWeek from "date-fns/startOfWeek";
import { format } from "date-fns";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import ApiService from "services/ApiService.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.fetchStats = this.fetchStats.bind(this);
    this.state = {
      stats: {},
    };
  }

  componentDidMount() {
    this.fetchStats();
  }

  fetchStats() {
    ApiService.request(`/reports/WEEKLY/${format(startOfWeek(new Date(), { weekStartsOn: 1 }), "dd-MM-yyyy")}`)
      .then(data => this.setState({ stats: data }))
      .catch(err => console.log(err));
  }

  ageGroupMax() {
    if (this.state.stats?.referralsByAgeOfVoucherHolder) {
      return Object.keys(
        this.state.stats?.referralsByAgeOfVoucherHolder
      ).reduce((a, b) =>
        this.state.stats?.referralsByAgeOfVoucherHolder[a] >
        this.state.stats?.referralsByAgeOfVoucherHolder[b]
          ? a
          : b
      );
    }
    return "";
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Input />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Total parcels distributed
                </p>
                <h3 className={classes.cardTitle}>
                  {this.state.stats.totalParcelsDistributed}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Contacts />
                </CardIcon>
                <p className={classes.cardCategory}>Total unique recipients</p>
                <h3 className={classes.cardTitle}>
                  {this.state.stats.totalUniqueRecipients}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Contacts />
                </CardIcon>
                <p className={classes.cardCategory}>Most guests are between</p>
                <h3 className={classes.cardTitle}>{this.ageGroupMax()}</h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Input />
                </CardIcon>
                <p className={classes.cardCategory}>Valid vouchers</p>
                <h3 className={classes.cardTitle}>
                  {this.state.stats.validVouchers}
                </h3>
                <p className={classes.cardCategory}>
                  New: {this.state.stats.newReferrals}
                </p>
                <p className={classes.cardCategory}>
                  Existing:{" "}
                  {this.state.stats.existingReferrals +
                    this.state.stats.postAssessmentReferrals}
                </p>
                <p className={classes.cardCategory}>
                  Internal: {this.state.stats.internalReferrals}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Input />
                </CardIcon>
                <p className={classes.cardCategory}>
                  Further assessments completed
                </p>
                <h3 className={classes.cardTitle}>
                  {this.state.stats?.approvedAssessments +
                    this.state.stats?.rejectedAssessments}
                </h3>
                <p className={classes.cardCategory}>
                  Approved: {this.state.stats?.approvedAssessments}
                </p>
                <p className={classes.cardCategory}>
                  Rejected: {this.state.stats?.rejectedAssessments}
                </p>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {Object.keys(
            this.state.stats?.numberOfParcelsCollectedByHub || {}
          ).map((hub, idx) => (
            <React.Fragment>
              <GridItem xs={12} sm={6} md={2}>
                <Card>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <Store />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Parcels collected from {hub}
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.stats.numberOfParcelsCollectedByHub[hub]}
                    </h3>
                  </CardHeader>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={2}>
                <Card>
                  <CardHeader color="rose" stats icon>
                    <CardIcon color="rose">
                      <Store />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Parcels delivered by {hub}
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.stats.numberOfParcelsDeliveredByHub[hub]}
                    </h3>
                  </CardHeader>
                </Card>
              </GridItem>
            </React.Fragment>
          ))}
          <GridItem xs={12} sm={6} md={2}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Warning />
                </CardIcon>
                <p className={classes.cardCategory}>Parcels uncollected</p>
                <h3 className={classes.cardTitle}>
                  {this.state.stats.parcelsUncollected}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer></GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
